import { useState, useEffect } from 'react'

import { debouncedSearch } from '../services/algolia'

const doSearch = debouncedSearch(300)

export default function useSearch(
  query: string,
): { loading: boolean; error: any; hits: any[] } {
  const [loading, setLoading] = useState(false)
  const [hits, setHits]: [any[], Function] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    async function search(): Promise<void> {
      if (!query || query.length < 3) {
        setLoading(false)
        setError(false)
        setHits([])
        return
      }

      try {
        setLoading(true)

        const { hits } = await doSearch(query, {
          hitsPerPage: 50,
          typoTolerance: true,
          ignorePlurals: ['nb'],
          attributesToRetrieve: [
            'objectID',
            'type',
            'slug',
            'title',
            'image',
            'duration',
            'lectures',
            'lectureCount',
          ],
        })

        setError(false)
        setHits(hits)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    if (query && query.length) {
      search()
      return
    }

    setError(false)
    setHits([])
  }, [query])

  return { loading, error, hits }
}
