import './index.scss'

import React, { useRef, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('aspect-ratio-image')

// Check if intersection observer is supported
const isBrowser = typeof window !== 'undefined'
const intersectionObserverSupport = isBrowser ? 'IntersectionObserver' in window : false

// Set up observer
let observer = null
if (intersectionObserverSupport) {
  observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio <= 0) {
        return
      }

      observer.unobserve(entry.target)
      loadLazy(entry.target)
    })
  })
}

// Function loading lazy images
function loadLazy(image) {
  image.setAttribute('src', image.getAttribute('data-src'))
}

export default function AspectRatioImage({
  src,
  alt,
  className,
  style = {},
  aspect,
  lqip = null,
  loading = false,
  noLazyLoad = false,
  ...props
}) {
  const imageRef = useRef(null)

  /**
   * Observer image using the intersection observer, alternatively
   * just loading the images upfront if the intersection observer
   * in not supported in the browser
   */
  useEffect(() => {
    if (!src) { return }

    const target = imageRef.current

    if (intersectionObserverSupport && !noLazyLoad) {
      observer.observe(target)
    } else {
      loadLazy(imageRef.current)
    }

    return () => observer && target && observer.unobserve(target)
  }, [noLazyLoad, src])

  const [a, b] = aspect.split('/')
  const backgroundImage = lqip ? `url(${lqip})` : null

  return (
    <div {...bem('', { skeleton: loading }, className)} style={style}>
      {src && (
        <img
          ref={imageRef}
          data-src={src}
          alt={alt}
          {...bem('image')}
          {...props}
        />
      )}
      <span
        {...bem('aspect-ratio')}
        style={{
          paddingTop: `calc(100% / ${a / b})`,
          backgroundImage,
        }}
      />
    </div>
  )
}

AspectRatioImage.propTypes = {
  src: T.string,
  alt: T.string,
  className: T.string,
  aspect: T.string,
  lqip: T.string,
  style: T.object,
  loading: T.bool,
  noLazyLoad: T.bool,
}

AspectRatioImage.defaultProps = {
  aspect: '4/3',
}
