import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { minutesToSentence } from '../../lib/utils'
import { AspectRatioImage, Icon } from '../../_shared/components'

const bem = new BEMHelper('search-results')

export default function SearchResults({ query, items, noResults }: InferProps<typeof SearchResults.propTypes>): JSX.Element {
  const history = useHistory()
  const { pathname, hash } = useLocation()
  const from = `${pathname}#${encodeURIComponent(query || '')}`

  const results = items?.filter(({ type }) => type !== 'category') || []
  const categories = items?.filter(({ type }) => type === 'category') || []

  return (
    <nav {...bem('')} aria-live="polite">
      {categories.length > 0 && (
        <div {...bem('categories')}>
          {categories.map(({ objectID: id, title, slug }) => (
            <Link
              to={{
                pathname: `/kategori/${slug}`,
                state: { from },
              }}
              {...bem('category')}
              key={id}
            >
              <Icon icon="tag" size="small" {...bem('icon')} />
              {title}
            </Link>
          ))}
        </div>
      )}

      {noResults && (!items || items.length === 0) && (
        <p {...bem('no-results')}>Fant ingen retter</p>
      )}

      {results.map(({
        type,
        objectID: id,
        title,
        duration,
        slug,
        image,
        lectureCount,
      }) => {
        switch (type) {
          case 'recipe':
            return (
              <Link
                to={{
                  pathname: `/oppskrifter/${slug}`,
                  state: { from },
                }}
                data-from={from}
                {...bem('item', type)}
                key={id}
              >
                {image && <AspectRatioImage aspect="3/4" {...bem('image')} src={image} alt={title} />}
                <div {...bem('content')}>
                  <h2 {...bem('name')}>{title}</h2>

                  <span {...bem('meta')}>
                    <Icon icon="time" size="small" {...bem('icon')} />
                    {minutesToSentence(duration) || '—'}
                  </span>
                </div>
              </Link>
            )

          case 'course':
            return (
              <Link
                to={{
                  pathname: `/kurs/${slug}`,
                  state: { from },
                }}
                data-from={from}
                {...bem('item', type)}
                key={id}
              >
                {image && <AspectRatioImage aspect="3/4" {...bem('image')} src={image} alt={title} />}
                <div {...bem('content')}>
                  <h2 {...bem('name')}>{title}</h2>

                  <span {...bem('meta')}>
                    <Icon icon="course" size="small" {...bem('icon')} /> {lectureCount} leksjoner
                  </span>
                </div>
              </Link>
            )

          case 'topic':
            return (
              <Link
                to={{
                  pathname: `/tema/${slug}`,
                  state: { from },
                }}
                data-from={from}
                {...bem('item', type)}
                key={id}
              >
                {image && <AspectRatioImage aspect="3/4" {...bem('image')} src={image} alt={title} />}
                <div {...bem('content')}>
                  <h2 {...bem('name')}>{title}</h2>
                </div>
              </Link>
            )

          default:
            console.warn('No habla undersand', { id, type, title })
            return null
        }
      })}
    </nav>
  )
}

SearchResults.propTypes = {
  title: T.string,
  type: T.string,
  items: T.array,
  noResults: T.bool,
  query: T.string,
}
