import gql from 'graphql-tag'

export default gql`
  fragment GroceryList on GroceryList {
    id
    departments {
      id
      department {
        id
        title
      }
      ingredients {
        id
        checked
        ingredient {
          id
          title
          quantity {
            two
            four
            six
            eight
          }
        }
      }
    }
  }
`
