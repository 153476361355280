/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import React, { useEffect, useState } from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

import postMessage from '../../lib/post-message'

const bem = new BEMHelper('loader')

export default function Loader({
  delay,
  type,
  text,
}: InferProps<typeof Loader.propTypes>): JSX.Element | null {
  // Show immediately if delay is set to 0, otherwise not
  const [show, setShow] = useState(delay === 0)

  // Delay showing the loader for the duration specified for the delay prop
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, delay || 0)

    return (): void => {
      postMessage({ type: 'loadingEnd' })
      clearTimeout(timer)
    }
  }, [])

  if (!show) {
    return null
  }

  return (
    <figure
      {...bem('', type || undefined)}
      aria-busy="true"
      aria-live="polite"
    >
      <svg width="86px" height="86px" viewBox="0 0 86 86" {...bem('spinner')}>
        <title>Laster...</title>
        <rect
          x="3"
          y="3"
          width="80"
          height="80"
          rx="12"
          {...bem('line', 'muted')}
        />

        <rect
          x="3"
          y="3"
          width="80"
          height="80"
          rx="12"
          {...bem('line')}
        />
      </svg>

      {text && <figcaption {...bem('text')}>{text}</figcaption>}
    </figure>
  )
}

Loader.propTypes = {
  type: T.oneOfType([T.string, T.array]),
  delay: T.number,
  text: T.string,
}

Loader.defaultProps = {
  delay: 300, // ms
  transparent: false,
}
