import React, { useEffect, ReactNode } from 'react'
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom'
import { get } from 'lodash'

import useIsSignedIn from '../hooks/signed-in'
import isNative from '../hooks/isNative'
import Login, { routeProps as loginRouteProps } from './Login'
import Logout, { routeProps as logoutRouteProps } from './Logout'
import Home, { routeProps as homeRouteProps } from './Home'
import MyPage, { routeProps as myPageRouteProps } from './MyPage'
import Terms, { routeProps as termsRouteProps } from './Terms'
import Privacy, { routeProps as privacyRouteProps } from './Privacy'
import Charges, { routeProps as chargesRouteProps } from './Charges'
import Consents, { routeProps as consentsRouteProps } from './Consents'
import Data, { routeProps as dataRouteProps } from './Data'
import ThankYou, { routeProps as thankYouRouteProps } from './ThankYou'
import Courses, { routeProps as coursesRouteProps } from './Courses'
import Course, { routeProps as courseRouteProps } from './Course'
import Lecture, { routeProps as lectureRouteProps } from './Lecture'
import Steps, { routeProps as stepsRouteProps } from './Steps'
import Recipes, { routeProps as recipesRouteProps } from './Recipes'
import Category, { routeProps as categoryRouteProps } from './Category'
import Recipe, { routeProps as recipeRouteProps } from './Recipe'
import Search, { routeProps as searchRouteProps } from './Search'
import Favourites, { routeProps as favouritesRouteProps } from './Favourites'
import Week, { routeProps as weekRouteProps } from './Week'
import Weeks, { routeProps as weeksRouteProps } from './Weeks'
import Grocery, { routeProps as groceryRouteProps } from './Grocery'
import Equipment, { routeProps as equipmentRouteProps } from './Equipment'
import Try, { routeProps as tryRouteProps } from './Try'
import Push, { routeProps as pushRouteProps } from './Push'
import ChangePassword, {
  routeProps as changePasswordRouteProps,
} from './ChangePassword'
import FourOhFour from './404'
import { PageTransition, Navbar, Header } from '../components'
import postMessage from '../_shared/lib/post-message'

const HIDE_NAVBAR_ROUTES = [
  /oppskrifter\/(.+)/,
  /login/,
  /sok/,
  /utstyr/,
  /prov/,
  /kurs\/(.+)/,
  /tema\/(.+)/,
  /uke/,
  /uke\/(.+)/,
  /kategori\/(.+)/,
  /min-side\/(.+)/,
]

const HIDE_HEADER_ROUTES = [
  /oppskrifter\/(.+)/,
  /login/,
  /endre-passord/,
  /sok/,
  /utstyr/,
  /prov/,
  /kurs\/(.+)/,
  /tema\/(.+)/,
  /uke/,
  /uke\/(.+)/,
  /min-side\/(.+)/,
  /kategori\/(.+)/,
]

const setHeight = (): void => {
  // Alternative to vh-units in css that doesn't account for bottom-bar in ios
  const height = window.innerHeight
  const vh = height / 100
  const root = document.documentElement
  root.style.setProperty('--vh', `${vh}px`)
}

// Variables for storing scroll
const storeScroll = new Map()
let prevPath: string

export default function Pages(): JSX.Element | null {
  useEffect(() => {
    window.addEventListener('resize', setHeight)
    setHeight()

    return (): void => {
      window.removeEventListener('resize', setHeight)
    }
  }, [])

  const history = useHistory()
  const native = isNative()

  useEffect(() => {
    postMessage({ type: 'location', location: history.location.pathname })
  }, [history.location.pathname])

  useEffect(() => {
    if (native) {
      document.body.classList.add('ios-native')
    }
  }, [native])

  const { loading, signedIn } = useIsSignedIn()

  if (loading) {
    return null
  }

  return (
    <Route
      render={({ location }): ReactNode => {
        const { pathname, state } = location
        const scrollToTop = get(state, 'scrollToTop')

        // Get current scrollpos
        const scrollPos =
          window.pageYOffset ||
          document.body.scrollTop ||
          document.documentElement.scrollTop

        // Get previously stored scroll pos
        const newScrollPos = scrollToTop
          ? 0
          : storeScroll.get(pathname.replace(/\/$/, '')) || 0

        // Set the min-height on body so that we can scroll that far
        document.body.style.minHeight = `${newScrollPos + window.innerHeight}px`

        // Set --scroll-pos variable for stuff that's exiting
        const root = document.documentElement
        root.style.setProperty('--scroll-pos', `${-scrollPos + newScrollPos}px`)

        // Set scroll pos to window
        setTimeout(() => window.scrollTo(0, newScrollPos), 50)

        // Potential cleanups that's needed
        document.body.classList.remove('block-scrolling')
        document.body.classList.remove('modal-open')

        // Hide navbar and header on specific routes
        const hideNavbar = HIDE_NAVBAR_ROUTES.find((pattern) =>
          pathname.match(pattern),
        )
        const hideHeader = HIDE_HEADER_ROUTES.find((pattern) =>
          pathname.match(pattern),
        )

        // Store the scrollpos
        storeScroll.set((prevPath || pathname).replace(/\/$/, ''), scrollPos)

        // Store pathname for next route change
        prevPath = pathname

        return (
          <main className={`${hideNavbar ? '' : 'has-navbar'}`}>
            <Header show={!hideHeader} menu absolute search />

            {/* LOGGED IN CHECK AND REDIRECT */}
            {!signedIn && !native ? (
              <Switch>
                <Route {...loginRouteProps}></Route>
                <Route {...privacyRouteProps}></Route>
                <Route exact path="/personvern">
                  <Redirect to={privacyRouteProps.path}/>
                </Route>
                <Redirect to={loginRouteProps.path} />
              </Switch>
            ) : null}

            {signedIn && (
              <Route {...loginRouteProps}>
                <Redirect to={homeRouteProps.path} />
              </Route>
            )}

            <PageTransition
              location={location}
              exitScrollPos={`${-scrollPos + newScrollPos}px`}
            >
              {(status: string): ReactNode => {
                const entering = !!status.match(/enter/)

                /**
                 * ✨Higher order component that builds a renderProp for the route ✨
                 *
                 * 1. takes a component
                 * 2. returns a function that takes a route match object, containing
                 *    a match property
                 * 3. returns JSX rendring the provided component with the applyColors
                 *    prop set to true if the route matched and it is either entered
                 *    or entering
                 */
                function makeRenderProp(
                  Component: React.FunctionComponent<
                    RouteComponentProps & { applyColors: boolean }
                  >,
                ) {
                  return function WrappedFunction(
                    props: RouteComponentProps,
                  ): JSX.Element {
                    return (
                      <Component
                        {...props}
                        applyColors={!!props.match && entering}
                      />
                    )
                  }
                }

                return (
                  <Switch location={location}>
                    {/* LOGIN PAGE */}
                    <Route
                      {...loginRouteProps}
                      render={makeRenderProp(Login)}
                    />

                    {/* SIGNOUT */}
                    <Route
                      {...logoutRouteProps}
                      render={makeRenderProp(Logout)}
                    />

                    {/* HOME PAGE */}
                    <Route {...homeRouteProps} render={makeRenderProp(Home)} />

                    {/* MY PAGE */}
                    <Route
                      {...myPageRouteProps}
                      render={makeRenderProp(MyPage)}
                    />

                    {/* TERMS PAGE */}
                    <Route
                      {...termsRouteProps}
                      render={makeRenderProp(Terms)}
                    />

                    {/* DATA PAGE */}
                    <Route {...dataRouteProps} render={makeRenderProp(Data)} />

                    {/* CONSENTS PAGE */}
                    <Route
                      {...consentsRouteProps}
                      render={makeRenderProp(Consents)}
                    />

                    {/* CHARGES LIST PAGE */}
                    <Route
                      {...chargesRouteProps}
                      render={makeRenderProp(Charges)}
                    />

                    {/* PRIVACY PAGE */}
                    <Route
                      {...privacyRouteProps}
                      render={makeRenderProp(Privacy)}
                    />

                    {/* CHANGE/SET PASSWORD PAGE */}
                    <Route
                      {...changePasswordRouteProps}
                      render={makeRenderProp(ChangePassword)}
                    />

                    {/* ? PAGE */}
                    <Route
                      {...thankYouRouteProps}
                      render={makeRenderProp(ThankYou)}
                    />

                    {/* COURSE PAGES */}
                    <Route
                      {...coursesRouteProps}
                      render={makeRenderProp(Courses)}
                    />
                    <Route
                      {...courseRouteProps}
                      render={makeRenderProp(Course)}
                    />
                    <Route
                      {...lectureRouteProps}
                      render={makeRenderProp(Lecture)}
                    />

                    {/* RECIPE PAGES */}
                    <Route
                      {...recipesRouteProps}
                      render={makeRenderProp(Recipes)}
                    />
                    <Route
                      {...recipeRouteProps}
                      render={makeRenderProp(Recipe)}
                    />
                    <Route
                      {...stepsRouteProps}
                      render={makeRenderProp(Steps)}
                    />

                    {/* CATEGORY PAGE */}
                    <Route
                      {...categoryRouteProps}
                      render={makeRenderProp(Category)}
                    />

                    {/* SEARCH PAGES */}
                    <Route
                      {...searchRouteProps}
                      render={makeRenderProp(Search)}
                    />

                    {/* FAVOURITES PAGE */}
                    <Route
                      {...favouritesRouteProps}
                      render={makeRenderProp(Favourites)}
                    />

                    {/* WEEK PAGES */}
                    <Route {...weekRouteProps} render={makeRenderProp(Week)} />
                    <Route
                      {...weeksRouteProps}
                      render={makeRenderProp(Weeks)}
                    />

                    {/* GROCERY PAGE */}
                    <Route
                      {...groceryRouteProps}
                      render={makeRenderProp(Grocery)}
                    />

                    {/* EQUIPMENTSES PAGE */}
                    <Route
                      {...equipmentRouteProps}
                      render={makeRenderProp(Equipment)}
                    />

                    {/* TRY PAGE */}
                    <Route {...tryRouteProps} render={makeRenderProp(Try)} />

                    {/* PUSH TEST PAGE */}
                    <Route {...pushRouteProps} render={makeRenderProp(Push)} />

                    <Route render={makeRenderProp(FourOhFour)} />

                    {/* TEMP PAGES */}
                    {/* <Route {...paymentRouteProps} render={makeRenderProp(Payment)} /> */}
                  </Switch>
                )
              }}
            </PageTransition>

            <Navbar show={!hideNavbar} />
          </main>
        )
      }}
    />
  )
}
