import React from 'react'
import T, { InferProps } from 'prop-types'

import { Layout } from '../components'
import { Button } from '../_shared/components'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'

export default function Error404({ applyColors, title }: InferProps<typeof Error404.propTypes>): JSX.Element {
  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, '404')
  }

  return (
    <Layout cover={{ title: title || 'Kommer snart' }}>
      <div style={{ textAlign: 'center' }}>
        <Button
          primary
          full
          icon="home"
          onClick={(): void => { window.location.pathname = '/' }}
        >Ta meg hjem</Button>
      </div>
    </Layout>
  )
}

Error404.propTypes = {
  applyColors: T.bool.isRequired,
  title: T.string,
}
