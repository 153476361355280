import gql from 'graphql-tag'

export default gql`
  query {
    isSignedIn @client
    me {
      node {
        id
      }
    }
  }
`
