import gql from 'graphql-tag'

export const ADD_FAVORITE_RECIPE = gql`
  mutation AddFavoriteRecipe($id: ID!) {
    addFavoriteRecipe(recipeId: $id) {
      success
      recipe {
        node {
          id
          isFavorite
        }
      }
    }
  }
`

export const REMOVE_FAVORITE_RECIPE = gql`
  mutation RemoveFavoriteRecipe($id: ID!) {
    removeFavoriteRecipe(recipeId: $id) {
      success
      recipe {
        node {
          id
          isFavorite
        }
      }
    }
  }
`

export const ADD_FAVORITE_COURSE = gql`
  mutation AddFavoriteCourse($id: ID!) {
    addFavoriteCourse(courseId: $id) {
      success
      course {
        node {
          id
          isFavorite
        }
      }
    }
  }
`

export const REMOVE_FAVORITE_COURSE = gql`
  mutation RemoveFavoriteCourse($id: ID!) {
    removeFavoriteCourse(courseId: $id) {
      success
      course {
        node {
          id
          isFavorite
        }
      }
    }
  }
`
