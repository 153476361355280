import React, { useState, ReactElement } from 'react'
import { get } from 'lodash'
import T, { InferProps } from 'prop-types'
import { useLocation, useParams, useHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'

import getColor from '../_shared/lib/color'
import extractIngredients from '../lib/extractIngredients'
import setColors from '../lib/setColors'
import trackCustomEvent from '../helpers/track-custom-event'
import { Steps } from '../components'
import STEPS_QUERY from '../queries/pages/Steps'
import FAVORITES_QUERY from '../queries/pages/Favourites'
import {
  ADD_FAVORITE_RECIPE,
  REMOVE_FAVORITE_RECIPE,
} from '../queries/mutations/favorite'
import SAVE_RATING from '../queries/mutations/save-rating'
import { RouteProps } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = {
  path: '/oppskrifter/:recipe/steps/',
}

export default function StepsPage({
  applyColors,
}: InferProps<typeof StepsPage.propTypes>): ReactElement {
  const { state } = useLocation()
  const { recipe: slug } = useParams()
  const history = useHistory()

  const [addFavorite] = useMutation(ADD_FAVORITE_RECIPE)
  const [removeFavorite] = useMutation(REMOVE_FAVORITE_RECIPE)

  // Fish the persons param out of the search query
  const back = get(state, 'back', '/oppskrifter')
  const persons = get(state, 'persons', 'two')
  const [portions, setPortions] = useState(persons)
  const [rating, setRating] = useState(0)

  const { loading, error, data } = useQuery(STEPS_QUERY, {
    variables: { slug },
    fetchPolicy: 'network-only',
  })

  const recipe = get(data, 'recipe.node', null)

  const [saveRating] = useMutation(SAVE_RATING)

  // Set colors
  if (!loading && recipe && applyColors) {
    const colors = getColor(
      get(recipe, 'image.asset.metadata.palette'),
      get(recipe, 'color'),
    )
    colors && setColors(colors, `recipe/${slug}/steps`)
  }

  const id = get(recipe, 'id')
  const isFavorite = get(recipe, 'isFavorite')

  function handleRating(rating: number): void {
    setRating(rating)
    saveRating({
      variables: {
        type: 'recipe',
        itemId: id,
        rating,
      },
    })
  }

  const toggleFavorite = (): void => {
    if (isFavorite) {
      removeFavorite({
        variables: { id },
        optimisticResponse: {
          removeFavoriteRecipe: {
            success: true,
            __typename: 'RemoveFavoriteRecipePayload',
            recipe: {
              __typename: 'RecipesEdge',
              node: { __typename: 'Recipe', id, isFavorite: false },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent(
        'Receipt favourite',
        'Add to favourites',
        'Vurder denne oppskriften',
        1,
      )
    } else {
      addFavorite({
        variables: { id },
        optimisticResponse: {
          addFavoriteRecipe: {
            success: true,
            __typename: 'AddFavoriteRecipePayload',
            recipe: {
              __typename: 'RecipesEdge',
              node: { __typename: 'Recipe', id, isFavorite: true },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent(
        'Receipt favourite',
        'Remove from favourites',
        'Vurder denne oppskriften',
        -1,
      )
    }
  }

  const ingredients = extractIngredients(get(recipe, 'steps', []))

  const firstStep = {
    id: 'first',

    ...(recipe?.quantitySettings?.mode === 'full'
      ? {
        title: 'Hvor mange skal spise?',
        portions: {
          options: [
            ['two', 2],
            ['four', 4],
            ['six', 6],
            ['eight', 8],
          ],
          setPortions,
        },
      }
      : {
        title: 'Hvor mye vil du lage?',
        portions: {
          type: 'small',
          options: [
            ['two', recipe?.quantitySettings?.singleHeading || 'enkel'],
            ['four', recipe?.quantitySettings?.doubleHeading || 'dobbel'],
          ],
          setPortions,
        },
      }),
    ingredients,
  }

  const lastStep = {
    id: 'bon-appetit',
    title: 'Vurder denne retten',
    rating,
    setRating: handleRating,
    isFavorite,
    setFavorite: toggleFavorite,
  }

  const stepsData = {
    ...(recipe || {}),
    steps: [firstStep, ...(get(recipe, 'steps') || []), lastStep],
  }

  if (error) {
    throw error
  }

  if (!loading && !recipe) {
    return <Error404 applyColors title="Oppskriften finnes ikke" />
  }

  return (
    <Steps
      loading={loading}
      recipeSlug={slug}
      data={stepsData}
      history={history}
      persons={portions}
      back={back}
      ingredients={ingredients}
    />
  )
}

StepsPage.propTypes = {
  applyColors: T.bool.isRequired,
}
