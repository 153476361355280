import Hypher from 'hypher'
import norwegian from 'hyphenation.nb-no'

const h = new Hypher({ ...norwegian, leftmin: 4, rightmin: 4 })

export function breakLongWord(string, minLength = 10) {
  return (string || '')
    .split(' ')
    .map(str => {
      if (str.length > minLength) {
        return h.hyphenateText(str)
      }
      return str
    })
    .join(' ')
}

// export function truncate(string, maxLength) {
//   return string.length > maxLength
//     ? `${string.substring(0, maxLength)} (...)`
//     : string
// }

// export function upperCaseFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1)
// }
