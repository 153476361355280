/* eslint-disable react-hooks/exhaustive-deps */

import './index.scss'

import React, { useEffect, useState, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { get, throttle } from 'lodash'

import usePrevious from '../../hooks/previous'
import getColor from '../../_shared/lib/color'
import { Icon, AspectRatioImage, Portal, Transition } from '../../_shared/components'

const ROOT_ELEMENT = document.querySelector('#root')

const bem = new BEMHelper('equipment')

export default function Equipment({ title, preamble, url, images, onClose, show = false }) {
  const imagesRef = useRef()
  const [active, setActive] = useState(0)
  const pallette = get(images, '[0].asset.metadata.palette', null)
  const colors = getColor(pallette)

  const prevShow = usePrevious(show)

  useEffect(() => {
    let _timeout = null

    if (show) {
      document.body.classList.add('modal-open')

      if (imagesRef.current) {
        imagesRef.current.addEventListener('scroll', handleScroll)
      }

      _timeout = setTimeout(() => {
        document.body.classList.add('modal-open')
      }, 600)
    } else if (prevShow) {
      clearTimeout(_timeout)
      document.body.classList.remove('modal-open')

      if (imagesRef.current) {
        imagesRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [show])

  const handleScroll = throttle(() => {
    const index = Math.round((imagesRef.current.scrollLeft / imagesRef.current.scrollWidth) * images.length)

    setActive(index)
  }, 200)

  return (
    <Portal insertTo={ROOT_ELEMENT}>
      <Transition updateKey={show.toString()} {...bem('')} enter={700} exit={500}>
        {show && (
          <>
            <div
              {...bem('wrapper')}
              style={{
                '--modal-color-base': colors.base,
                '--modal-color-highlighted': colors.highlighted,
              }}
            >
              <div {...bem('modal')}>
                <figure {...bem('images')} ref={imagesRef}>
                  {images.map(image => (
                    <AspectRatioImage
                      alt={title}
                      key={image.url}
                      src={image.url}
                      lqip={image.lqip}
                      aspect="1/1"
                      noLazyLoad
                      {...bem('image')}
                    />
                  ))}
                </figure>
                <nav {...bem('indicators')}>
                  <ol {...bem('indicator-wrapper')}>
                    {images.map((image, index) => (
                      <li {...bem('indicator', { active: index === active })} key={image.url} />
                    ))}
                  </ol>
                </nav>
                <div {...bem('content')}>
                  <h2 {...bem('title')}>{title}</h2>
                  <p {...bem('preamble')}>{preamble}</p>
                  {/* <Button primary {...bem('action')}>
                    Kjøp
                  </Button> */}
                </div>
              </div>

              <button {...bem('close')} type="button" aria-label="Lukk" onClick={onClose}>
                <Icon icon="close" {...bem('icon')} />
              </button>
            </div>
            <button {...bem('backdrop')} type="button" onFocus={onClose} onClick={onClose} aria-label="Lukk" />
          </>
        )}
      </Transition>
    </Portal>
  )
}

Equipment.propTypes = {
  title: T.string.isRequired,
  preamble: T.string.isRequired,
  url: T.string.isRequired,
  images: T.array.isRequired,
  onClose: T.func.isRequired,
  show: T.bool,
}
