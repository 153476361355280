// TODO Duplicate error code should be resolved. Must check for deps in clients and graphql
export const ERROR_INVALID_CREDENTIALS = { code: 1, message: 'Invalid credentials' }
export const ERROR_EXPIRED_TOKEN = { code: 1, message: 'Expired token' }
export const ERROR_USERNAME_EXISTS = { code: 2, message: 'User exists' }
export const ERROR_USER_NOT_FOUND = { code: 3, message: 'User not found' }
export const UNCONNECTED_USER_WITH_SAME_EMAIL = { code: 4, message: 'User with same email exist' }
export const USER_CREATION_ERROR = { code: 5, message: 'An error occured while creating the user' }
export const ERROR_USER_ALREADY_CONNECTED = { code: 6, message: 'User is already connected' }
export const SOCIAL_USER_ALREADY_CONNECTED = { code: 7, message: 'Social user is already connected' }
export const ERROR_INVALID_RESET_REQUEST = { code: 8, message: 'Invalid reset request and/or OTP' }
export const USER_UPDATE_ERROR = { code: 9, message: 'An error occured while updating the user' }
export const INVALID_EMAIL = { code: 10, message: 'Invalid email address' }
export const ORPHAN_ERROR = { code: 11, message: 'Cannot disconnect last social login for user without password' }
