import gql from 'graphql-tag'

export const STOP_RENEWAL = gql`
  mutation StopRenewal($planId: String!) {
    stopRenewal(planId: $planId) {
      success
      plan {
        id
        paymentAgreementStatus
      }
    }
  }
`

export const RESTART_RENEWAL = gql`
  mutation RestartRenewal($planId: String!) {
    restartRenewal(planId: $planId) {
      success
      plan {
        id
        paymentAgreementStatus
      }
    }
  }
`
