import gql from 'graphql-tag'

import PageSections from '../fragments/page-sections'
import PaletteFragment from '../fragments/palette'

export default gql`
  query Recipes {
    page(id: "recipes") {
      ... on RecipesPage {
        promoted {
          __typename
          ... on RecipesEdge {
            node {
              id
              slug
              title
              duration
              color
              image {
                url(width: 600, height: 732)
                lqip
                asset {
                  metadata {
                    palette {
                      ...Palette
                    }
                  }
                }
              }
            }
          }
          ... on WeekMenusEdge {
            node {
              id
              week
              year
              slug
              title
              recipes {
                count
                edges {
                  node {
                    title
                    color
                    image {
                      url(width: 600, height: 732)
                      lqip
                      asset {
                        metadata {
                          palette {
                            ...Palette
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        sections {
          ...PageSections
        }
      }
    }

    categories: categoryTypes {
      edges(promoted: true) {
        node {
          title
          id
          categories {
            count
            edges {
              node {
                id
                slug
                title
                courses { count }
                recipes { count }
              }
            }
          }
        }
      }
    }
  }

  ${PageSections}
  ${PaletteFragment}
`
