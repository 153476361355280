import './index.scss'

import React, { Component } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { keyframes } from 'emotion'

import { Icon } from '../../_shared/components'

const bem = new BEMHelper('step-accordion')

const DURATION = 300
const EASING = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'

export default class StepAccordion extends Component {
  static propTypes = {
    id: T.string,
    title: T.string,
    expanded: T.bool,
    disabled: T.bool,
    children: T.any,
    elementAfter: T.any,
    onToggle: T.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: this.props.expanded,
      animating: false,
      height: null,
    }

    this._animationTimer = null
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expanded === this.props.expanded) {
      return
    }

    this.setState({
      expanded: nextProps.expanded,
      animating: false,
    })

    this.removeAnimationClass({
      duration: nextProps.expanded ? 550 : 200,
    })

    if (!nextProps.expanded) {
      const { elementAfter } = this.props
      if (this._wrapper && this._content && elementAfter) {
        this.handleToggle()
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this._animationTimer)
  }

  removeAnimationClass = ({ duration = 550 }) => {
    this._animationTimer = setTimeout(() => {
      this.setState({ animating: false })
    }, duration)
  }

  handleToggle = () => {
    const { elementAfter } = this.props
    const expanded = !this.state.expanded

    const heightBefore = this._wrapper.offsetHeight
    const afterPosBefore = (elementAfter && elementAfter.offsetTop) || 0

    this.setState(
      { expanded, animating: true },
      this.animate({
        heightBefore,
        afterPosBefore,
        expanded,
      }),
    )
    this.removeAnimationClass({ duration: expanded ? 500 : 400 })
  }

  animate = ({ heightBefore, expanded, afterPosBefore }) => () => {
    const { elementAfter, onToggle } = this.props
    const height = expanded ? this._wrapper.offsetHeight : heightBefore
    const moveUp = `translateY(-${height / 2}px)`
    const moveDown = `translateY(calc(${height / 2}px - 2rem))`

    if (onToggle) {
      onToggle({ expanded, height: height / 2 })
    }

    const wrapper = keyframes(expanded ? { to: { transform: moveUp } } : { from: { transform: moveUp } })

    const content = keyframes(
      expanded
        ? { from: { opacity: 0, transform: `${moveUp} scale(0.7)` } }
        : { to: { opacity: 0, transform: `${moveUp} scale(0.8)` } },
    )

    const after = keyframes(expanded ? { to: { transform: moveDown } } : { from: { transform: moveDown } })

    this._wrapper.style.animation = `${wrapper} ${DURATION}ms ${EASING} forwards`
    this._content.style.animation = `${content} ${DURATION}ms ${EASING} forwards`

    if (elementAfter) {
      elementAfter.style.animation = `${after} ${DURATION}ms ${EASING} forwards`
    }
  }

  render() {
    const { title, children, disabled } = this.props
    const { expanded, animating } = this.state

    const animateout = !expanded && animating

    return (
      <div
        {...bem('', {
          disabled,
          expanded,
          'animate-out': animateout,
        })}
        ref={ref => {
          this._wrapper = ref
        }}
      >
        <button {...bem('toggle')} type="button" disabled={disabled} onClick={this.handleToggle} aria-expanded={expanded}>
          <h2 {...bem('title')}>{title}</h2>

          <span {...bem('indicator')}>
            <Icon icon="chevron" size="small" direction="down" />
          </span>
        </button>

        {(expanded || animateout) && (
          <div
            {...bem('content')}
            ref={ref => {
              this._content = ref
            }}
          >
            {children}
          </div>
        )}
      </div>
    )
  }
}
