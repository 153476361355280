import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Recipe($slug: String!) {
    recipe(slug: $slug) {
      node {
        id
        slug
        title
        preamble
        duration
        tips
        isFavorite
        hasAccess
        color
        beverageTips {
          edges {
            node {
              id
              title
              content
              url
            }
          }
        }
        quantitySettings {
          mode
          singleHeading
          doubleHeading
        }
        equipment {
          edges {
            node {
              id
              title
              images {
                lqip
                url(width: 440, height: 440)
                asset {
                  metadata {
                    palette {
                      ...Palette
                    }
                  }
                }
              }
              preamble
              url
            }
          }
        }
        image {
          asset {
            metadata {
              palette {
                ...Palette
              }
            }
          }
          lqip
          url(width: 600)
        }
        steps {
          id
          title
          ingredients(onlyInIngredientList: true) {
            id
            title
            quantity {
              two
              four
              six
              eight
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
