import './index.scss'

import React, { Component } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Hammer from 'hammerjs'
import { Link } from 'react-router-dom'

import { Progress, Step } from '..'
import { Loader, Icon } from '../../_shared/components'
import trackCustomEvent from '../../helpers/track-custom-event'

const bem = new BEMHelper('steps')

const MARGIN = 3
const SENSITIVITY = 10
const SWIPE_END_MODIFIER = 0.3

export default class Steps extends Component {
  static propTypes = {
    recipeSlug: T.string.isRequired,
    data: T.object.isRequired,
    history: T.object.isRequired,
    loading: T.bool,
    ingredients: T.array,
    persons: T.oneOf(['two', 'four', 'six', 'eight']),
    back: T.string,
  }

  static defaultProps = {
    persons: 'two',
  }

  state = {
    active: 0,
    animating: false,
    overlay: false,
    ingrediensExpanded: false,
  }

  _timeout = null
  _bounceTimeout = null

  componentDidMount() {
    this.initSwipe()
    document.body.classList.add('modal-open')

    document.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    clearTimeout(this._bounceTimeout)
    clearTimeout(this._timeout)
    document.body.classList.remove('modal-open')

    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = ({ keyCode }) => {
    const { active } = this.state

    if (keyCode === 37) {
      this.goTo(active - 1)
    } else if (keyCode === 39) {
      this.goTo(active + 1)
    }
  }

  trackStep = step => {
    const length = this.props.data.steps.length
    const progress = (step + 1) / length
    trackCustomEvent('Receipt step', step + 1, length, progress)
  }

  initSwipe = () => {
    this._hammer = new Hammer.Manager(this._slider)
    this._hammer.add(new Hammer.Pan({ threshold: 10, pointers: 0 }))
    this._hammer.on('pan', this.pan)
  }

  pan = event => {
    event.preventDefault()
    const { active } = this.state
    const {
      data: { steps },
    } = this.props
    const count = steps.length
    let modifier = 1

    const MAX = 100 / count

    // Calculate pixel movements into 1:1 screen percents so gestures track with motion
    const percentage = Math.min(MAX, Math.max(-MAX, ((100 / count) * event.deltaX) / window.innerWidth))

    if ((active === 0 && percentage > 0) || (active + 1 === count && percentage < 0)) {
      modifier = SWIPE_END_MODIFIER
    }

    // Multiply percent by # of slide we're on
    const percentageCalculated = percentage * modifier - (100 / count) * active

    // Apply transformation
    if (this._slider) {
      this._slider.style.transform = `translateX(calc(${percentageCalculated}% - ${MARGIN * active}px))`
    }

    // Snap to slide when done
    if (event.isFinal) {
      if (event.velocityX > 1) {
        this.goTo(active - 1)
      } else if (event.velocityX < -1) {
        this.goTo(active + 1)
      } else {
        if (percentage <= -(SENSITIVITY / count)) {
          this.goTo(active + 1)
        } else if (percentage >= SENSITIVITY / count) {
          this.goTo(active - 1)
        } else {
          this.goTo(active)
        }
      }
    }
  }

  goTo = number => {
    const {
      data: { steps },
    } = this.props
    const count = steps.length
    let active = number

    // Stop it from doing weird things like moving to steps that don't exist
    if (number < 0) {
      active = 0
    } else if (number > count - 1) {
      active = count - 1
    }

    // Track navigation
    this.trackStep(active)

    // Apply transformation & smoothly animate
    this.setState({ animating: true, active }, this.onStepChanged)

    const percentage = -(100 / count) * active

    if (this._slider) {
      this._slider.style.transform = `translateX(calc(${percentage}% - ${MARGIN * active}px))`
    }
  }

  onStepChanged = () => {
    clearTimeout(this._timeout)

    const activeStep = this._slider.querySelector('.steps__item--active')

    this._timeout = setTimeout(() => {
      this.setState({ animating: false })

      if (activeStep) {
        activeStep.focus()
      }
    }, 400)
  }

  toggleOverlay = overlay => {
    this.setState({ overlay })
  }

  toggleIngredients = ingrediensExpanded => {
    this.setState({ ingrediensExpanded })
  }

  render() {
    const { animating, active, overlay, ingrediensExpanded } = this.state
    const {
      data: { steps },
      persons,
      loading,
      recipeSlug,
      back,
      ingredients,
    } = this.props

    return (
      <div
        {...bem('')}
        style={{ '--margin': `${MARGIN}px` }}
        ref={ref => {
          this._wrapper = ref
        }}
      >
        <Link
          to={{
            pathname: `/oppskrifter/${recipeSlug}`,
            state: { from: back },
          }}
          {...bem('close')}
          aria-label="Lukk steg"
        >
          <Icon icon="close" size="small" />
        </Link>
        <span {...bem('background')} />

        <ol
          {...bem('wrapper', { animating })}
          style={{ width: `${steps.length * 100}%` }}
          ref={ref => {
            this._slider = ref
          }}
        >
          {loading && <Loader />}
          {steps.map((item, index) => {
            const previous = active === index + 1
            const isActive = active === index
            const next = active === index - 1

            const distance = Math.abs(active - index) // Distance from active step
            const renderStep = distance < 4

            const activeStepProps = isActive ? { tabIndex: '0' } : null

            return (
              <li
                {...bem('item', {
                  active: isActive,
                  previous,
                  next,
                })}
                key={item.id}
                {...activeStepProps}
              >
                {renderStep && (
                  <Step
                    {...item}
                    active={isActive}
                    persons={persons}
                    goTo={this.goTo}
                    wrapperRef={this._wrapper}
                    toggleOverlay={this.toggleOverlay}
                    onToggleIngredients={this.toggleIngredients}
                  />
                )}
              </li>
            )
          })}
        </ol>

        <Progress
          step={active + 1}
          steps={steps}
          goTo={this.goTo}
          loading={loading}
          persons={persons}
          ingredients={ingredients}
          hideProgress={overlay || ingrediensExpanded}
        />
      </div>
    )
  }
}
