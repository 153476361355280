import React, { useState, useEffect, ChangeEvent, ReactElement } from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'

import { Form } from '../_shared/components'
import { RouteProps } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { Layout, Message } from '../components'

export const routeProps: RouteProps = { path: '/endre-passord', exact: true }

const SAVED_MESSAGE_DELAY = 3000

export default function ChangePassword({
  applyColors,
}: InferProps<typeof ChangePassword.propTypes>): ReactElement|null {
  const [values, setValues] = useState({ password: null, newPassword: null })
  const [error, setError] = useState<string|null>(null)
  const [saved, setSaved] = useState(false)
  const history = useHistory()

  const {
    loading,
    data: {
      me: {
        node: {
          hasPassword = false,
        } = {},
      } = {},
    } = {},
  } = useQuery(gql`{ me { node { hasPassword } } }`, { fetchPolicy: 'no-cache' })

  const [changePassword] = useMutation(gql`
    mutation LoginSocial($password: String $newPassword: String!) {
      changePassword(password: $password, newPassword: $newPassword) {
        success
        error {
          code
          message
        }
      }
    }
  `)

  useEffect(() => {
    const _timer = setTimeout(() => {
      if (saved) {
        setSaved(false)
      }
    }, SAVED_MESSAGE_DELAY)

    return (): void => clearTimeout(_timer)
  }, [saved])

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'change-password')
  }

  const handleSubmit = async(event: ChangeEvent<HTMLFormElement>): Promise<void> => {
    setError(null)
    setSaved(false)
    event.preventDefault()

    if (!values.newPassword) {
      return setError('Skriv inn passord')
    }

    const {
      data: {
        changePassword: {
          error = null,
          success = false,
        } = {},
      } = {},
    } = await changePassword({
      variables: {
        password: values.password,
        newPassword: values.newPassword,
      },
    })

    if (success) {
      setSaved(true)
      setTimeout(() => history.push('/min-side'), SAVED_MESSAGE_DELAY)
      return
    }

    switch (error.code) {
      case 1: return setError('Feil passord')
      case 9: return setError('Feil under uppdatering av bruker')
      default: return setError(error.message)
    }
  }

  const handleChange = (key: string) => (value: string): void => {
    setValues({ ...values, [key]: value })
  }

  const form = {
    items: [
      ...(hasPassword ? [{
        item: 'input',
        id: 'old-password',
        name: 'old-password',
        label: 'Gammelt passord',
        type: 'password',
        required: true,
        minLength: 6,
        value: values.password || '',
        onChange: handleChange('password'),
        autoComplete: 'new-password',
      }] : []),
      {
        item: 'input',
        id: 'new-password',
        name: 'new-password',
        label: 'Nytt passord',
        type: 'password',
        required: true,
        minLength: 6,
        value: values.newPassword || '',
        onChange: handleChange('newPassword'),
        autoComplete: 'new-password',
      },
      {
        id: 'error',
        item: 'warning',
        value: error,
      },
    ],
    onSubmit: handleSubmit,
    submitProps: { large: 'true' },
    submit: 'Lagre',
  }

  if (loading) { return null }

  return (
    <Layout
      title={hasPassword ? 'Endre passord' : 'Sett passord'}
      header={{
        back: { name: 'Min side', to: '/min-side' },
      }}
    >
      <Form steps={{ form }} />
      {saved && (
        <Message
          message="Passordet er endret"
          duration={SAVED_MESSAGE_DELAY}
          icon="passwordHidden"
        />
      )}
    </Layout>
  )
}

ChangePassword.propTypes = {
  applyColors: T.bool.isRequired,
}
