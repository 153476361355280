import gql from 'graphql-tag'

export const RESET_PROGRESS = gql`
  mutation ResetLecturesProgress {
    resetLecturesProgress {
      success
      courses {
        edges {
          node {
            id
            progress
            lectures {
              edges {
                node {
                  id
                  progress
                }
              }
            }
          }
        }
      }
    }
  }
`
