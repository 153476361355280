import gql from 'graphql-tag'

export default gql`
  mutation LoginFacebook($accessToken: String!) {
    loginFacebook(accessToken: $accessToken) {
      token
      error {
        code
        message
      }
    }
  }
`
