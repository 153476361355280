import React, { createContext, useEffect, useState } from "react";

type NativeState = {
  hasNotificationPermission?: boolean
  isPushDisabled?: boolean
}

export const NativeStateContext = createContext<NativeState>({})

export default function NativeStateProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<NativeState>({})

  useEffect(() => {
    const listener = function ({
      detail: {
        id,
        timestamp,
        ...patchState
      }
    }: any) {
      setState(current => ({
        ...current,
        ...(patchState ?? {}) as unknown as NativeState
      }))
    }

    window.addEventListener('patchState', listener, false);
    return () => window.removeEventListener('patchState', listener)
  }, [])

  return <NativeStateContext.Provider value={state}>
    {children}
  </NativeStateContext.Provider>
}
