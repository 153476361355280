import './index.scss'

import React, { useState } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Tabs } from '../../_shared/components'
import Ingredients from '../Ingredients'

const bem = new BEMHelper('overview')

export default function Overview({ steps, ingredients, persons, goTo }) {
  const [tab, setTab] = useState('steps')

  return (
    <nav {...bem('')}>
      <Tabs
        active={tab}
        onChange={setTab}
        items={[
          { label: 'Steg', id: 'steps' },
          { label: 'Ingredienser', id: 'ingredients' },
        ]}
      />

      {tab === 'steps' && steps && steps.map(({ _key, title }, index) => (
        <button
          key={_key || index}
          {...bem('item')}
          onClick={() => goTo(index)}
        >
          <strong {...bem('number')}>{index + 1}</strong>
          <h2 {...bem('title')}>{title}</h2>
        </button>
      ))}

      {tab === 'ingredients' && ingredients && persons && (
        <Ingredients
          items={ingredients}
          persons={persons}
          bullets
          animate
        />
      )}
    </nav>
  )
}

Overview.propTypes = {
  steps: T.array.isRequired,
  ingredients: T.array.isRequired,
  persons: T.oneOf(['two', 'four', 'six', 'eight']),
  goTo: T.func.isRequired,
  status: T.string,
}
