import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Button, AspectRatioImage } from '../../_shared/components'
import { breakLongWord } from '../../lib/string'

const bem = new BEMHelper('cover')

export default function Cover({
  title,
  preamble,
  children,
  image,
  tight,
  lqip,
  alt,
  action,
  skeleton,
  loading,
  uppercase,
}) {
  const show = (key, obj) => Boolean(skeleton.indexOf(key) > -1 && loading) || Boolean(obj)

  return (
    <header {...bem('', { tight, action })}>
      {show('image', image) && (
        <AspectRatioImage loading={loading} aspect="3/4" {...bem('image')} src={image} alt={alt || ''} lqip={lqip} />
      )}

      <h1
        {...bem('title', {
          uppercase,
          skeleton: loading && !title,
          xlarge: (title || '').length < 20 && !(title || '').split(' ').some(str => str.length > 10),
        })}
      >
        {breakLongWord(title)}
      </h1>

      {show('children', children) && <div {...bem('content')}>{children}</div>}

      {show('preamble', preamble) && <p {...bem('preamble')}>{preamble}</p>}

      {show('action', action) && <Button primary full {...action} {...bem('action')} />}
    </header>
  )
}

Cover.propTypes = {
  title: T.string,
  preamble: T.string,
  alt: T.string,
  image: T.string,
  lqip: T.string,
  meta: T.array,
  tight: T.bool,
  action: T.object,
  children: T.any,
  skeleton: T.arrayOf(T.string),
  loading: T.bool,
  uppercase: T.bool,
}

Cover.defaultProps = {
  skeleton: [],
}
