import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

export default function useGestures(ref, handlers, cancelEvent = true) {
  const [positions, setPositions] = useState({
    startX: null,
    startY: null,
    diffX: null,
    diffY: null,
    endX: null,
    endY: null,
  })

  const { touchStart, touchMove, touchEnd } = handlers
  const hasHandlers = handlers && (touchStart || touchMove || touchEnd)

  const HANDLE_TOUCH_START = event => {
    if (event.touches && hasHandlers) {
      const positions = {
        startX: event.touches[0].clientX,
        startY: event.touches[0].clientY,
      }

      setPositions(positions)

      touchStart &&
        touchStart({
          touches: event.touches[0],
          positions: { ...positions },
          event,
        })
    }
  }

  const HANDLE_TOUCH_MOVE = throttle(event => {
    if (event.touches && hasHandlers) {
      cancelEvent && event.preventDefault()

      setPositions(positions => ({
        ...positions,
        diffX: positions.startX - event.touches[0].clientX,
        diffY: positions.startY - event.touches[0].clientY,
      }))

      touchMove &&
        touchMove({
          touches: event.touches[0],
          positions: { ...positions },
          event,
        })
    }
  }, 60 / 5)

  const HANDLE_TOUCH_END = event => {
    cancelEvent && event.preventDefault()

    touchEnd &&
      touchEnd({
        positions: {
          ...positions,
          endX: event.changedTouches[0].pageX,
          endY: event.changedTouches[0].pageY,
        },
        event,
      })

    setPositions({
      startX: null,
      startY: null,
      diffX: null,
      diffY: null,
      endX: null,
      endY: null,
    })
  }

  if (hasHandlers) {
    // eslint-disable-next-line
    useEffect(() => {
      const element = ref.current

      element.addEventListener('touchstart', HANDLE_TOUCH_START)
      element.addEventListener('touchmove', HANDLE_TOUCH_MOVE)
      element.addEventListener('touchend', HANDLE_TOUCH_END)

      return () => {
        element.removeEventListener('touchstart', HANDLE_TOUCH_START)
        element.removeEventListener('touchmove', HANDLE_TOUCH_MOVE)
        element.removeEventListener('touchend', HANDLE_TOUCH_END)
      }
    })
  }
}
