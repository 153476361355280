import { useEffect } from 'react'

import postMessage from '../_shared/lib/post-message'
import { RouteProps } from '../types'

export const routeProps: RouteProps = { path: '/logout', exact: true }

export default function Login(): null{
  useEffect(() => {
    localStorage.clear()
    postMessage({ type: 'logout' })
    window.location.pathname = '/'
  }, [])

  return null
}
