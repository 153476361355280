import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Courses {
    inProgress: courses(inProgress: true) {
      edges {
        node {
          ...Course
        }
      }
    }

    courses {
      edges {
        node {
          ...Course
        }
      }
    }
  }

  fragment Course on Course {
    id
    slug
    title
    progress
    color
    image {
      url(width: 600, height: 732)
      lqip
      asset {
        metadata {
          palette {
            ...Palette
          }
        }
      }
    }
    lectures { count }
  }
  ${PaletteFragment}
`
