import gql from 'graphql-tag'

export default gql`
  mutation LinkFacebook($accessToken: String! $password: String!) {
    linkFacebook(accessToken: $accessToken password: $password) {
      token
      error {
        code
        message
      }
    }
  }
`
