import React, { ReactElement } from 'react'
import T from 'prop-types'
import * as Sentry from '@sentry/browser'

import ErrorPage from '../../pages/Error'

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: T.node,
  }

  state = { hasError: false }

  componentDidCatch(error: Error, errorInfo: Record<string, any>): void {
    this.setState({ hasError: true })

    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render(): T.ReactNodeLike {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage applyColors />
    }

    return this.props.children
  }
}
