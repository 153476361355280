import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Icon } from '../../_shared/components'

const bem = new BEMHelper('progress-circle')

export default function ProgressCircle({ value = 0, className }) {
  const size = 20 // Shouldn't be changed
  const length = 63 // Precalculated from the size
  const percentage = (value * length) / 100

  const path = `
    M10,1
    h4
      c2,0,5,2,5,5
      v8
      c0,2-2,5-5,5
    H6
      c-2,0-5-2-5-5
    V6
      c0-2,2-5,5-5
    H10
  `

  const done = value > 95

  return (
    <span
      {...bem('', { done }, className)}
      style={{
        '--precentage-max': length,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} {...bem('circle')}>
        <path {...bem('bar', 'background')} d={path} />
        <path {...bem('bar', 'progress')} d={path} strokeDasharray={`${percentage}, ${length}`} />
      </svg>
      {done && <Icon icon="check" {...bem('icon', 'check')} />}
    </span>
  )
}

ProgressCircle.propTypes = {
  value: T.number.isRequired,
  className: T.string,
  size: T.number,
}
