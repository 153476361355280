import gql from 'graphql-tag'

export const EMPTY_GROCERY_LIST = gql`
  mutation EmptyGroceryList {
    emptyGroceryList {
      success
      groceryList {
        id
        departments {
          id
        }
      }
    }
  }
`
