import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

import { Loader } from '../_shared/components'
import { Layout } from '../components'

export const routeProps = { path: '/min-side/takk' }

interface RefreshPendingPlansResponse {
  refreshPendingPlans: {
    updatedPlans: {
      id: string;
    }[];
  };
}

const maxRetries = 10

export default function PrivacyPage(): JSX.Element|null {
  const { push } = useHistory()
  const { search } = useLocation()
  const [retries, setRetries] = useState(0)
  const [gaveUp, setGaveUp] = useState(false)

  // Prepare me query for later execution
  const [refresh, { error, loading, data }] = useMutation<RefreshPendingPlansResponse>(gql`
    mutation {
      refreshPendingPlans {
        updatedPlans {
          id
        }
      }
    }
  `)

  useEffect(() => {
    let callCount = 0
    const interval = setInterval(() => {
      if (callCount > maxRetries) {
        clearInterval(interval)
        setGaveUp(true)
      }

      refresh()
      callCount++
    }, 2000)

    return (): void => {
      clearInterval(interval)
    }
  }, [refresh, retries])

  if (data?.refreshPendingPlans.updatedPlans.length) {
    window.location.href = '/'
    return null
  }

  return (
    <Layout header={{ name: 'Vent litt...' }}>
      <Loader/>
      {!gaveUp ? <p>
        Vent et øyeblikk mens vi henter bekreftelse på betalingen fra vipps...
      </p> : null}
      {gaveUp ? <p>
        Uff da. Det ser ikke ut som om betalingen gikk igjennom. Sjekk vipps-appen
      </p> : null}
    </Layout>
  )
}
