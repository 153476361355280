import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

import { Layout, SearchResults, Search } from '../components'
import { Loader } from '../_shared/components'
import { COLORS } from '../_shared/lib/color'
import setColors from '../lib/setColors'
import useSearch from '../hooks/search'
import { RouteProps } from '../types'

export const sharedRouteProps: RouteProps = { path: '/s' }
export const routeProps: RouteProps = { path: '/sok', exact: true }

export default function SearchPage({ applyColors }: InferProps<typeof SearchPage.propTypes>): JSX.Element {
  const location = useLocation()
  const [query, setQuery] = useState('')
  const { loading, error, hits } = useSearch(query)

  // set colors
  applyColors && setColors(COLORS.inverted, 'search')

  if (error) { throw error }

  const back = get(location, 'state.from', '/')

  const noResults = !loading && hits.length === 0 && query.length !== 0

  return (
    <Layout noAnimation spaceless>
      <Search onChange={setQuery} back={back} />
      {loading && <Loader />}
      <SearchResults items={hits} query={query} noResults={noResults} />
    </Layout>
  )
}

SearchPage.propTypes = {
  applyColors: T.bool.isRequired,
}
