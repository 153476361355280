import './index.scss'

import React, { Component } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Hls from 'hls.js'
import mux from 'mux-embed'

import { Loader, Icon } from '../../_shared/components'

const bem = new BEMHelper('video')

const isVideoPlaying = video =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )

export default class Video extends Component {
  static propTypes = {
    url: T.string,
    active: T.bool,
    hlsConfig: T.object,
    className: T.string,
    thumbnailUrl: T.string,
    moveProgress: T.number,
  }

  state = {
    count: 0,
    loading: false,
    done: false,
  }

  _hls = null
  _initialized = false
  _progressResetTimer = null
  _playTimer = null
  _retryTimer = null

  playVideo = () => {
    if (!this._video) {
      return
    }

    this.setState({ done: false })

    this._video.play().catch(() => {
      // Dirty hack. Try starting it once more, in a 100 ms
      this._retryTimer = setTimeout(() => {
        if (!this._video) { return }
        this._video.play().catch(() => {})
      }, 100)
    })
  }

  pauseVideo = () => {
    if (!this._video) {
      return
    }

    try {
      if (isVideoPlaying(this._video) && this._video) {
        this._video.currentTime = 0
        this._video.pause()
      }
    } catch (err) {
      console.error(err)
    }
  }

  componentDidMount() {
    this._video.addEventListener('ended', this.videoEnded, false)
    this._video.addEventListener('timeupdate', this.updateProgress, false)
    this.updateProgressLine(0)
  }

  componentWillUnmount() {
    this._video.removeEventListener('ended', this.videoEnded)
    this._video.removeEventListener('timeupdate', this.updateProgress)
    clearTimeout(this._progressResetTimer)
    clearTimeout(this._playTimer)
    clearTimeout(this._retryTimer)
  }

  initPlayer = () => {
    if (this._initialized) {
      return
    }

    const { url, hlsConfig = {}, active } = this.props

    this.setState({ loading: true })

    if (this._video.canPlayType('application/vnd.apple.mpegurl')) {
      this._video.src = url

      if (this._hls) {
        this._hls.destroy()
        this._hls = null
      }

      // active && this.playVideo()
      this._video.addEventListener('loadedmetadata', async() => {
        // active && this.playVideo()
        this.setState({ loading: false })
      })
    } else if (Hls.isSupported()) {
      if (this._hls) {
        this._hls.destroy()
      }

      const hls = new Hls(hlsConfig)

      hls.loadSource(url)
      hls.attachMedia(this._video)

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // active && this.playVideo()
        this.setState({ loading: false })
      })

      this._hls = hls
    }

    mux.monitor(this._video, {
      debug: false,

      ...(this._hls ? {
        // Pass in the HLS.js instance
        hlsjs: this._hls,

        // Optionally, if Hls is not available on the window globally
        // you need to pass Hls as well.
        Hls,
      } : {}),

      data: {
        env_key: process.env.NODE_ENV === 'development' ? 'ckkbh9v2554anqbg01293m6fk' : 'qnv29pi0n3fdbd62nd6nkuh37',

        // Metadata
        player_name: 'Video component',
        player_init_time: Date.now(),
      },
    })

    this._initialized = true
  }

  componentDidUpdate(prevProps) {
    if (!this._video) {
      return
    }

    if (this.props.active) {
      this.initPlayer()
    }

    if (this.props.active !== prevProps.active) {
      clearTimeout(this._playTimer)
      if (this.props.active) {
        this._playTimer = setTimeout(this.playVideo, 150)
      } else {
        this.pauseVideo()
      }
    }
  }

  videoEnded = () => {
    if (!this._video || !this.props.active) {
      return
    }

    this.setState({ count: this.state.count + 1, done: true })

    this._progress.style.transition = 'none'
    this.updateProgressLine(0)

    this._progressResetTimer = setTimeout(() => {
      this._progress.style.transition = null
    }, 200)
  }

  updateProgress = () => {
    if (!this._video) {
      return
    }

    const percentage = Math.floor(
      (100 / this._video.duration) * this._video.currentTime,
    )

    this.updateProgressLine(percentage)

    if (this._video.controls && percentage > 0) {
      this._video.controls = false
    }
  }

  updateProgressLine = percentage => {
    if (this._progress) {
      this._progress.style.transform = `scaleX(${Math.max(
        0.005,
        percentage / 100,
      )})`
    }
  }

  handleClick = () => {
    if (!this._video) {
      return
    }

    // TODO: Make pause state with pause icon and some controls: Restrart, play etc..
    if (isVideoPlaying(this._video)) {
      this._video.pause()
    } else {
      this.playVideo()
    }
  }

  renderVideoProgress = () => {
    const { moveProgress } = this.props
    const { count, done } = this.state

    const styles = moveProgress
      ? { transform: `translateY(-${moveProgress}px)`, willChange: 'transform' }
      : null

    return (
      <span {...bem('progress', { done })} key={`progress-${count}`} style={styles}>
        <span
          {...bem('progress-line')}
          ref={ref => {
            this._progress = ref
          }}
        />
      </span>
    )
  }

  render() {
    const { loading, done } = this.state
    const { thumbnailUrl, className, url } = this.props

    return (
      <div {...bem('', '', className)}>
        {done && (
          <button type="button" onClick={this.playVideo} {...bem('replay')}>
            <span {...bem('replay-icon')}><Icon icon="replay" /></span>
          </button>
        )}

        <video
          {...bem('video')}
          onClick={this.handleClick}
          ref={ref => { this._video = ref }}
          muted
          preload="meta"
          playsInline
          webkit-playsinline="true"
          src={url}
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
          poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />

        <svg viewBox="0 0 1 1" {...bem('aspect-ratio')} />

        {this.renderVideoProgress()}

        {loading && <Loader type={['absolute', 'transparent']} />}
      </div>
    )
  }
}
