import './index.scss'

import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Icon } from '../../_shared/components'

const bem = new BEMHelper('portions')

export default function Portions({
  value,
  onChange,
  options,
  className,
  center,
  type,
}: InferProps<typeof Portions.propTypes>): JSX.Element {
  const [expanded, setExpanded] = useState(false)

  const handleClick = (number: number) => (): void => {
    if (expanded) {
      onChange && onChange(number)
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  const toggleExpand = (): void => {
    setExpanded((expanded) => !expanded)
  }

  return (
    <div
      {...bem(
        '',
        {
          expanded,
          collapsed: !expanded,
          center: Boolean(center),
          text: type === 'text',
        },
        className || undefined,
      )}
      style={{
        '--options': options.length,
      }}
    >
      <div {...bem('wrapper')}>
        <button
          type="button"
          {...bem('label')}
          onClick={toggleExpand}
          aria-label="Velg antall porsjoner"
          aria-expanded={expanded}
        >
          <Icon icon="people" {...bem('icon')} />
        </button>

        <span {...bem('options')} role="listbox" aria-expanded={expanded}>
          {options.map(([key, text], index) => (
            <button
              {...bem('option', { selected: value === key })}
              type="button"
              key={key}
              onClick={handleClick(key)}
              aria-label={`${text} porsjoner`}
              aria-selected={value === key}
              role="option"
              style={{ '--index': index }}
            >
              <span {...bem('number')}>{text}</span>
            </button>
          ))}
        </span>
      </div>
    </div>
  )
}

Portions.propTypes = {
  value: T.string,
  onChange: T.func,
  options: T.array.isRequired,
  className: T.string,
  alwaysExpanded: T.bool,
  center: T.bool,
  type: T.string,
}

Portions.defaultProps = {
  center: false,
  onChange: (number: number): void => {
    console.log(number)
  },
}
