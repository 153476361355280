// import './styles/styles.scss'
import './_shared/styles/styles.scss'

import { BrowserRouter } from 'react-router-dom'
import React, { ReactElement, useEffect } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import jwtDecode from 'jwt-decode'

import ErrorBoundary from './components/ErrorBoundary'
import * as serviceWorker from './serviceWorker'
import ApolloWrapper from './services/apollo'
import Pages from './pages'
import NativeStateProvider from 'components/NativeStateProvider'

const supportsHistory = 'pushState' in window.history

function App(): ReactElement {
  useEffect(() => {
    const token = window.localStorage.token

    if (!token) {
      return
    }

    const tokenData: { id: string } = jwtDecode(token)

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'userIdset',
      userId: tokenData?.id,
    })
  }, [])

  return (
    <BrowserRouter forceRefresh={!supportsHistory}>
      <ErrorBoundary>
        <NativeStateProvider>
        <ApolloWrapper>
          <Pages />
        </ApolloWrapper>
        </NativeStateProvider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

// Instantiate Sentry for error logging
if (window.location.href.match(/app\.foodsteps\.no/)) {
  Sentry.init({
    dsn: 'https://4ab002d3cca64c0c9bc0fd584e2f1b1e@sentry.io/1915537',
    ignoreErrors: [/AbortError/, /e\.originalEvent\.origin/],
  })
}

// Render dat app 🎉
ReactDOM.render(<App />, document.getElementById('root'))

// Unregister service worker
serviceWorker.unregister()
