import './index.scss'

import React, { useState, useEffect, useRef } from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'

import Icon from '../Icon'

const bem = new BEMHelper('select')

const updateWidth = (wrapper: any, target: any): number => {
  if (!wrapper || !target || !target.value) {
    return 0
  }

  const shadow = document.createElement('select')
  const value =
    get(target, 'selectedOptions[0].innerHTML') || get(target, 'value') || ''

  const styles = window.getComputedStyle(target)

  if (!styles || !shadow || !value) {
    return 0
  }

  shadow.style.position = 'absolute'
  shadow.style.left = '-1000px'
  shadow.style.width = 'auto'
  shadow.style.maxWidth = 'none'
  shadow.style.fontSize = styles.getPropertyValue('font-size')
  shadow.style.fontFamily = styles.getPropertyValue('font-family')
  shadow.style.fontWeight = styles.getPropertyValue('font-weight')
  shadow.style.letterSpacing = styles.getPropertyValue('letter-spacing')
  shadow.style.paddingRight = styles.getPropertyValue('padding-right')
  shadow.style.paddingLeft = styles.getPropertyValue('padding-left')
  shadow.style.borderRight = styles.getPropertyValue('border-right')
  shadow.style.borderLeft = styles.getPropertyValue('border-left')

  const option = document.createElement('option')
  option.value = value
  option.innerHTML = value

  shadow.appendChild(option)
  wrapper.appendChild(shadow)

  const width = get(shadow, 'offsetWidth', null)

  wrapper.removeChild(shadow)
  // shadow.remove()

  return Number(width)
}

export default function Select({
  options,
  onChange,
  value,
  label,
  className = undefined,
  ...props
}: InferProps<typeof Select.propTypes>): JSX.Element {
  const wrapperRef = useRef<HTMLSpanElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)
  const [width, setWidth] = useState<number>(120)

  useEffect(() => {
    const width = updateWidth(wrapperRef.current, selectRef.current)
    setWidth(width)

    setTimeout(() => {
      const width = updateWidth(wrapperRef.current, selectRef.current)
      setWidth(width)
    }, 64)
  }, [value, options, wrapperRef, selectRef])

  const handleChange = (event: any): void => onChange(event.target.value)

  const style = { width: `${width + 6}px` }

  const renderOptions = (options: InferProps<typeof Select.propTypes>['options']) => options.map(option => {
    if (option.label) {
      return (
        <optgroup key={option.label} label={`${option.label}`}>
          {renderOptions(option.options as unknown as typeof options)}
        </optgroup>
      )
    }

    const optValue = get(option, 'value', '') || ''
    const optName = get(option, 'name', '') || ''

    return (
      <option value={optValue} key={optValue} selected={optValue === value}>
        {optName}
      </option>
    )
  })

  return (
    <label {...bem('', '', className || undefined)}>
      {label && <span {...bem('label')}>{label}</span>}
      <span {...bem('wrapper')} ref={wrapperRef}>
        <select
          {...props}
          {...bem('dropdown')}
          onChange={handleChange}
          style={style}
          ref={selectRef}
        >
          {renderOptions(options)}
        </select>

        <Icon
          icon="chevron"
          size="small"
          direction="down"
          {...bem('indicator')}
        />
      </span>
    </label>
  )
}

Select.propTypes = {
  options: T.arrayOf(
    T.oneOfType([
      T.shape({
        label: T.oneOfType([T.string, T.number]),
        options: T.arrayOf(
          T.shape({
            value: T.oneOfType([T.string, T.number]),
            name: T.oneOfType([T.string, T.number]).isRequired,
          }),
        ).isRequired
      }),
      T.shape({
        label: T.oneOf([undefined]),
        value: T.oneOfType([T.string, T.number]),
        name: T.oneOfType([T.string, T.number]).isRequired,
      }),
    ]).isRequired
  ).isRequired,
  onChange: T.func.isRequired,
  value: T.string.isRequired,
  label: T.string,
  className: T.string,
}
