import { NativeStateContext } from "components/NativeStateProvider";
import { useContext } from "react";

export default function useNativeState() {
  const nativeState = useContext(NativeStateContext)

  function getStateValue(property: keyof typeof nativeState) {
    return nativeState[property] ?? false
  }

  return {
    state: nativeState,
    getStateValue
  }
}
