/* eslint-disable max-len */
import React from 'react'
import T, { InferProps } from 'prop-types'
import { Link } from 'react-router-dom'

import { Article } from '../_shared/components'
import { RouteProps } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { Layout } from '../components'

export const routeProps: RouteProps = { path: '/min-side/vilkar', exact: true }

export default function Terms({ applyColors }: InferProps<typeof Terms.propTypes>): JSX.Element {
  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'terms')
  }

  return (
    <Layout
      title="Vilkår for kjøp og bruk"
      header={{
        back: { name: 'Min side', to: '/min-side' },
      }}
    >
      <Article
        preamble="Disse vilkårene gjelder fra 1. april 2020. Foodsteps AS kan fritt endre vilkårene for bruk, kjøp og personvern. Oppdatert versjon er alltid tilgjengelig på denne siden."
      >
        <Article.SubTitle>Bruksvilkår</Article.SubTitle>
        <Article.Text>
          Foodsteps er en digital tjeneste som har som mål å gjøre det lettere å lage god mat. Den er levert av Foodsteps AS, org. nr 920 889 956.
        </Article.Text>
        <Article.Text>
          For å kunne bruke Foodsteps må du ha tegnet et abonnement eller fått tilgang via gavekort/rabattkode. Abonnementer, gavekort og rabattkoder på Foodsteps er personlig og kan ikke brukes av eller overføres til andre.
        </Article.Text>
        <Article.Text>
          Når du bruker våre tjenester vil Foodsteps AS behandle personopplysningere om deg. Les <Link to="/min-side/personvern">personvernerklæringen</Link> vår for å se hvilke opplysninger vi lagrer og hvordan vi behandler dem.
        </Article.Text>
        <Article.Text>
          Foodsteps skal utelukkende brukes i private sammenhenger. Innholdet på nettsiden og i appen eies av Foodsteps AS. Det er ikke lov å kopiere innhold og oppskrifter fra Foodsteps uten vår tillatelse.
        </Article.Text>
        <Article.Text>
          Når det er sagt samarbeider vi gjerne med spennende aktører, så ta kontakt på <a href="mailto:post@foodsteps.no" target="_blank" rel="noopener noreferrer">post@foodsteps.no</a> om du har noen gode ideer.
        </Article.Text>

        <Article.SubTitle>Kjøpsvilkår</Article.SubTitle>
        <Article.Text>
          Du må være over 18 år for å kjøpe tilgang til Foodsteps.
        </Article.Text>
        <Article.Text>
          Du kan bruke innholdet med en gang du har lastet ned og logget
          inn. Normalt gjelder for forbrukerkjøp en 14 dagers angrerett.
          Foodsteps AS tilbyr en 30 dagers gratisperiode ved
          førstegangsregistrering. I gratisperioden står du fritt til å si
          opp uten å bli trukket noe beløp.
        </Article.Text>
        <Article.Text>
          Foodsteps er en tjeneste under utvikling. Foodsteps AS kan fritt justere innholdet, funksjonalitet eller prisen på tjenesten. Ved prisjusteringer får du beskjed om dette 30 dager før endringen trer i kraft.
        </Article.Text>
        <Article.Text>
          Gavekort på Foodsteps kan kun benyttes til bruk av Foodsteps, og kan ikke omsettes i kontanter eller andre varer.
        </Article.Text>

        <Article.SubTitle>Betaling, varighet og oppsigelse</Article.SubTitle>
        <Article.Text>
          Abonnementet er løpende og du belastes månedlig forskuddsvis fra
          utløpet av den gratis prøveperioden. Første betaling trekkes da 30
          dager etter at du har tegnet abonnementet. Om du avslutter
          abonnement, vil du ha tilgang til tjenesten i den perioden du har
          betalt for. Du får ikke refusjon selv om avtalen sies opp før
          periodens utløp. Dersom det skulle være en mangel ved tjenesten
          vår kan du reklamere ved å sende en e-post til oss innen rimelig
          tid fra mangelen ble oppdaget eller burde vært oppdaget. Dersom en
          tvist ikke blir løst i minnelighet kan du ta saken til
          Forbrukerrådet.
        </Article.Text>
        <Article.Text>
          Abonnementet sies opp under “Min side” i appen.
        </Article.Text>
      </Article>
    </Layout>
  )
}

Terms.propTypes = {
  applyColors: T.bool.isRequired,
}
