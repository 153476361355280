/* eslint complexity:0 */

import React from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery, QueryResult } from '@apollo/client'
import { format } from 'date-fns'
import { filter } from 'lodash'

import { Loader } from '../_shared/components'
import MY_PAGE_QUERY from '../queries/pages/MyPage'
import { Layout, Info, ChargeRecipe } from '../components'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { User, RouteProps } from '../types'

export const routeProps: RouteProps = {
  path: '/min-side/kvitteringer',
  exact: true,
}

interface AlterRenewalResponse {
  success: boolean
  plan?: {
    id: string
    paymentAgreementStatus: string
  }
}

interface DisconnectSocialResponse {
  success: boolean
  error?: { code: number; message: string }
}

export default function ChargesPage({
  applyColors,
}: InferProps<typeof ChargesPage.propTypes>): JSX.Element {
  const {
    loading,
    error,
    data: { me: { node: me = undefined } = {} } = { me: {} },
  }: QueryResult<{ me?: { node?: User } }> = useQuery(MY_PAGE_QUERY, {
    fetchPolicy: 'network-only',
  })

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'mypage')
  }

  if (loading) {
    return (
      <Layout title="Vipps-kvitteringer">
        <Loader />
      </Layout>
    )
  }

  if (error) {
    throw error
  }
  if (!me) {
    throw new Error(`'me' is ${me}`)
  }

  const charges = filter(me.charges || [], { status: 'charged' })

  return (
    <Layout
      title="Vipps-kvitteringer"
      header={{
        back: { name: 'Dine data', to: '/min-side' },
      }}
    >
      {charges.length < 1 && (
        <Info type="center">Du har ingen registrerte betalinger</Info>
      )}

      {charges.length > 0 && (
        <ChargeRecipe
          recipes={charges.map((charge) => ({
            id: charge.id,
            due: format(new Date(charge.due), 'dd.MM.yyyy'),
            title: charge?.plan?.product?.title,
            price: `${charge?.amount / 100} kr`,
            vat: `${Number((charge?.amount / 100) * 0.2).toFixed(2)} kr`,
            foreignId: charge?.vippsChargeId,
          }))}
        />
      )}
    </Layout>
  )
}

ChargesPage.propTypes = {
  applyColors: T.bool.isRequired,
}
