/* eslint-disable max-len */
import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import { useMutation } from '@apollo/client'

import { Article, Button } from '../_shared/components'
import { RouteProps } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { Layout, Confirm } from '../components'
import { RESET_PROGRESS } from '../queries/mutations/reset-progress'
import { useHistory } from 'react-router-dom'
import useIsSignedIn from 'hooks/signed-in'

export const routeProps: RouteProps = { path: '/min-side/data', exact: true }

export default function Data({ applyColors }: InferProps<typeof Data.propTypes>): JSX.Element|null {
  const history = useHistory()
  const { loading: signedInLoading, signedIn } = useIsSignedIn()
  const [confirm, setConfirm] = useState(false)
  const [resetLecturesProgress] = useMutation(RESET_PROGRESS)

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'data')
  }

  const showConfirm = (): void => {
    setConfirm(true)
  }
  const hideConfirm = (): void => {
    setConfirm(false)
  }

  async function handleResetProgress(): Promise<void> {
    await resetLecturesProgress()
    hideConfirm()
  }

  if (!signedInLoading && !signedIn) {
    history.push('/')
    return null
  }

  return (
    <Layout
      title="Dine data"
      header={{
        back: { name: 'Min side', to: '/min-side' },
      }}
    >
      <Article>
        <Article.SubTitle uppercase>Favoritter</Article.SubTitle>
        <Article.Text>
          Vi har lagret favorittene du har spart på i appen. Disse kan du selv fjerne ved å gå til Favoritter. Når du tar bort favoritter er de helt borte.
        </Article.Text>
        <Button primary full small to="/favoritter" spaceless>Se mine favoritter</Button>

        <Article.SubTitle uppercase>Handleliste</Article.SubTitle>
        <Article.Text>
          Matvarer du har lagt til fra oppskrifter og ukesmeny blir lagret i handlelisten. Denne kan du tømme ved å gå til handlelisten. Vi lagrer ikke historiske data.
        </Article.Text>
        <Button primary full small to="/handleliste" spaceless>Gå til handlelisten</Button>

        <Article.SubTitle uppercase>Din fremdrift</Article.SubTitle>
        <Article.Text>
          Vi har lagret data om hvilke kursvideoer du har sett, og hvor mye av videoene du har sett. Vi bruker det for at du skal kunne fortsette der du slapp, og til å vise deg relevant innhold.
        </Article.Text>
        <Button primary full small onClick={showConfirm} spaceless>Slett data om fremdrift</Button>
      </Article>

      <Confirm
        title="Er du sikker? Du kan ikke angre dette."
        onSubmit={hideConfirm}
        actions={[
          { label: 'Slett data', primary: true, onClick: handleResetProgress },
          { label: 'Avbryt - ikke slett data', dark: true, onClick: hideConfirm },
        ]}
        onClose={hideConfirm}
        show={confirm}
      />

      <Article.SubTitle uppercase>Dine samtykker</Article.SubTitle>
      <Article.Text>
          Vi har lagret informasjon om samtykker du har gitt til markedsføring og annen kommunikasjon. Uavhengig av samtykker får du all nødvendig info om abonnementet ditt på epost.
      </Article.Text>
      <Button primary full small to="/min-side/samtykker" spaceless>Se mine samtykker</Button>
    </Layout>
  )
}

Data.propTypes = {
  applyColors: T.bool.isRequired,
}
