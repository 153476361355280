import React from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import RECIPES_QUERY from '../queries/pages/Recipes'
import { Layout, PageSections, Categories, RecipeList, WeekMenuEntry } from '../components'
import { Loader } from '../_shared/components'
import setColors from '../lib/setColors'
import getColor from '../_shared/lib/color'
import { CategoryType, RouteProps } from '../types'
import useIsSignedIn from 'hooks/signed-in'
import { useHistory } from 'react-router-dom'

export const routeProps: RouteProps = { path: '/oppskrifter/', exact: true }

export default function Recipes({ applyColors }: InferProps<typeof Recipes.propTypes>): JSX.Element|null {
  const history = useHistory()
  const {
    loading,
    error,
    data: {
      categories = { edges: [] },
      page: { sections = [], promoted } = { sections: [], promoted: { node: {} } },
    } = {},
  } = useQuery<{
    categories: {
      edges: {
        node: CategoryType
      }[]
    },
    page: {
      sections: any[], promoted: any[]
    }
  }>(RECIPES_QUERY)



  // Set colors
  const type = get(promoted, '__typename', null)
  const palette =
    type === 'WeekMenusEdge'
      ? get(promoted, 'node.recipes.edges[0].node.image.asset.metadata.palette', null)
      : get(promoted, 'node.image.asset.metadata.palette', null)
  const color =
    type === 'WeekMenusEdge'
      ? get(promoted, 'node.recipes.edges[0].node.color', null)
      : get(promoted, 'node.color', null)

  if (applyColors && (palette || color)) {
    const colors = getColor(palette, color)
    setColors(colors, 'recipes')
  }

  if (error) { throw error }

  return (
    <Layout title="Oppskrifter">
      {loading ? (
        <Loader />
      ) : (
        <>
          {type === 'WeekMenusEdge' ? (
            <WeekMenuEntry
              year={get(promoted, 'node.year')}
              week={get(promoted, 'node.week')}
              recipes={get(promoted, 'node.recipes', [])}
              slug={get(promoted, 'node.slug', null)}
              promoted
            />
          ) : null}
          {type === 'RecipesEdge' ? <RecipeList recipes={[promoted]} type="promoted" /> : null}
          <PageSections sections={sections} />
          {categories.edges.map(({ node }) => (
            <Categories key={get(node, 'id')} {...node} />
          ))}
        </>
      )}
    </Layout>
  )
}

Recipes.propTypes = {
  applyColors: T.bool.isRequired,
}
