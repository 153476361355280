/* eslint-disable max-len */
import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('logo')

export default function Logo({
  className,
}: InferProps<typeof Logo.propTypes>): JSX.Element {
  return (
    <svg
      width="248"
      height="35"
      viewBox="0 0 248 35"
      {...bem('', '', className || '')}
    >
      <title>Foodsteps</title>
      <path d="M25.4558 17.5019C25.4558 6.71547 29.6745 0.00388572 38.831 0.00388572C47.9396 0.00388572 52.1583 6.71547 52.1583 17.5019C52.1583 28.2884 47.9396 35 38.831 35C29.6745 35 25.4558 28.2884 25.4558 17.5019ZM48.0402 17.5019C48.0402 12.66 47.1773 3.64732 38.8358 3.64732C30.4943 3.64732 29.5834 12.66 29.5834 17.5019C29.5834 22.3439 30.4943 31.3566 38.8358 31.3566C47.1773 31.3566 48.0402 22.3439 48.0402 17.5019Z" />
      <path d="M56.9141 17.5019C56.9141 6.71547 61.1328 0.00388572 70.2893 0.00388572C79.3979 0.00388572 83.6166 6.71547 83.6166 17.5019C83.6166 28.2884 79.3979 35 70.2893 35C61.1328 35 56.9141 28.2884 56.9141 17.5019ZM79.4986 17.5019C79.4986 12.66 78.6357 3.64732 70.2941 3.64732C61.9526 3.64732 61.0417 12.66 61.0417 17.5019C61.0417 22.3439 61.9526 31.3566 70.2941 31.3566C78.6357 31.3566 79.4986 22.3439 79.4986 17.5019Z" />
      <path d="M89.1969 0.718858H98.2096C107.75 0.718858 113.886 7.47838 113.886 17.5937C113.886 27.6131 107.75 34.2768 98.2096 34.2768H89.1969V0.718858ZM98.4061 30.4464C105.357 30.4464 109.768 25.3168 109.768 17.5985C109.768 9.78429 105.357 4.55884 98.4061 4.55884H93.3245V30.4464H98.4061Z" />
      <path d="M139.328 0.718858V4.55405H122.415C122.319 13.6147 140.296 14.142 140.344 25.0244C140.296 31.9277 135.454 34.8521 129.126 34.8521C122.271 34.8521 117.525 29.7225 117.764 23.5862L121.983 22.7712C121.408 27.2296 124.524 31.0169 129.174 31.0169C133.681 31.0169 136.173 28.9554 136.269 25.2641C136.317 16.3952 118.196 17.1622 118.004 3.40349V0.718858H139.328Z" />
      <path d="M153.647 4.55405H143.244V0.718858H168.221V4.55405H157.77V34.2768H153.647V4.55405Z" />
      <path d="M170.397 24.5981C170.397 20.5232 172.602 17.503 176.437 16.5442C172.171 15.2498 171.164 11.9899 171.164 9.01764C171.164 3.98395 175.047 0.724039 180.944 0.724039H192.737V4.55923H181.183C177.636 4.55923 175.287 6.28506 175.287 8.9697C175.287 12.1337 176.917 14.5307 184.395 14.5787H192.21V18.318H184.395C177.013 18.318 174.52 20.9067 174.52 24.5981C174.52 28.3853 177.636 30.4468 181.998 30.4468H192.737V34.282H181.998C175.862 34.282 170.397 31.1659 170.397 24.5981Z" />
      <path d="M198.73 12.2247C198.73 4.84193 203.284 0 210.235 0C216.947 0 221.357 4.45841 221.357 11.2659C221.357 18.1213 216.18 22.6276 208.318 22.6276H202.805V34.277H198.682L198.73 12.2247ZM208.318 18.7972C213.687 18.7972 217.187 15.825 217.187 11.2707C217.187 6.76432 214.454 3.83998 210.235 3.83998C205.729 3.83998 202.805 7.14784 202.805 12.2295V18.7972H208.318Z" />
      <path d="M246.564 0.718858V4.55405H229.651C229.555 13.6147 247.533 14.142 247.581 25.0244C247.533 31.9277 242.691 34.8521 236.363 34.8521C229.507 34.8521 224.761 29.7225 225.001 23.5862L229.22 22.7712C228.644 27.2296 231.76 31.0169 236.411 31.0169C240.917 31.0169 243.41 28.9554 243.506 25.2641C243.554 16.3952 225.432 17.1622 225.241 3.40349V0.718858H246.564Z" />
      <path d="M0 0.718858H21.525V4.55405H4.12283V15.2926H20.9977V19.1278H4.12283V34.2768H0V0.718858Z" />
    </svg>
  )
}

Logo.propTypes = {
  className: T.string,
}
