import './index.scss'

import React, { useState, useEffect, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link } from 'react-router-dom'

import { Icon } from '../../_shared/components'

const bem = new BEMHelper('search')

export default function Search({ value = '', onChange = f => f, back }) {
  const input = useRef()

  const [searchTerm, setSearchTerm] = useState(decodeURIComponent(window.location.hash.replace(/^#/, '')))

  useEffect(() => {
    window.history.replaceState(null, null, `#${searchTerm}`)
    onChange(searchTerm)

    if (input && input.current) {
      input.current.style.width = '0px'
      input.current.style.width = `${input.current.scrollWidth}px`
    }
  }, [onChange, searchTerm])

  useEffect(() => {
    const _timeout = setTimeout(() => {
      if (input && input.current) {
        input.current.focus()
        input.current.style.width = '0px'
        input.current.style.width = `${input.current.scrollWidth}px`
      }
    }, 100)

    return () => {
      clearTimeout(_timeout)
    }
  }, [])

  const handleChange = ({ target: { value } }) => setSearchTerm(value)

  return (
    <form {...bem('')} onSubmit={event => event.preventDefault()}>
      <Link to={back} {...bem('close')}>
        <Icon icon="close" title="Tilbake" {...bem('close-icon')} />
      </Link>

      <label {...bem('wrapper')} aria-label="Søk">
        <input
          ref={input}
          type="search"
          {...bem('input')}
          value={searchTerm}
          placeholder="Søk"
          onChange={handleChange}
          autoFocus
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </label>
    </form>
  )
}

Search.propTypes = {
  value: T.string,
  onChange: T.func.isRequired,
  back: T.string.isRequired,
}
