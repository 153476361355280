import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('charge-recipe')

export default function ChargeRecipe({
  recipes,
}: InferProps<typeof ChargeRecipe.propTypes>): JSX.Element {
  return (
    <ul {...bem('')}>
      {recipes.map((item: any) => (
        <li key={item?.id} {...bem('item')}>
          <h2 {...bem('title')}>{item?.title}</h2>
          <time {...bem('date')}>{item?.due}</time>
          <strong {...bem('price')}>{item?.price}</strong>

          <div {...bem('details')}>
            <span {...bem('detail')}>MVA: {item?.vat}</span>
            <span {...bem('detail')}>ID: {item?.foreignId}</span>
          </div>
        </li>
      ))}
    </ul>
  )
}

ChargeRecipe.propTypes = {
  recipes: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      due: T.string,
      title: T.string,
      price: T.string,
      vat: T.string,
      foreignId: T.string,
    }),
  ).isRequired,
}
