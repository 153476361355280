/* Custom tracking events for Google Tag Manager */
export default function trackCustomEvent(
  category: string | null = null,
  action: string | number | null = null,
  label: string | number | null = null,
  value: string | number | null = null,
): void {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'custom-event',
    'custom-event.category': category,
    'custom-event.action': action,
    'custom-event.label': label,
    'custom-event.value': value,
  })
}
