/* eslint-disable @typescript-eslint/camelcase */

import React, { Component } from 'react'
import { get } from 'lodash'
import T from 'prop-types'
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider, HttpLink, ApolloLink } from '@apollo/client'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import sha256 from 'crypto-js/sha256';
import gql from 'graphql-tag'

import { Loader } from '../../_shared/components'
import postMessage from '../../_shared/lib/post-message'
import possibleTypes from './possibleTypes'

declare global {
  interface Window {
    dataLayer: any;
  }
}

const cache = new InMemoryCache({ possibleTypes })

let jwtToken: string | null = null

const trackUserTypeForGTM = (userType: string): void => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'dimensions.user-type': userType,
  })
}

try {
  const [, urlToken] = window.location.search.match(/[?&]token=([^&]+)/) || []

  if (urlToken) {
    localStorage.setItem('token', urlToken)
    history.replaceState({}, window.document.title, '?')
    window.location.reload()
  }

  jwtToken = localStorage.getItem('token')

  // Track user type
  trackUserTypeForGTM(jwtToken ? 'Signed in' : 'Anonymous')
} catch (e) {
  console.warn(e.message)
}

const persistedQueriesLink = createPersistedQueryLink({
  sha256: (word: string) => `${sha256(word)}`
});

export const client = new ApolloClient({
  link: persistedQueriesLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors = [], networkError, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations }) => {
            // We have no interest in the persisted query error. It's not really an error
            if (message.match(/PersistedQueryNotFound/)) {
              return
            }

            console.group(`[gql error] ${message}`)
            locations && console.log(`Location: line ${locations[0].line}, column ${locations[0].column}`)

            const queryBody = get(operation, 'query.loc.source.body')
            if (queryBody) {
              console.log(`Query: ${queryBody}`)
            }

            console.groupEnd()
          })
        }

        // If we get an authentication error, log the user out
        const statusCode = get(networkError, 'statusCode')
        if (networkError && statusCode === 401) {
          localStorage.clear()
          postMessage({ type: 'logout' })
          window.location.pathname = '/'
          return
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL || 'https://graphql.foodsteps.no/graphql',
        headers: {
          ...(jwtToken ? { authorization: `Bearer ${jwtToken}` } : {}),
          'client-name': 'Foodsteps [app]',
          'client-version': '1.0.0',
        },
      }),
    ]),
  ),
  typeDefs: gql`
    extend type Query {
      isSignedIn: Bool!
    }
  `,
  cache,
  resolvers: {
    Query: {
      isSignedIn: (): boolean => !!jwtToken,
    },
  },
  name: `app [${window.location.host}]`,
})

export const FlagsContext = React.createContext({})
export const OfferingsContext = React.createContext<{monthly?: {}; identifier?: string}>({})

export default class ApolloWrapper extends Component {
  state: {
    flags: {
      [flagName: string]: boolean;
    };
    loaded: boolean;
    offerings: any;
  } = {
    flags: {},
    /**
     * Set this to false if loading flags from backend in componentDidMount in order to
     * delay rendering until we know what's enabled
     */
    loaded: true,
    offerings: {},

    // offerings: {
    //   monthly: {
    //     offeringIdentifier: 'standard',
    //     product: {
    //       discounts: [],
    //       intro_price_string: 'NOK 0,00',
    //       intro_price_period_number_of_units: 1,
    //       identifier: 'full',
    //       intro_price_cycles: 1,
    //       price_string: 'NOK 39,00',
    //       intro_price_period: 'P1M',
    //       title: 'Full tilgang',
    //       price: 39,
    //       currency_code: 'NOK',
    //       intro_price_period_unit: 'MONTH',
    //       intro_price: 0,
    //       introPrice: {
    //         periodUnit: 'MONTH',
    //         price: 0,
    //         period: 'P1M',
    //         priceString: 'NOK 0,00',
    //         periodNumberOfUnits: 1,
    //         cycles: 1,
    //       },
    //       description: '',
    //     },
    //     packageType: 'MONTHLY',
    //     identifier: '$rc_monthly',
    //   },
    //   identifier: 'standard',
    // },
  }

  static propTypes = {
    children: T.any.isRequired,
  }

  handlePostMessage = (event: { data: { type: string; payload: any } }): void => {
    if (typeof event.data !== 'object') { return }

    if (event.data.type === 'offerings') {
      this.setState({ offerings: event.data.payload })
    }
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener('message', this.handlePostMessage, false)

    // // Get feature flags from backend
    // const {
    //   data: { flags },
    // } = await client.query({
    //   query: gql`
    //     query Flags {
    //       flags {
    //         id
    //         enabled
    //       }
    //     }
    //   `,
    // })
    //
    // this.setState({ flags, loaded: true })
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.handlePostMessage)
  }

  render(): JSX.Element {
    const { children } = this.props
    const { flags, loaded, offerings } = this.state

    if (!loaded) {
      return <Loader />
    }

    return (
      <ApolloProvider client={client}>
        <FlagsContext.Provider value={flags}>
          <OfferingsContext.Provider value={offerings}>
            {children}
          </OfferingsContext.Provider>
        </FlagsContext.Provider>
      </ApolloProvider>
    )
  }
}
