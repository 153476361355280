import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useParams } from 'react-router-dom'

// import { minutesToSentence } from '../../lib/utils'
// import AspectRatioImage from '../AspectRatioImage'
// import Icon from '../Icon'
import ProgressCircle from '../ProgressCircle'

const bem = new BEMHelper('lecture-list')

function renderItem({ title, progress, slug }, index) {
  return (
    <>
      <span {...bem('count')}>{index + 1}</span> {title}
      <ProgressCircle value={progress} {...bem('progress')} />
    </>
  )
}

renderItem.propTypes = {
  title: T.string.isRequired,
  progress: T.number.isRequired,
  slug: T.string.isRequired,
}

export default function LectureList({ lectures, disabled }) {
  const { slug: courseSlug } = useParams()

  return (
    <nav {...bem('')}>
      {lectures.edges.map(({ node: { id, title, progress, slug }, node: item }, index) =>
        disabled ? (
          <div key={id} {...bem('item', { 'not-started': progress < 3, disabled })}>
            {renderItem(item, index)}
          </div>
        ) : (
          <Link key={id} to={`/kurs/${courseSlug}/leksjon/${id}`} {...bem('item', { 'not-started': progress < 3 })}>
            {renderItem(item, index)}
          </Link>
        ),
      )}
    </nav>
  )
}

LectureList.propTypes = {
  lectures: T.shape({
    edges: T.arrayOf(
      T.shape({
        node: T.object.isRequired,
      }),
    ),
  }).isRequired,
  disabled: T.bool,
}

LectureList.defaultProps = {
  disabled: false,
}
