import React from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import COURSES_QUERY from '../queries/pages/Courses'
import { Layout, CourseList } from '../components'
import { Loader } from '../_shared/components'
import setColors from '../lib/setColors'
import getColor from '../_shared/lib/color'
import { RouteProps } from '../types'

export const routeProps: RouteProps = { path: '/kurs', exact: true }

export default function Courses({ applyColors }: InferProps<typeof Courses.propTypes>): JSX.Element {
  const {
    loading,
    error,
    data: { courses = [], inProgress } = {
      courses: { edges: [] },
      inProgress: { edges: [] },
    },
  } = useQuery(COURSES_QUERY)

  // Set colors
  let palette, color

  if (get(inProgress, 'edges[0].node')) {
    palette = get(inProgress, 'edges[0].node.image.asset.metadata.palette', null)
    color = get(inProgress, 'edges[0].node.color', null)
  } else if (get(courses, 'edges[0].node')) {
    palette = get(courses, 'edges[0].node.image.asset.metadata.palette', null)
    color = get(courses, 'edges[0].node.color', null)
  }

  if (applyColors && (palette || color)) {
    const colors = getColor(palette, color)
    setColors(colors, 'courses')
  }

  if (error) { throw error }

  return (
    <Layout title="Kurs">
      {loading ? (
        <Loader />
      ) : (
        <>
          <CourseList courses={{ edges: inProgress.edges.filter((_, i) => i === 0) }} promoted />
          <CourseList courses={courses} title="Alle kurs" />
        </>
      )}
    </Layout>
  )
}

Courses.propTypes = {
  applyColors: T.bool.isRequired,
}
