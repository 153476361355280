import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('section')

export default function Section({ children, title, ...props }) {
  return (
    <section {...bem('')}>
      <h2 {...bem('title')}>{title}</h2>
      <div {...bem('content')}>{children}</div>
    </section>
  )
}

Section.propTypes = {
  title: T.any,
  children: T.any.isRequired,
}
