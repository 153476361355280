import gql from 'graphql-tag'

export default gql`
  fragment Swatch on SanityImagePaletteSwatch {
    population
    title
    foreground
    background
  }

  fragment Palette on SanityImagePalette {
    darkMuted { ...Swatch }
    darkVibrant { ...Swatch }
    dominant { ...Swatch }
    lightMuted { ...Swatch }
    lightVibrant { ...Swatch }
    muted { ...Swatch }
    vibrant { ...Swatch }
  }
`
