import Color from 'color'
import { get, orderBy } from 'lodash'

function getPlatform(): 'ios' | 'android' | null {
  if (!get(window, 'ReactNativeWebView')) { return null }
  if (window.navigator.userAgent.match(/native-ios/)) { return 'ios' }
  if (window.navigator.userAgent.match(/native-android/)) { return 'android' }
  return null
}

export const COLORS = {
  red: {
    base: '#FC7F6E',
    highlighted: '#EB5F4C',
    muted: '#512A24',
  },
  orange: {
    base: '#FDB26D',
    highlighted: '#F09847',
    muted: '#684A2E',
  },
  yellow: {
    base: '#F9D06C',
    highlighted: '#EABA43',
    muted: '#6C5A2F',
  },
  blue: {
    base: '#81A2FA',
    highlighted: '#5D82E5',
    muted: '#2E3A5B',
  },
  green: {
    base: '#A8DE7C',
    highlighted: '#8EC95F',
    muted: '#496235',
  },
  inverted: {
    base: '#000000',
    highlighted: '#262626',
    muted: '#757575',
  },
  light: {
    base: '#ffffff',
    highlighted: '#EEEEEE',
    muted: 'rgba(0, 0, 0, 0.75)',
  },
}

export const COLOR_DEFAULT = COLORS.red

function setValue(base: number, value: number): number {
  return Array.isArray(value)
    ? Math.max(value[0], Math.min(base, value[1]))
    : value
}

export function adjust(
  color: any,
  { s, l }: {
    s?: number
    l?: number
  }, useColorMap?: boolean,
): string {
  // Convert into hsl
  const hsl = Color(color)
    .hsl()
    .object()

  // Adjust saturation
  if (s) {
    hsl.s = setValue(hsl.s, s)
  }

  // Adjust lightness
  if (l) {
    hsl.l = setValue(hsl.l, l)
  }

  // Color range adjustments (hue-based)
  const colorRangeAdjustments = [
    [0, 50, { s: -3 }],
    [50, 90, { s: -20, l: 1 }],
    [90, 130, { s: -25, l: 3 }],
    [130, 170, { s: -22, l: 3 }],
    [170, 190, { s: -26 }],
    [190, 220, { s: -25, l: 6 }],
    [220, 270, { s: -25, l: 10 }],
    [270, 310, { s: -40, l: 2 }],
    [310, 330, { s: -28, l: 4 }],
    [310, 330, { s: -28, l: 2 }],
    [330, 360, { s: -22 }],
  ]
  const colorRangeMatch = colorRangeAdjustments.find(([min, max]) => hsl.h >= min && hsl.h <= max)

  if (useColorMap && colorRangeMatch && get(colorRangeMatch, '[2]')) {
    const { s, l }: any = colorRangeMatch[2]

    if (s) {
      hsl.s += s
    }

    if (l) {
      hsl.l += l
    }
  }

  return Color(hsl).hex()
}

function scoreColors(colors: any[] = []): { color: string, score: number }[] {
  const colorScores = colors.map<{ color: string, score: number }>(color => {
    const { s } = Color(color)
      .hsl()
      .object()

    // The more vibrant the better
    const score = Math.round(s / 3)

    return {
      color,
      score,
    }
  })

  return orderBy(colorScores, 'score', 'desc')
}

export default function getColor(
  palette: {
    lightVibrant?: any
    vibrant?: any
    darkVibrant?: any
    dominant?: any
  } = {},
  baseColor?: string,
): {
  base: string
  highlighted: string
  muted: string
  } {
  const platform = getPlatform()

  // Make a list of priority from the palette and pick the first actual color
  const colors = [
    get(palette, 'lightVibrant.background', false),
    get(palette, 'vibrant.background', false),
    get(palette, 'darkVibrant.background', false),
    get(palette, 'dominant.background', false),
  ].filter(Boolean)

  const color = baseColor || get(scoreColors(colors), '[0].color', colors[0])

  if (!color || !color.length) {
    return COLOR_DEFAULT
  }

  const saturation = platform === 'android' ? 90 : 97

  const base = adjust(color, {
    s: saturation,
    l: 68,
  }, true)

  return {
    base,
    highlighted: adjust(base, { s: 87, l: 57 }, true),
    muted: adjust(base, { s: 75, l: 22 }),
  }
}
