import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useHistory } from 'react-router-dom'

import { AspectRatioImage } from '../../_shared/components'

const bem = new BEMHelper('topic-entry')

export default function TopicEntry({ title, image, slug }) {
  const history = useHistory()
  const from = history.location.pathname

  return (
    <Link
      to={{
        pathname: `/kategori/${slug}`,
        state: { from },
      }}
      {...bem('')}
    >
      {image && (
        <AspectRatioImage aspect="3/4" {...bem('image')} src={image.url} alt="" lqip={image && image.lqip} />
      )}
      <h2 {...bem('name')}>{title}</h2>
    </Link>
  )
}

TopicEntry.propTypes = {
  title: T.string.isRequired,
  image: T.object.isRequired,
  slug: T.string.isRequired,
}
