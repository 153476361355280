import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { get } from 'lodash'

import useSignedIn from './signed-in'

export default function useMe() {
  // Check if user is signed in. No 'me' if not signed in
  const { signedId } = useSignedIn()

  // Prepare me query for later execution
  const [loadMe, { error, loading, data }] = useLazyQuery(gql`
    query Me {
      me {
        node {
          id
          firstName
          lastName
          email
          roles
        }
      }
    }
  `)

  // Load me if the user is signed in
  useEffect(() => {
    if (signedId) {
      loadMe()
    }
  }, [loadMe, signedId])

  return {
    loading,
    error,
    user: get(data, 'me.node', null),
  }
}
