/* eslint-disable complexity */

import React, { useState, useEffect } from 'react'
import T, { InferProps } from 'prop-types'
import { useParams, useHistory } from 'react-router'
import { get, throttle } from 'lodash'
import { useMutation, useQuery, QueryResult } from '@apollo/client'
import useIsSignedIn from 'hooks/signed-in'

import setColors from '../lib/setColors'
import LECTURE_QUERY from '../queries/pages/Lecture'
import PROGRESS_MUTATION from '../queries/mutations/lecture-progress'
import getColor from '../_shared/lib/color'
import { Layout, CourseVideo } from '../components'
import { Loader } from '../_shared/components'
import { RouteProps, Lecture } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = { path: '/kurs/:slug/leksjon/:lectureId', exact: true }

export default function LecturePage({ applyColors }: InferProps<typeof LecturePage.propTypes>): JSX.Element | null {
  const history = useHistory()
  const { lectureId: id, slug } = useParams()
  const [updateLectureProgress] = useMutation(PROGRESS_MUTATION)
  const [showNext, setShowNext] = useState(false)
  const { signedIn } = useIsSignedIn()

  const {
    loading,
    error,
    data: { lecture: { node: lecture } = { node: undefined } } = {},
  }: QueryResult<{ lecture?: { node?: Lecture } }> = useQuery(LECTURE_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const video = get(lecture, 'video', null)
  const thumbnail = get(lecture, 'video.asset.thumbnail', null)
  const caption = get(lecture, 'caption', null)
  const next = get(lecture, 'next', null)
  const progress = get(lecture, 'progress') || 0
  const hasAccess = get(lecture, 'hasAccess', true)

  const mutateLectureProgress = throttle(value => {
    async function update(): Promise<void> {
      if (!lecture) {
        return
      }

      const diff = Math.abs(lecture.progress - value)

      /**
       * If the difference between the progress stored in the backend,
       * and where the user is differs by less than 4%, do nothing. This
       * is to avoid making too many calls to the backend
       */
      if (diff < 3) {
        return
      }

      const progress = Math.floor(value)

      updateLectureProgress({
        variables: {
          lectureId: id,
          progress,
        },
      })
    }

    update()
  }, 1000)

  const updateProgress = (value: number): void => {
    setShowNext(value === 100)
    signedIn && mutateLectureProgress(value)
  }

  useEffect(() => {
    if (!loading && (!lecture || !hasAccess)) {
      history.push(`/kurs/${slug}`)
    }
  }, [loading, history, slug, lecture, hasAccess])

  // Set colors
  if (!loading && lecture && applyColors) {
    const colors = getColor(
      get(lecture, 'course.node.image.asset.metadata.palette'),
      get(lecture, 'course.node.color'),
    )
    setColors(colors, `lecture/${slug}/${id}`)
  }

  if (error) { throw error }

  if (!loading && !lecture) {
    return <Error404 applyColors title="Leksjonen finnes ikke" />
  }

  const closeLink = `/kurs/${slug}`

  return (
    <Layout
      close={closeLink}
      full
    >
      {video && (
        <CourseVideo
          video={video}
          caption={caption}
          thumbnailUrl={thumbnail}
          active
          onProgress={updateProgress}
          initialProgress={progress}
          resolution={720}
          done={showNext}
          next={next ? { ...next.node, to: `/kurs/${slug}/leksjon/${next.node.id}` } : null}
        />
      )}

      {!video && loading && <Loader />}
    </Layout>
  )
}

LecturePage.propTypes = {
  applyColors: T.bool.isRequired,
}
