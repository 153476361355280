/* eslint-disable complexity */

import './index.scss'

import React, { useState, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { StepAccordion, Ingredients } from '..'
import Video from '../Video'
import Toggle from '../Toggle'
import StepOverlay from '../StepOverlay'
import Rating from '../Rating'
import { Button } from '../../_shared/components'

const bem = new BEMHelper('step')

export default function Step({
  title,
  video,
  ingredients,
  active,
  portions,
  persons,
  goTo,
  wrapperRef,
  toggleOverlay,
  onToggleIngredients,
  setFavorite,
  isFavorite,
  rating,
  setRating,
}) {
  const [moveProgress, setMoveProgress] = useState(0)
  const instructionRef = useRef()
  const hasIngredients = ingredients && ingredients.length > 0

  const onIngredientsToggle = ({ expanded, height }) => {
    setMoveProgress(expanded && height && active ? height : 0)
    onToggleIngredients(expanded)
  }

  const instructionLength = (title && title.length) || 0

  const Intructions = !video ? 'h2' : 'p'

  return (
    <div {...bem('', { 'no-video': !video })}>
      {video && (
        <>
          <Video
            url={video.asset.url}
            thumbnailUrl={`${video.asset.thumbnail}?time=0`}
            active={active}
            moveProgress={moveProgress}
            {...bem('video')}
          />
          <StepAccordion
            title="Ingredienser"
            disabled={!hasIngredients}
            elementAfter={instructionRef.current}
            onToggle={onIngredientsToggle}
          >
            {hasIngredients && (
              <Ingredients items={ingredients} persons={persons} />
            )}
          </StepAccordion>
        </>
      )}

      <Intructions
        {...bem('instructions', {
          long: instructionLength > 40,
          xlong: instructionLength > 70,
        })}
        ref={instructionRef}
      >
        {title}
      </Intructions>

      {portions && (
        <Toggle
          {...bem('portions')}
          value={persons}
          options={portions.options}
          type={portions.type}
          onChange={(value) => {
            portions.setPortions(value)
            setTimeout(() => goTo(1), 98)
          }}
          unitType="personer"
        />
      )}

      {!video && hasIngredients && !portions && (
        <Ingredients
          {...bem('ingrediens')}
          items={ingredients}
          persons={persons}
          large={ingredients.length < 8 && instructionLength <= 70}
          center={ingredients.length < 3 && instructionLength <= 70}
        />
      )}

      {portions && hasIngredients && (
        <StepOverlay insertContentTo={wrapperRef} onChange={toggleOverlay}>
          <Ingredients items={ingredients} persons={persons} />
        </StepOverlay>
      )}

      {setRating && <Rating value={rating || 0} onChange={setRating} />}

      {setFavorite && (
        <Button
          primary
          full
          spaceless
          icon={isFavorite ? 'heartFilled' : 'heart'}
          iconProps={{ size: 'small' }}
          onClick={setFavorite}
          small
          {...bem('button')}
        >
          {isFavorite ? 'Favoritt' : 'Lagre som favoritt'}
        </Button>
      )}
    </div>
  )
}

Step.propTypes = {
  video: T.shape({
    asset: T.shape({
      thumbnail: T.string.isRequired,
      url: T.string.isRequired,
    }).isRequired,
  }),
  ingredients: T.array,
  portions: T.shape({
    type: T.string,
    options: T.array.isRequired,
    setPortions: T.func.isRequired,
  }),
  title: T.string,
  slug: T.string,
  active: T.bool,
  goTo: T.func.isRequired,
  toggleOverlay: T.func.isRequired,
  onToggleIngredients: T.func.isRequired,
  wrapperRef: T.any,
  setFavorite: T.func,
  isFavorite: T.bool,
  rating: T.number,
  setRating: T.func,
  persons: T.oneOf(['two', 'four', 'six', 'eight']),
}

Step.defaultProps = {
  video: null,
  persons: 'two',
}
