export function uppercaseFirst(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export function lowercaseFirst(str) {
  return str[0].toLowerCase() + str.slice(1)
}

export function limit(number) {
  return (item, index) => !number || index < number
}

export function norwegianCommaList(items) {
  if (!items || items.length === 0) {
    return
  }

  return [items.slice(0, items.length - 1).join(', '), items[items.length - 1]]
    .filter(item => Boolean(item))
    .join(' og ')
}

export function truncate(string, length) {
  if (string.length > length) {
    return `${string.substring(0, `${string} `.lastIndexOf(' ', length))}...`
  }

  return string
}

export function sentences(string, length) {
  if (typeof string !== 'string') {
    return string
  }

  const s = string
    .split('. ')
    .filter(limit(length))
    .join('. ')

  return !s.endsWith('.') && !s.endsWith(' ') ? `${s}.` : s
}

const endings = (str, length) => {
  return `${str}${length > 1 ? 'r' : ''}`
}

export function minutesToSentence(min) {
  if (!min) {
    return '— min'
  }

  const h = min / 60
  const hours = Math.floor(h)
  const minutes = Math.round((h - hours) * 60)

  if (hours && minutes) {
    return `${hours} ${endings('time', hours)} ${minutes} min`
  }

  if (hours && !minutes) {
    return `${hours} ${endings('time', hours)}`
  }

  if (!hours && minutes) {
    return `${minutes} min`
  }

  return null
}
