import { COLOR_DEFAULT } from '../_shared/lib/color'

const knownColors = ['base', 'muted', 'highlighted']
const root = document.documentElement
let lastColors

export default (c, key) => {
  const colors = c || COLOR_DEFAULT

  if (key === lastColors) {
    return
  }

  knownColors.forEach(color => {
    const currentColor = root.style.getPropertyValue(`--color-${color}`)
    const nextColor = colors && colors[color]

    // Set the previous color before changing, if a values is currently set
    if (currentColor) {
      root.style.setProperty(`--prev-color-${color}`, currentColor)
    } else if (!currentColor && !nextColor) {
      root.style.removeProperty(`--prev-color-${color}`)
    } else if (!currentColor && nextColor) {
      root.style.setProperty(`--prev-color-${color}`, nextColor)
    }

    if (nextColor) {
      root.style.setProperty(`--color-${color}`, nextColor)
    } else {
      root.style.removeProperty(`--color-${color}`)
    }
  })

  lastColors = key
}
