import gql from 'graphql-tag'

export default gql`
  mutation DisconnectSocial($type: SocialPlatform! $password: String!) {
    disconnectSocial(type: $type password: $password) {
      success
      error {
        code
        message
      }
    }
  }
`
