import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Lecture($id: ID!) {
    lecture(id: $id) {
      node {
        id
        title
        hasAccess
        video {
          asset {
            url
            thumbnail
            data {
              duration
              aspect_ratio
              tracks {
                ... on MuxVideoTrack {
                  max_width
                  max_height
                }
              }
            }
          }
        }
        chapters {
          id
          title
          start
          end
        }
        caption
        progress
        course {
          node {
            id
            progress
            color
            image {
              asset {
                metadata {
                  palette {
                    ...Palette
                  }
                }
              }
              lqip
              url(width: 600)
            }
          }
        }
        next {
          node {
            id
            title
            image {
              url(width: 600)
              lqip
            }
            video {
              asset {
                thumbnail
              }
            }
          }
        }
        previous {
          node {
            id
            title
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
