import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Steps($slug: String!) {
    recipe(slug: $slug) {
      node {
        id
        slug
        title
        color
        isFavorite
        image {
          asset {
            metadata {
              palette {
                ...Palette
              }
            }
          }
        }
        quantitySettings {
          mode
          singleHeading
          doubleHeading
        }
        steps {
          id
          title
          preparation
          video {
            asset {
              url
              thumbnail
            }
          }
          ingredients {
            id
            title
            showInIngredientList
            quantity {
              two
              four
              six
              eight
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
