import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useHistory } from 'react-router-dom'
import { get } from 'lodash'

import { Icon, AspectRatioImage } from '../../_shared/components'

const bem = new BEMHelper('course-list')

export default function CourseList({ courses, title, promoted, animate, removeFavorite }) {
  const history = useHistory()
  if (!courses || !courses.edges || !courses.edges.length) {
    return null
  }

  const getPrecentage = value => {
    // Rounding the percentage so we don't get 99% etc
    return Math.round(value / 10) * 10
  }

  return (
    <nav {...bem('', { promoted })}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      <ul {...bem('list')}>
        {courses.edges.map(({ node: { id, title, slug, image, lectures = { edges: [] }, progress } }) => (
          <li {...bem('item', { animate, removable: !!removeFavorite })} key={id}>
            {removeFavorite && (
              <button
                type="button"
                {...bem('remove')}
                onClick={(event) => {
                  event.preventDefault()
                  removeFavorite(id)
                }}
              >
                <Icon icon="heartFilled" size="small" {...bem('heart')} />
              </button>
            )}

            <Link
              to={{
                pathname: `/kurs/${slug}`,
                state: { from: history.location.pathname },
              }}
              {...bem('link')}
            >
              <AspectRatioImage
                aspect="3/4"
                {...bem('image', { progress })}
                src={image && image.url}
                alt=""
                lqip={image && image.lqip}
              />

              <div {...bem('content')}>
                <h2 {...bem('name')}>{title}</h2>

                <p {...bem('meta')}>
                  <Icon icon="course" size="small" {...bem('icon')} />
                  {get(lectures, 'count', 'Ingen')} kurs
                </p>
                {Boolean(progress) && (
                  <span {...bem('progress')}>
                    <span {...bem('progress-bar')} style={{ width: `${getPrecentage(progress)}%` }} />
                  </span>
                )}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

CourseList.propTypes = {
  title: T.string,
  courses: T.shape({
    edges: T.arrayOf(
      T.shape({
        node: T.object.isRequired,
      }),
    ),
  }).isRequired,
  promoted: T.bool,
  animate: T.bool,
  removeFavorite: T.func,
}
