import { get, includes } from 'lodash'

import useMe from './me'

export default function useRole(role) {
  const { loading, error, user } = useMe()

  return {
    loading,
    error,
    hasRole: includes(get(user, 'roles', []), role),
  }
}
