import { useQuery } from '@apollo/client'

import IS_SIGNED_IN_QUERY from '../queries/is-signed-in'

export default function useIsSignedIn(): { loading: boolean; signedIn: boolean } {
  const {
    loading,
    data: {
      isSignedIn = false,
    } = {},
  } = useQuery(IS_SIGNED_IN_QUERY)

  return {
    loading,
    signedIn: isSignedIn,
  }
}
