import gql from 'graphql-tag'

export default gql`
  query MyPage {
    me {
      node {
        id
        email
        consents {
          id
          type
          timestamp
        }
      }
    }
  }
`
