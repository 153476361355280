import React from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useParams, useLocation } from 'react-router'
import { useQuery, QueryResult } from '@apollo/client'

import getColor from '../_shared/lib/color'
import setColors from '../lib/setColors'
import CATEGORY_QUERY from '../queries/pages/Category'
import { Layout, RecipeList, CourseList } from '../components'
import { RouteProps, Category } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = { path: '/kategori/:slug', exact: true }

export default function CategoryPage({ applyColors }: InferProps<typeof CategoryPage.propTypes>): JSX.Element {
  const location = useLocation()
  const { slug } = useParams()

  const {
    loading,
    error,
    data: { category: query } = {},
  }: QueryResult<{ category?: { node?: Category } }> = useQuery(CATEGORY_QUERY, { variables: { slug } })

  const category = get(query, 'node')
  const title = get(category, 'title', '')
  const image = get(category, 'image', null)
  const preamble = get(category, 'preamble', null)
  const courses = get(category, 'courses', null)
  const recipes = get(category, 'recipes', null)
  const back = get(location, 'state.from', '/oppskrifter')

  const hasCourses = !!get(courses, 'edges', []).length
  const hasRecipes = !!get(recipes, 'edges', []).length

  // Set colors
  if (!loading && category && applyColors) {
    const colors = getColor(
      get(image, 'asset.metadata.palette') ||
      get(courses, 'edges[0].node.image.asset.metadata.palette') ||
      get(recipes, 'edges[0].node.image.asset.metadata.palette'),
      get(courses, 'edges[0].node.color') ||
      get(recipes, 'edges[0].node.color'),
    )
    setColors(colors, `category/${slug}`)
  }

  if (error) { throw error }

  if (!loading && !category) {
    return <Error404 applyColors title="Kategorien finnes ikke" />
  }

  return (
    <Layout
      header={{
        back: { name: 'Tilbake', to: back },
        search: true,
      }}
      cover={{
        loading,
        skeleton: ['title'],
        title,
        preamble,
      }}
    >
      {hasCourses && courses && <CourseList courses={courses} title="Kurs" />}
      {hasRecipes && recipes && <RecipeList recipes={recipes} title="Oppskrifter" tight />}
    </Layout>
  )
}

CategoryPage.propTypes = {
  applyColors: T.bool.isRequired,
}
