import './index.scss'

import { createElement } from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('container')

export default function Container({
  children,
  size,
  className,
  node,
  tight,
  ...props
}: InferProps<typeof Container.propTypes>): JSX.Element {
  const modifiers = []

  if (size) {
    modifiers.push(size)
  }

  if (tight) {
    modifiers.push('tight')
  }

  return createElement(
    node || 'div',
    {
      ...bem('', modifiers, className || undefined),
      ...props,
    },
    children,
  )
}

Container.propTypes = {
  children: T.any,
  size: T.string,
  className: T.string,
  node: T.string.isRequired,
  tight: T.bool,
}

Container.defaultProps = {
  className: undefined,
  node: 'section',
}
