import gql from 'graphql-tag'

export default gql`
  query MyPage {
    me {
      node {
        id
        email
        fbUserId
        appleUserId
        hasPassword
        firstName
        lastName
        roles
        charges {
          id
          due
          amount
          refunded
          vippsChargeId
          plan {
            id
            paymentType
            product {
              id
              title
            }
            variant {
              id
              months
              price
            }
          }
          status
        }
        plans {
          id
          months
          renewal
          product {
            id
            title
            description
            type
          }
          variant {
            id
            months
            price
          }
          end
          amount
          paymentType
          paymentTypeStore
          paymentAgreementStatus
          campaignPrice
          campaignMonths
          campaignEndDate
        }
      }
    }
  }
`
