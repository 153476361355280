import React, { useState, useEffect } from 'react'
import T, { InferProps } from 'prop-types'
import { useParams, useHistory } from 'react-router'
import { findIndex, get, orderBy } from 'lodash'
import { useQuery, useMutation } from '@apollo/client'

import getColor from '../_shared/lib/color'
import trackCustomEvent from '../helpers/track-custom-event'
import { Button, Select } from '../_shared/components'
import WEEK_QUERY from '../queries/pages/Week'
import { ADD_MENU_TO_GROCERY_LIST } from '../queries/mutations/add-menu-to-grocery-list'
import setColors from '../lib/setColors'
import { Layout, RecipeList, Message } from '../components'
import { RouteProps, GroceryList } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = { path: '/uke/:slug', exact: true }

interface SubscribeToPlanResponse {
  success: boolean;
  groceryList: GroceryList;
}

const REDIRECT_DELAY = 1100

export default function WeekPage({ applyColors }: InferProps<typeof WeekPage.propTypes>): JSX.Element {
  const [addedToGrocery, setAddedToGrocery] = useState(false)
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()

  const { loading, error, data } = useQuery(WEEK_QUERY, {
    variables: { slug },
  })

  const weekMenu = get(data, 'weekMenu.node', {})
  const weekMenus = get(data, 'weekMenus.edges', [])

  const [addMenuToGroceryList] = useMutation<SubscribeToPlanResponse>(ADD_MENU_TO_GROCERY_LIST)

  const preamble = get(weekMenu, 'preamble', null)
  const recipes = get(weekMenu, 'recipes', null)

  const ingredients = get(weekMenu, 'shoppingList', []).reduce(
    (res: any, { ingredients }: any) => [...res, ...ingredients],
    [],
  )

  function handleAddMenuToGroceryList(): void {
    addMenuToGroceryList({ variables: { weekMenuSlug: slug } })
    setAddedToGrocery(true)
    trackCustomEvent('Grocery list', 'Add to grocery list', 'Week menu')
  }

  useEffect(() => {
    let _timer: any
    if (addedToGrocery) {
      clearTimeout(_timer)
      _timer = setTimeout(() => { history.push('/handleliste') }, REDIRECT_DELAY)
    }

    return (): void => {
      clearTimeout(_timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedToGrocery])

  // set colors
  if (!loading && weekMenu && applyColors) {
    const colors = getColor(
      get(weekMenu, 'recipes.edges[0].node.image.asset.metadata.palette'),
      get(weekMenu, 'recipes.edges[0].node.color'),
    )
    setColors(colors, `week/${slug}`)
  }

  if (error) { throw error }

  if (!loading && !weekMenu) {
    return <Error404 applyColors title="Ukesmenyen finnes ikke" />
  }

  const weeks = orderBy(
    weekMenus.map(({ node }: any) => ({
      // TODO: Once all week menus are mapped to years/weeks, title references can be removed
      name: `Uke ${node.week || node.title}`,
      year: node.year ? Number(node.year) : 2020, // TODO: Once all week menus belong to a year, we shouldn't default to 2020
      week: node.week ? Number(node.week) : Number(node.title),
      value: node.slug
    })),
    ['year', 'week'],
    ['desc', 'desc']
  ).reduce((res, menu) => {
    const yearIndex = findIndex(res, { year: menu.year })

    if (yearIndex === -1) {
      return [
        ...res,
        {
          year: menu.year,
          label: menu.year,
          options: [menu]
        }
      ]
    }

    return [
      ...res.slice(0, yearIndex),
      {
        ...res[yearIndex],
        options: [
          ...res[yearIndex].options,
          menu
        ]
      },
      ...res.slice(yearIndex + 1)
    ]
  }, [])

  const handleWeekChange = (slug: string): void => {
    history.push(`/uke/${slug}`)
  }

  return (
    <Layout
      header={{
        back: { name: 'Hjem', to: '/' },
        search: true,
      }}
      cover={{
        loading,
        skeleton: ['title'],
        title: 'Ukesmeny',
        preamble,
        children: (
          <Select
            options={weeks}
            value={slug}
            onChange={handleWeekChange}
          />
        ),
      }}
    >
      {recipes && <RecipeList recipes={recipes} titles={['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag']} />}
      {ingredients && ingredients.length > 0 && (
        <Button
          primary
          full
          spaceless
          disabled={addedToGrocery}
          description={{ content: `${ingredients.length} ingredienser` }}
          onClick={handleAddMenuToGroceryList}
        >
          Legg i handleliste
        </Button>
      )}
      {addedToGrocery && <Message message="Legger til i din handleliste" duration={REDIRECT_DELAY} icon="grocery" />}
    </Layout>
  )
}

WeekPage.propTypes = {
  applyColors: T.bool.isRequired,
}
