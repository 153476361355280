import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query {
    equipment: allEquipment {
      edges {
        node {
          id
          title
          preamble
          url
          images {
            lqip
            url(width: 440, height: 440)
            asset {
              metadata {
                palette {
                  ...Palette
                }
              }
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
