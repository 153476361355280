/* eslint-disable max-len */
import React from 'react'
import T, { InferProps } from 'prop-types'

import { Article } from '../_shared/components'
import { RouteProps } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { Layout } from '../components'
import useIsSignedIn from 'hooks/signed-in'

export const routeProps: RouteProps = { path: '/min-side/personvern', exact: true }

export default function Privacy({ applyColors }: InferProps<typeof Privacy.propTypes>): JSX.Element {
  const { loading, signedIn } = useIsSignedIn()

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'privacy')
  }

  // TODO: Use &shy; in title (\u00AD)

  return (
    <Layout
      title="Personvern-erklæring"
      header={!loading && signedIn
        ? { back: { name: 'Min side', to: '/min-side' } }
        : null
      }
    >
      <Article
        preamble="Disse vilkårene gjelder fra 1. april 2020. Foodsteps AS kan fritt endre vilkårene for bruk, kjøp og personvern. Oppdatert versjon er alltid tilgjengelig på denne siden."
      >
        <Article.Text>
          Vi er opptatt av å ta godt vare på informasjonen om deg. Her kan du lese om hvilke data vi samler inn og hvordan det brukes.
        </Article.Text>

        <Article.SubTitle>Personopplysninger</Article.SubTitle>
        <Article.Text>
          Personopplysninger er opplysninger som kan knyttes direkte til deg som person, for eksempel navn og e-postadresse. Slike opplysninger er nødvendig for at vi skal kunne gi deg en best mulig kundeopplevelse.
        </Article.Text>

        <Article.SubTitle>Behandlingsansvarlig og Databehandler</Article.SubTitle>
        <Article.Text>
          Foodsteps AS, organisasjonsnummer 920 889 956 og adresse Kittel-Nielsens vei 61 B 1163 Oslo, er behandlingsansvarlig for dataene som samles inn. Foodsteps vil kunne benytte databehandlere for å bistå med å levere tjenester til deg.
        </Article.Text>
        <Article.Text>
          Foodsteps bruker Behalf AS som underleverandør. Behalf har ansvar for drift, vedlikehold og utvikling av tjenesten og behandler derfor personopplysninger som databehandler på vegne av Foodsteps. Foodsteps og Behalf har en databehandleravtale som sier hvilke opplysningere som behandles og hvem som har tilgang til hva.
        </Article.Text>

        <Article.SubTitle>Samtykke</Article.SubTitle>
        <Article.Text>
          Det er frivillig å gi fra seg personopplysninger til Foodsteps, men for å kunne bruke tjenesten må du registrere deg med navn og e-postadresse. Når du registrer deg som kunde hos Foodteps samtykker du til at du godtar vår personvernerklæring.
        </Article.Text>

        <Article.SubTitle>Dine data og bruken av dem</Article.SubTitle>
        <Article.Text>
          Når du registrerer deg på Foodsteps og bruker tjenesten samler vi inn disse dataene om deg:
        </Article.Text>
        <Article.List
          items={[
            <>
              <Article.SubTitle type="h3">Navn og e-postadresse i vår kundebase:</Article.SubTitle>
              Dette bruker vi for å administrere kundeforholdet, herunder å varsle om endringer i abonnementet, eventuell nedetid o.l. Dersom du har samtykket til at vi kan sende deg nyhetsbrev vil bruke din e-postadresse til dette.
            </>,
            <>
              <Article.SubTitle type="h3">Facebook bruker-ID:</Article.SubTitle>
              Hvis du velger å logge inn med Facebook kobles din konto hos Foodsteps til din profil på Facebook. Vi lagrer kun din IDen fra Facebook, og sender ikke noen informasjon tilbake til Facebook.
            </>,
            <>
              <Article.SubTitle type="h3">Data om det du gjør i appen:</Article.SubTitle>
              For at appen skal fungere må vi spare på en del informasjon som oppskrifter du merker som favoritt, hvor langt du har kommet i kursvideoer, ting du har lagt til i handlekurven og lignende.
            </>,
            <>
              <Article.SubTitle type="h3">Anonymisert informasjon om hvordan du bruker appen og nettsiden:</Article.SubTitle>
              Vi bruker Hotjar, Google Analytics og andre verktøy ved behov for å lære om, forbedre og videreutvikle brukeropplevelsen. De forteller oss for eksempel hvilke sider som er mest besøkt og hvor lenge besøkene varer.
            </>,
            <>
              <Article.SubTitle type="h3">Vipps:</Article.SubTitle>
              Ved betaling gjennom Vipps sparer vi på informasjon som er nødvendig for å sjekke status på betaling og ta betalt i forbindelse med fornying av abonnementet, men lagrer ingen informasjon om hvordan du betaler eller kortdetaljer.
            </>,
            <>
              <Article.SubTitle type="h3">App Store:</Article.SubTitle>
              Ved betaling gjennom App Store sparer vi på informasjon som er nødvendig for å sjekke status på betaling, men lagrer ingen informasjon om hvordan du betaler eller kortdetaljer.
            </>,
            <>
              <Article.SubTitle type="h3">Google Play:</Article.SubTitle>
              Ved betaling gjennom Google Play sparer vi på informasjon som er nødvendig for å sjekke status på betaling, men lagrer ingen informasjon om hvordan du betaler eller kortdetaljer.
            </>,
            <>
              <Article.SubTitle type="h3">Cookies:</Article.SubTitle>
              Vi benytter informasjonskapsler for å gi deg bedre tjenester. De fleste nyere nettlesere er innstilt slik at de automatisk aksepterer cookies. Dersom du ikke ønsker dette, kan du endre innstillinger rett i nettleseren. På Nettvett.no kan du lese om hvordan du kan stille inn nettleseren din for å akseptere eller avvise informasjonskapsler.
            </>,
            <>
              <Article.SubTitle type="h3">Analyse:</Article.SubTitle>
              Analyseverktøyet Google Analytics setter to informasjonskapsler til bruk i analyse av bruksmønstre, disse kapslene begynner på _ga og _gid.
            </>,
          ]}
        />
        <Article.SubTitle>Rettslig grunnlag</Article.SubTitle>
        <Article.Text>
          Det rettslige grunnlaget for behandlingen av personopplysningene er Foodsteps berettigede interesse i å kunne administrere tjenesten til deg (GDPR artikkel 6 nr. 1, bokstav f). Dersom du har samtykket til at vi kan sende deg nyhetsbrev er det rettslige grunnlaget ditt samtykke (GDPR artikkel 6 nr. 1 bokstav a). Vi samler ikke inn personopplysninger som ikke er relevant og nødvendig for å levere Foodsteps til deg som kunde. Vi selger aldri personopplysningene dine videre til andre selskaper.
        </Article.Text>

        <Article.SubTitle>Hvilke rettigheter har du?</Article.SubTitle>

        <Article.List
          items={[
            <>
              <Article.SubTitle type="h3">Tilgang:</Article.SubTitle>
              Du har til enhver tid rett til å be om informasjon om personopplysningene vi oppbevarer om deg. Kontakt oss på post@foodsteps.no så hjelper vi deg.
            </>,
            <>
              <Article.SubTitle type="h3">Korrigering:</Article.SubTitle>
              Du har rett til å be om korrigering av personopplysningene dine dersom de ikke er korrekte, og dette inkluderer retten til å utfylle ufullstendige personopplysninger. Siden Foodsteps er en digital tjeneste kan du selv korrigere opplysningene på Min side.
            </>,
            <>
              <Article.SubTitle type="h3">Dataportabilitet:</Article.SubTitle>
              Du har rett til å få med deg dataene vi har lagret om deg hvis du slutter å bruke Foodsteps. Kontakt oss på post@foodsteps.no så hjelper vi deg.
            </>,
            <>
              <Article.SubTitle type="h3">Sletting:</Article.SubTitle>
              Personopplysningene vi har lagret om deg slettes når ditt abonnement avsluttes. Personopplysninger direkte knyttet til transaksjoner må vi imidlertid lagre i 5 kalenderår for å oppfylle bokføringsloven. Opplysninger vil eventuelt også kunne bli lagret lenger om nødvendig for å fastsette, gjøre gjeldende eller forsvare rettskrav. Du har for øvrig rett til når som helst å kreve sletting av personopplysningene som behandles av Foodsteps, samt til å protestere mot behandlingen og eventuelt påklage behandlingen til Datatilsynet.
            </>,
          ]}
        />

      </Article>
    </Layout>
  )
}

Privacy.propTypes = {
  applyColors: T.bool.isRequired,
}
