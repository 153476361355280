import React, { useEffect, useState, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import formatSeconds from '../../lib/format-seconds'
import useGestures from '../../hooks/useGestures'

const bem = new BEMHelper('course-video')

export default function Progress({
  percentage,
  setProgress,
  duration,
  showControls,
}) {
  const wrapper = useRef()
  const seeker = useRef()
  const [touch, setTouch] = useState(false)
  const [move, setMove] = useState(0)
  const [minMax, setMinMax] = useState(null)

  useEffect(() => {
    if (document.activeElement === wrapper.current && !touch) {
      wrapper.current.blur()
    }
  }, [touch])

  useGestures(seeker, {
    touchStart: ({ positions: { startX } }) => {
      setTouch(true)
      wrapper.current.focus()
      const seekerRect = seeker.current.getBoundingClientRect()
      const value = startX - seekerRect.left
      const percentage = (value * 100) / seeker.current.offsetWidth
      setMinMax([seekerRect.left, seekerRect.right])
      setProgress(percentage)
    },
    touchMove: ({ positions: { diffX, startX } }) => {
      if (startX - diffX < minMax[0]) {
        setMove(-Math.round(startX - minMax[0]))
      } else if (startX - diffX > minMax[1]) {
        setMove(-Math.round(startX - minMax[1]))
      } else {
        setMove(-Math.round(diffX))
      }
    },
    touchEnd: ({ positions: { endX } }) => {
      setTouch(false)
      setMove(0)

      const value = endX - seeker.current.getBoundingClientRect().left
      const percentage = (value * 100) / seeker.current.offsetWidth
      setProgress(Math.min(100, Math.max(0, percentage)))
    },
  })

  return (
    <span {...bem('progress', { hidden: !showControls })} tabIndex="0" ref={wrapper}>
      <span {...bem('progress-wrapper')} ref={seeker}>
        <span
          {...bem('progress-bar')}
          style={{ transform: `translateX(calc(-${100 - percentage}% + ${move}px))` }}
        >
          <span
            {...bem('progress-indicator', { touch })}
          >
            {formatSeconds(duration * (percentage / 100))}
          </span>
        </span>
      </span>
    </span>
  )
}

Progress.propTypes = {
  percentage: T.number.isRequired,
  setProgress: T.func.isRequired,
  chapters: T.array,
  duration: T.number.isRequired,
  showControls: T.bool,
}
