import './index.scss'

import React, { useState, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('input')

export default function Input({
  label,
  onChange,
  value = '',
  type = 'text',
  className = undefined,
  dark = false,
  ...props
}) {
  const inputRef = useRef()
  const [isFocus, setIsFocus] = useState(false)
  const [inputType, setType] = useState(type)

  const handleChange = ({ target: { value } }) => {
    onChange(value)
  }

  const handleFocus = () => {
    setIsFocus(true)
  }

  const handleBlur = () => {
    setIsFocus(false)
  }

  const overlay = value === '' && !isFocus

  const viewMode = type === 'password' && inputType !== 'password'
  const toggleViewMode = () => {
    inputRef.current.focus()

    setType(type => {
      if (type === 'password') {
        return 'text'
      }

      return 'password'
    })
  }

  return (
    <label {...bem('', { overlay, dark }, className)}>
      <input
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        type={inputType || 'text'}
        ref={inputRef}
        {...props}
        {...bem('input')}
      />
      <span {...bem('label')}>{label || ''}</span>

      {type === 'password' && (
        <button
          type="button"
          aria-label={viewMode ? 'Skjul passord' : 'Vis passord'}
          onClick={toggleViewMode}
          {...bem('toggle-password')}
        >
          <Icon icon={viewMode ? 'passwordVisible' : 'passwordHidden'} />
        </button>
      )}
    </label>
  )
}

/*
passwordHidden
passwordVisible
*/

Input.propTypes = {
  type: T.string,
  label: T.string,
  onChange: T.func.isRequired,
  value: T.oneOfType([T.string, T.number]).isRequired,
  className: T.string,
  dark: T.bool,
}

Input.defaultProps = {
  onChange: () => {},
}
