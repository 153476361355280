import React from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useLocation } from 'react-router'

import { COLORS } from '../_shared/lib/color'
import setColors from '../lib/setColors'
import { Layout, Paywall } from '../components'

export const routeProps = { path: '/prov', exact: true }

export default function TryPage({
  applyColors,
}: InferProps<typeof TryPage.propTypes>): JSX.Element {
  const location = useLocation()

  // Set colors
  if (applyColors) {
    setColors(COLORS.yellow, 'try')
  }

  const back = get(location, 'state.from', '/')

  return (
    <Layout
      header={{
        back: { name: 'Hjem', to: back },
      }}
    >
      <Paywall title="Prøv 30 dager gratis" />
    </Layout>
  )
}

TryPage.propTypes = {
  applyColors: T.bool.isRequired,
}
