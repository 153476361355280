import gql from 'graphql-tag'

import PaletteFragment from './palette'

export default gql`
  fragment PageSections on PageSection {
    id
    ... on CoursesList {
      title
      courses {
        edges {
          node {
            id
            title
            slug
            progress
            image {
              url(width: 600, height: 732)
              lqip
            }
            lectures {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
    ... on RecipePromotion {
      title
      recipes {
        edges {
          node {
            id
            title
            duration
            image {
              url(width: 600, height: 732)
              lqip
            }
            slug
          }
        }
      }
    }
    ... on CategoryPromotion {
      category {
        node {
          id
          slug
          title
          recipes { count }
          courses { count }
          image {
            url(width: 600, height: 732)
            lqip
            asset {
              metadata {
                palette {
                  ...Palette
                }
              }
            }
          }
        }
      }
    }
    ... on WeekMenuPromotion {
      weekMenu {
        node {
          id
          slug
          title
          image {
            url(width: 600, height: 732)
            lqip
            asset {
              metadata {
                palette {
                  ...Palette
                }
              }
            }
          }
          recipes { count }
        }
      }
    }
  }
  ${PaletteFragment}
`
