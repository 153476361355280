/* eslint-disable no-alert */
/* eslint-disable indent */
/* eslint complexity:0 */

import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery, useMutation, QueryResult } from '@apollo/client'
import { addDays, format } from 'date-fns'
import { filter, some, sortBy } from 'lodash'
import { useHistory } from 'react-router-dom'

import { Button, Loader, Input } from '../_shared/components'
import MY_PAGE_QUERY from '../queries/pages/MyPage'
import DISCONNECT_SOCIAL from '../queries/mutations/disconnect-social'
import {
  STOP_RENEWAL,
  RESTART_RENEWAL,
} from '../queries/mutations/alter-plan-renewal'
import { Layout, Info, Actions, Section, Confirm } from '../components'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { User, RouteProps, Plan } from '../types'
import useNative from '../hooks/isNative'
import postMessage from '../_shared/lib/post-message'

export const routeProps: RouteProps = { path: '/min-side', exact: true }

interface AlterRenewalResponse {
  success: boolean
  plan?: {
    id: string
    paymentAgreementStatus: string
  }
}

interface DisconnectSocialResponse {
  success: boolean
  error?: { code: number; message: string }
}

export default function MyPage({
  applyColors,
}: InferProps<typeof MyPage.propTypes>): JSX.Element | null {
  const { loading, error, data }: QueryResult<{ me?: { node?: User } }> =
    useQuery(MY_PAGE_QUERY, {
      fetchPolicy: 'network-only',
    })

  const me = data?.me?.node

  const isNative = useNative()
  const history = useHistory()
  const [confirm, setConfirm] = useState<null | string>(null)
  const [password, setPassword] = useState<string>('')
  const [disconnectError, setDisconnectError] = useState<null | string>(null)

  const [stopRenewal] =
    useMutation<{ stopRenewal: AlterRenewalResponse }>(STOP_RENEWAL)
  const [restartRenewal] = useMutation<{
    restartRenewal: AlterRenewalResponse
  }>(RESTART_RENEWAL)
  const [disconnectSocial] = useMutation<{
    disconnectSocial: DisconnectSocialResponse
  }>(DISCONNECT_SOCIAL)

  const getPlanRenewalToggleHandler =
    (plan: Plan, renew: boolean): (() => {}) =>
    (): Promise<any> => {
      if (renew) {
        return restartRenewal({
          variables: { planId: plan.id },
          refetchQueries: [{ query: MY_PAGE_QUERY }],
        })
      }

      return stopRenewal({
        variables: { planId: plan.id },
        refetchQueries: [{ query: MY_PAGE_QUERY }],
      })
    }

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'mypage')
  }

  if (loading) {
    return (
      <Layout title="Min side">
        <Loader />
      </Layout>
    )
  }

  if (error) {
    throw error
  }

  if (!me) {
    history.push('/')
    return null
  }

  const plans = sortBy(me?.plans || [], ['end'])
  const charges = filter(me?.charges || [], { status: 'charged' })

  const hideConfirm = (): void => {
    setConfirm(null)
  }

  const handleDisconnect = async (): Promise<void> => {
    setDisconnectError(null)

    const res = await disconnectSocial({
      variables: { type: confirm, password },
      refetchQueries: [{ query: MY_PAGE_QUERY }],
    })

    const disconnectError = res?.data?.disconnectSocial?.error ?? null

    if (disconnectError) {
      switch (disconnectError.code) {
        case 1:
          return setDisconnectError('Feil passord')
        case 9:
          return setDisconnectError('Det skjedde noe feil')
        default:
          return setDisconnectError(disconnectError.message)
      }
    }

    hideConfirm()
  }

  let name = me?.firstName || ''
  if (me?.lastName) {
    name = `${name?.length ? ' ' : ''}${me?.lastName}`
  }

  const vippsExtendedByVoucher =
    some(plans, { paymentType: 'voucher' }) &&
    some(plans, { paymentType: 'vipps' })

  return (
    <Layout title="Din konto" tight>
      <Info type={['center', 'muted']} title={name}>
        {me?.email}
      </Info>

      <Actions
        small={true}
        center={true}
        spaceless={true}
        actions={[
          ...(me?.hasPassword
            ? [
                me?.fbUserId
                  ? {
                      key: 'fb-disconnect',
                      label: 'Koble fra Facebook',
                      onClick: (): void => {
                        setConfirm('facebook')
                      },
                    }
                  : {
                      key: 'fb-connect',
                      label: 'Koble til Facebook',
                      onClick: (): void => {
                        isNative
                          ? postMessage({ type: 'connect-facebook' })
                          : alert('Ikke tilgjengelig enda')
                      },
                    },
              ]
            : []),
          ...(me?.hasPassword
            ? [
                me?.appleUserId
                  ? {
                      key: 'apple-disconnect',
                      label: 'Koble fra Apple',
                      onClick: (): void => {
                        setConfirm('apple')
                      },
                    }
                  : {
                      key: 'apple-connect',
                      label: 'Koble til Apple',
                      onClick: (): void => {
                        isNative
                          ? postMessage({ type: 'connect-apple' })
                          : alert('Ikke tilgjengelig enda')
                      },
                    },
              ]
            : []),
          ...(isNative
            ? [
                {
                  key: 'restore-purchases',
                  label: 'Gjenopprett kjøp',
                  onClick: (): void =>
                    postMessage({ type: 'restore-purchases' }),
                },
              ]
            : []),
          {
            key: 'change-pw',
            label: me?.hasPassword ? 'Endre passord' : 'Sett passord',
            to: '/endre-passord',
          },
          { key: 'logout', label: 'Logg ut', to: '/logout' },
        ]}
      />

      {plans.length > 0 && (
        <Section title="Abonnement">
          {vippsExtendedByVoucher && (
            <Info type="center">
              Du har et Vipps-abonnement og har løst inn et gavekort.
              Vipps-abonnementet fortsetter når gavekort-perioden er over.
            </Info>
          )}

          {plans.map((plan, index) => (
            <div key={plan.id}>
              {index > 0 ? (
                <React.Fragment>
                  <br />
                  <br />
                </React.Fragment>
              ) : null}
              <Info type="center">
                {plan.paymentType === 'voucher' ? (
                  <React.Fragment>
                    {plan.product.title} til den{' '}
                    {format(new Date(plan.end), 'dd.MM.yyyy')}
                    <br />
                    Betalt med gavekort
                  </React.Fragment>
                ) : null}

                {plan.paymentType === 'vipps' ? (
                  <React.Fragment>
                    {plan.product.title}
                    <br />
                    {plan.campaignEndDate &&
                    new Date(plan.campaignEndDate) > new Date() ? (
                      <React.Fragment>
                        {(plan.campaignPrice || 0) / 100},- i måneden frem til
                        den {format(new Date(plan.campaignEndDate), 'dd.MM')}.
                        <br />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {plan.amount / 100},- i måneden.
                      </React.Fragment>
                    )}
                    <br />
                    {plan.renewal ? 'Fornyes' : 'Løper ut'} den{' '}
                    {format(new Date(plan.end), 'dd.MM.yyyy')}
                    <br />
                    Betaling med Vipps
                    {plan.renewal &&
                    plan.campaignEndDate &&
                    new Date(plan.campaignEndDate) > new Date() ? (
                      <React.Fragment>
                        <br />
                        <br />
                        Etter kampanjeperioden fornøyes abonnementet til{' '}
                        {plan.amount / 100},- i måneden.
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}

                {plan.paymentType === 'inApp' ? (
                  <React.Fragment>
                    {plan.product.title} til den{' '}
                    {format(new Date(plan.end), 'dd.MM.yyyy')}
                    <br />
                    Betaling gjennom{' '}
                    {plan.paymentTypeStore.toLocaleUpperCase() === 'PLAY_STORE'
                      ? 'Google Play'
                      : 'App Store'}
                    {/* <br />
                    39,- i måneden. */}
                  </React.Fragment>
                ) : null}
              </Info>

              {plan.paymentType === 'vipps' && plan.renewal && (
                <Button
                  onClick={getPlanRenewalToggleHandler(plan, false)}
                  primary
                  full
                  small
                >
                  Stopp abonnement
                </Button>
              )}

              {/* Allow restart renewals if plan ends in more than two days */}
              {plan.paymentType === 'vipps' &&
                !plan.renewal &&
                new Date(plan.end) > addDays(new Date(), 2) && (
                  <Button
                    onClick={getPlanRenewalToggleHandler(plan, true)}
                    primary
                    full
                    small
                  >
                    Skru på fornying
                  </Button>
                )}

              {plan.paymentType === 'inApp' &&
                plan.paymentTypeStore.toLocaleUpperCase() === 'APP_STORE' && (
                  <Button
                    onClick={(): void => {
                      window.location.href =
                        'itms-apps://apps.apple.com/account/subscriptions'
                    }}
                    primary
                    full
                    small
                  >
                    Administrer i App Store
                  </Button>
                )}

              {plan.paymentType === 'inApp' &&
                plan.paymentTypeStore.toLocaleUpperCase() === 'PLAY_STORE' && (
                  <Button
                    onClick={(): void =>
                      postMessage({
                        type: 'open',
                        url: 'https://play.google.com/store/account/subscriptions?sku=no.foodsteps.app',
                      })
                    }
                    primary
                    full
                    small
                  >
                    Administrer i Google Play
                  </Button>
                )}
            </div>
          ))}
        </Section>
      )}

      {charges.length ? (
        <Section title="Kvitteringer">
          <Info type="center">
            Du{' '}
            {some(plans, { paymentType: 'vipps' }) ? 'betaler' : 'har betalt'}{' '}
            med Vipps. Kvittering for disse betalingene finner du her.
          </Info>
          <Button to="/min-side/kvitteringer" primary full small>
            Se dine Vipps-kvitteringer
          </Button>
        </Section>
      ) : null}

      <Section title="Mellom oss">
        <Actions
          small={true}
          center={true}
          spaceless={true}
          actions={[
            { key: 'terms', label: 'Les våre vilkår', to: '/min-side/vilkar' },
            {
              key: 'privacy',
              label: 'Les om vårt personvern',
              to: '/min-side/personvern',
            },
            { key: 'data', label: 'Se dine data', to: '/min-side/data' },
          ]}
        />
      </Section>

      <Section title="Kontakt">
        <Info type="center">
          <div>
            <a href="mailto:post@foodsteps.no" className="">
              post@foodsteps.no
            </a>
          </div>
          <div>
            <a href="tel:+4792231200" className="">
              (+47) 922 31 200
            </a>
          </div>
        </Info>
        <Info type="center" title="Adresse:">
          Kittel-Nielsens vei 61 B<br />
          1163 Oslo
        </Info>
        <Info type="center" title="Organisasjonsnr.:">
          920 889 956
        </Info>
      </Section>

      <Confirm
        title="Skriv inn ditt Foodsteps-passord for å bekrefte"
        onSubmit={(e): void => {
          e.preventDefault()
          handleDisconnect()
        }}
        actions={[
          {
            label: `Koble fra ${confirm}`,
            primary: true,
            onClick: handleDisconnect,
          },
          { label: 'Avbryt', dark: true, onClick: hideConfirm },
        ]}
        onClose={hideConfirm}
        show={!!confirm}
      >
        <Input
          type="password"
          label="Ditt passord"
          value={password}
          onChange={setPassword}
        />
        {disconnectError}
      </Confirm>
    </Layout>
  )
}

MyPage.propTypes = {
  applyColors: T.bool.isRequired,
}
