import React from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'

import HOME_QUERY from '../queries/pages/Home'
import { Layout, PageSections, Categories, RecipeList, WeekMenuEntry } from '../components'
import { Loader } from '../_shared/components'
import setColors from '../lib/setColors'
import getColor from '../_shared/lib/color'
import { RouteProps } from '../types'

export const routeProps: RouteProps = { path: '/', exact: true }

export default function Home({ applyColors }: InferProps<typeof Home.propTypes>): JSX.Element {
  const {
    loading,
    error,
    data: {
      frontpage = { sections: [], promoted: { node: {} } },
      categories = { edges: [] },
    } = {},
  } = useQuery(HOME_QUERY)

  let palette, color
  const promoted = get(frontpage, 'promoted')
  const promotedType = get(promoted, '__typename', null)

  if (promoted) {
    palette = (
      get(promoted, 'node.image.asset.metadata.palette') ||
      get(promoted, 'node.recipes.edges[0].node.image.asset.metadata.palette') ||
      null
    )
    color = (
      get(promoted, 'node.color') ||
      get(promoted, 'node.recipes.edges[0].node.color') ||
      null
    )
  } else {
    palette = get(frontpage, 'sections[0].category.node.image.asset.metadata.palette', null)
  }

  // Set colors
  if (applyColors && (palette || color)) {
    const colors = getColor(palette, color)
    setColors(colors, 'home')
  }

  if (error) { throw error }

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout logo={true}>
      {promotedType === 'WeekMenusEdge' ? (
        <WeekMenuEntry
          year={get(promoted, 'node.year')}
          week={get(promoted, 'node.week')}
          recipes={get(promoted, 'node.recipes', {})}
          slug={get(promoted, 'node.slug', null)}
          promoted
        />
      ) : null}

      {promotedType === 'RecipesEdge' ? (
        <RecipeList
          recipes={[promoted]}
          type="promoted" />
      ) : null}

      <PageSections sections={frontpage.sections} />

      {categories.edges.map(({ node }: any) => (
        <Categories key={get(node, 'id')} {...node} />
      ))}
    </Layout>
  )
}

Home.propTypes = {
  applyColors: T.bool.isRequired,
}
