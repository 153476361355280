import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Course($slug: String!) {
    course(slug: $slug) {
      node {
        id
        slug
        title
        preamble
        duration
        progress
        isFavorite
        hasAccess
        color
        image {
          url(width: 600, height: 732)
          lqip
          asset {
            metadata {
              palette {
                ...Palette
              }
            }
          }
        }
        lectures {
          edges {
            node {
              id
              title
              progress
              duration
              image {
                url(width: 600, height: 732)
                lqip
              }
              video {
                asset {
                  data {
                    duration
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
