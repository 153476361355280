import React, { ReactElement } from 'react'
import T, { InferProps } from 'prop-types'
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group'
import BEMHelper from 'react-bem-helper'

export default function Transition({
  children,
  updateKey,
  className,
  enter,
  exit,
  node,
  ...props
}: InferProps<typeof Transition.propTypes>): ReactElement {
  const classes = className ? className.split(' ') : ['transition']
  const BEMName = classes[0]
  const modifiers = [...classes].filter((a, i) => i !== 0).map(name => name.split(`${BEMName}--`)[1])
  const bem = new BEMHelper(BEMName)
  const Node = node

  return (
    <TransitionGroup component={null}>
      <ReactTransition key={updateKey} timeout={{
        enter: Number(enter),
        exit: Number(exit),
      }}>
        {(status: string): ReactElement => (
          <Node {...bem('', [status, ...modifiers])} {...props}>
            {children}
          </Node>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

Transition.propTypes = {
  children: T.any.isRequired,
  updateKey: T.string.isRequired,
  className: T.string.isRequired,
  enter: T.number,
  exit: T.number,
  node: T.any,
}

Transition.defaultProps = {
  enter: 1200,
  exit: 600,
  node: 'div',
}
