import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query {
    recipes: favoriteRecipes {
      id
      recipe {
        node {
          id
          slug
          title
          duration
          color
          image {
            url(width: 600, height: 732)
            lqip
            asset {
              metadata {
                palette {
                  ...Palette
                }
              }
            }
          }
        }
      }
    }
    courses: favoriteCourses {
      id
      course {
        node {
          id
          slug
          title
          duration
          lectures { count }
          color
          image {
            url(width: 600, height: 732)
            lqip
            asset {
              metadata {
                palette {
                  ...Palette
                }
              }
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
