import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('preamble')

export default function Preamble({ children, ...props }) {
  return (
    <p {...bem('')} {...props}>
      {children}
    </p>
  )
}

Preamble.propTypes = {
  children: T.any,
}
