/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import React, { useEffect } from 'react'
import T, { InferProps } from 'prop-types'
import { Link } from 'react-router-dom'
import BEMHelper from 'react-bem-helper'

import { Cover, Header } from '..'
import { Container, Logo, Icon } from '../../_shared/components'

const bem = new BEMHelper('layout')

export default function Layout({
  children,
  header,
  cover,
  noAnimation,
  dark,
  title,
  tight,
  logo,
  full,
  spaceless,
  close,
}: InferProps<typeof Layout.propTypes>): JSX.Element {
  useEffect(() => {
    let _timeout: NodeJS.Timeout

    if (close) {
      document.body.classList.add('modal-open')

      _timeout = setTimeout(() => {
        document.body.classList.add('modal-open')
      }, 800)
    }

    return (): void => {
      clearTimeout(_timeout)

      if (close) {
        document.body.classList.remove('modal-open')
      }
    }
  }, [])

  return (
    <div
      {...bem('', {
        close: !!close,
        'no-animate': !!noAnimation,
        dark,
        full,
        'padding-top': !header && !close && !full && !spaceless,
      })}
    >
      {header && <Header {...header} />}
      {close && (
        <Link
          to={close}
          {...bem('close')}
          aria-label="Lukk"
        >
          <Icon icon="close" size="small" />
        </Link>
      )}

      {title && <h1 {...bem('title')}>{title}</h1>}

      {logo && !title && (
        <h1 {...bem('title')}>
          <Logo {...bem('logo')} />
        </h1>
      )}

      {cover && <Cover {...cover} />}

      <Container {...bem('content')} tight={tight}>{children}</Container>
    </div>
  )
}

Layout.propTypes = {
  children: T.node,
  title: T.string,
  cover: T.shape(Cover.propTypes),
  header: T.object,
  noAnimation: T.bool.isRequired,
  dark: T.bool.isRequired,
  full: T.bool.isRequired,
  logo: T.bool.isRequired,
  tight: T.bool.isRequired,
  spaceless: T.bool.isRequired, // TODO: Better naming? Only removes top padding
  close: T.string,
}

Layout.defaultProps = {
  noAnimation: false,
  dark: false,
  full: false,
  spaceless: false,
  logo: false,
  tight: false,
}
