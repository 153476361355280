import algoliasearch from 'algoliasearch'
import { SearchOptions } from '@algolia/client-search';
import { RequestOptions } from '@algolia/transporter'
import debounce from 'p-debounce'

export const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || 'AQSLOQV4XN',
  process.env.REACT_APP_ALGOLIA_TOKEN || '0c557e3b769cb7ef7e632047988ec543',
)

export const index = client.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX || 'sanity',
)

export const debouncedSearch = (ms: number) => debounce(
  (query: string, options?: RequestOptions & SearchOptions) => index.search(query, options), ms,
)
