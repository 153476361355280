import React from 'react'
import T, { InferProps } from 'prop-types'

import { RecipeList, CourseList, TopicEntry, WeekMenuEntry } from '..'

export default function PageSections({
  sections = [],
  skipFirstTitle = false,
}: InferProps<typeof PageSections.propTypes>): JSX.Element {
  return (
    <>
      {sections.map((item, index) => {
        switch (item.__typename) {
          case 'RecipePromotion':
            return (
              <RecipeList
                title={skipFirstTitle && index === 0 ? null : item.title}
                key={item.id}
                recipes={item.recipes}
                type={item.variant}
              />
            )

          case 'CoursesList':
            return (
              <CourseList
                title={skipFirstTitle && index === 0 ? null : item.title}
                key={item.id}
                courses={item.courses}
              />
            )

          case 'CategoryPromotion':
            return <TopicEntry key={item.id} {...item.category.node} />

          case 'WeekMenuPromotion':
            return <WeekMenuEntry key={item.id} {...item.weekMenu.node} />

          default:
            return null
        }
      })}
    </>
  )
}

PageSections.propTypes = {
  sections: T.array.isRequired,
  skipFirstTitle: T.bool.isRequired,
}

PageSections.defaultProps = {
  sections: [],
  skipFirstTitle: false,
}
