import React from 'react'
import T, { InferProps } from 'prop-types'

import { Layout } from '../components'
import { Button, Article } from '../_shared/components'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'

export default function ErrorPage({ applyColors }: InferProps<typeof ErrorPage.propTypes>): JSX.Element {
  // Set colors
  if (applyColors) {
    setColors(COLORS.red, '500')
  }

  return (
    <Layout cover={{ title: 'Teknisk feil' }}>
      <Article.Text>Vi har blitt varslet om feilen. Du gjorde ikke noe galt, og vi vil se på saken.</Article.Text>
      <Button onClick={(): void => { window.location.pathname = '/' }} primary full icon="home">Ta meg hjem</Button>
    </Layout>
  )
}

ErrorPage.propTypes = {
  applyColors: T.bool.isRequired,
}
