import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { NavLink } from 'react-router-dom'

import { routeProps as homeRouteProps } from '../../pages/Home'
import { routeProps as recipesRouteProps } from '../../pages/Recipes'
import { routeProps as coursesRouteProps } from '../../pages/Courses'
import { routeProps as favouritesRouteProps } from '../../pages/Favourites'
import { routeProps as groceryRouteProps } from '../../pages/Grocery'
import { Icon, Transition } from '../../_shared/components'

const bem = new BEMHelper('navbar')

export default function Navbar({ items, show }) {
  return (
    <>
      {show && <div {...bem('dummy')} />}
      <Transition {...bem('')} updateKey={show ? 'show' : 'hide'} enter={500} exit={300}>
        {show && (
          <nav {...bem('bar')} role="menu">
            {/* promptEvent && 'ready to prompt' */}
            {(items || []).map(({ _key, to, text, icon, ...props }) => (
              <NavLink
                to={{
                  pathname: to,
                  state: { scrollToTop: true },
                }}
                key={_key}
                {...bem('action')}
                activeClassName="navbar__action--selected"
                aria-label={text}
                role="menuitem"
                {...props}
              >
                <Icon icon={icon} {...bem('icon')} />
              </NavLink>
            ))}
          </nav>
        )}
      </Transition>
    </>
  )
}

Navbar.propTypes = {
  items: T.array,
  show: T.bool,
}

Navbar.defaultProps = {
  items: [
    {
      _key: 'home',
      text: 'Hjem',
      icon: 'home',
      to: homeRouteProps.path,
      exact: true,
    },
    {
      _key: 'recipes',
      text: 'Oppskrifter',
      icon: 'recipe',
      to: recipesRouteProps.path,
    },
    {
      _key: 'course',
      text: 'Kurs',
      icon: 'course',
      to: coursesRouteProps.path,
    },
    {
      _key: 'favourites',
      text: 'Favoritter',
      icon: 'heart',
      to: favouritesRouteProps.path,
    },
    {
      _key: 'grocery',
      text: 'Handleliste',
      icon: 'grocery',
      to: groceryRouteProps.path,
    },
  ],
}
