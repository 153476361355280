import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('ingredients')

export default function Ingredients({
  items,
  persons,
  large,
  bullets,
  animate,
  tight,
  className,
}) {
  return (
    <div {...bem('', { large, tight, bullets, animate }, className)}>
      <ul {...bem('list')}>
        {items.map(({ id, quantity, title } = {}) => (
          <li {...bem('item')} key={id}>
            {quantity && quantity[persons] && (
              <span {...bem('quantity')}>{quantity[persons]}</span>
            )}
            <span {...bem('type')}>{title}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

Ingredients.propTypes = {
  items: T.array.isRequired,
  persons: T.oneOf(['two', 'four', 'six', 'eight']),
  large: T.bool,
  tight: T.bool,
  bullets: T.bool,
  animate: T.bool,
  className: T.string,
}

Ingredients.defaultProps = {
  persons: 'two',
}
