import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Button, Icon } from '../../_shared/components'

const bem = new BEMHelper('actions')

export default function Actions({
  actions,
  small,
  center,
  spaceless,
}: InferProps<typeof Actions.propTypes>): JSX.Element {
  return (
    <nav {...bem('', { spaceless: !!spaceless })}>
      {actions.map(({ key, label, icon, onClick, to, disabled }) => {
        const props = {
          onClick: onClick || undefined,
          to: to || undefined,
          small: small || false,
          left: !center,
          disabled: disabled || false,
        }

        return (
          <Button
            key={key}
            primary
            full
            spaceless
            {...bem('action', { icon: !!icon, disabled: !!disabled })}
            {...props}
          >
            {icon && <span {...bem('icon')}><Icon icon={icon} /></span>}
            {label}
          </Button>
        )
      })}
    </nav>
  )
}

Actions.propTypes = {
  center: T.bool,
  small: T.bool,
  spaceless: T.bool,
  actions: T.arrayOf(
    T.shape({
      key: T.string.isRequired,
      label: T.string.isRequired,
      icon: T.string,
      onClick: T.func,
      disabled: T.bool,
      to: T.oneOfType([T.string, T.object]),
    }).isRequired,
  ).isRequired,
}
