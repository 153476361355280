import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('toggle')

export default function Toggle({ options, value, unitType, onChange, type }) {
  return (
    <div {...bem('', type)} role="listbox">
      {options.map(([key, label]) => (
        <button
          key={key}
          type="button"
          {...bem('option', { selected: key === value })}
          onClick={() => onChange(key)}
          aria-label={`${label} ${unitType}`}
          aria-selected={key === value}
          role="option"
        >
          {label}
        </button>
      ))}
    </div>
  )
}

Toggle.propTypes = {
  options: T.array.isRequired,
  onChange: T.func.isRequired,
  unitType: T.string,
  type: T.string,
  value: T.oneOfType([T.string, T.number]),
}
