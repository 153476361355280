/* eslint-disable max-len */
import React from 'react'
import T, { InferProps } from 'prop-types'
import { useApolloClient, useMutation, useQuery, QueryResult } from '@apollo/client'
import { find, get } from 'lodash'
import { format } from 'date-fns'

import { Article, Checkbox, Loader } from '../_shared/components'
import { Consent, RouteProps, User } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import postMessage from '../_shared/lib/post-message'
import { Layout } from '../components'
import CONSENTS_QUERY from '../queries/pages/Consents'
import { REVOKE_CONSENT, GIVE_CONSENT } from '../queries/mutations/consents'
import useNativeState from 'hooks/nativeState'

export const routeProps: RouteProps = { path: '/min-side/samtykker', exact: true }

const consentTypes: {
  type: Consent['type'];
  title: string;
}[] = [
  {
    type: 'marketing',
    title: 'Nyheter og tilbud fra Foodsteps på epost',
  },
  // {
  //   type: 'push',
  //   title: 'Push-varslinger fra Foodsteps til din mobiltelefon',
  // },
]

export default function Data({ applyColors }: InferProps<typeof Data.propTypes>): JSX.Element {
  const [giveConsent] = useMutation(GIVE_CONSENT)
  const [revokeConsent] = useMutation(REVOKE_CONSENT)
  const { getStateValue } = useNativeState()

  const client = useApolloClient()

  const { loading, data }: QueryResult<{
    me?: {
      node?: {
        id: User['id']
        consents: User['consents'];
      };
    };
  }> = useQuery(CONSENTS_QUERY, { fetchPolicy: 'network-only' })

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'data')
  }

  const toggleConsentHandler = (type: Consent['type']) => (): void => {
    const consent: Consent|undefined = find(get(data, 'me.node.consents'), { type })

    const mutation = consent ? revokeConsent : giveConsent

    mutation({
      variables: { type },
      refetchQueries: [{ query: CONSENTS_QUERY }],
    })
  }

  const togglePushHandler = () => {
    const consent = getStateValue('hasNotificationPermission') && !getStateValue('isPushDisabled')

    if (!consent) {
      postMessage({ type: 'requestPush' })

      const i = setInterval(() => {
        client.refetchQueries({ include: [CONSENTS_QUERY] })
      }, 1000)

      setTimeout(() => clearInterval(i), 6000)
      return
    }

    postMessage({ type: 'disablePush' })
  }

  return (
    <Layout
      title="Dine samtykker"
      header={{
        back: { name: 'Dine data', to: '/min-side/data' },
      }}
    >
      <Article.SubTitle uppercase>Dine samtykker</Article.SubTitle>
      <Article.Text>Nedenfor ser du samtykkene du kan gi til foodsteps. De du har gitt er huket av. Trykk i boksen ved siden av samtykket for å legge til eller ta bort samtykker.</Article.Text>

      {loading ? <Loader /> : null}

      {!loading ? <ul>
        {consentTypes.map(({ type, title }) => {
          const consent = find(get(data, 'me.node.consents'), { type })

          return (
            <li key={type}>
              <Checkbox checked={!!consent} onChange={toggleConsentHandler(type)}>
                {title}
                {consent ? ` (gitt ${format(new Date(consent.timestamp), 'dd.MM.yyyy')})` : null}
              </Checkbox>
            </li>
          )
        })}

        {data?.me?.node?.id === '21' ? <Checkbox
          checked={getStateValue('hasNotificationPermission') && !getStateValue('isPushDisabled')}
          onChange={togglePushHandler}
        >
          Push-varslinger fra Foodsteps til din mobiltelefon
        </Checkbox> : null}
      </ul> : null}

    </Layout>
  )
}

Data.propTypes = {
  applyColors: T.bool.isRequired,
}
