import gql from 'graphql-tag'

export const GIVE_CONSENT = gql`
  mutation GiveConsent($type: ConsentType!) {
    giveConsent(type: $type)
  }
`

export const REVOKE_CONSENT = gql`
  mutation RevokeConsent($type: ConsentType!) {
    revokeConsent(type: $type)
  }
`
