/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import React, { useRef, useEffect, useState } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useHistory } from 'react-router-dom'
import { get, throttle } from 'lodash'

import { AspectRatioImage } from '../../_shared/components'
import setColors from '../../lib/setColors'
import getColor from '../../_shared/lib/color'

const bem = new BEMHelper('week-menu-entry')

export default function WeekMenuEntry({ recipes, slug, promoted, year, week }) {
  const imagesRef = useRef()
  const [active, setActive] = useState(0)
  const history = useHistory()
  const from = history.location.pathname
  const recipesCount = get(recipes, 'count', 0)
  const images = (get(recipes, 'edges', []) || []).map(({ node: { image } }) => image)

  useEffect(() => {
    if (imagesRef.current) {
      imagesRef.current.addEventListener('scroll', handleScroll)

      return () => imagesRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = throttle(() => {
    const index = Math.round((imagesRef.current.scrollLeft / imagesRef.current.scrollWidth) * images.length)

    setActive(index)

    if (promoted) {
      const activeRecipe = get(recipes, 'edges', [])[index]
      const palette = get(activeRecipe, 'node.image.asset.metadata.palette', null)
      const color = get(activeRecipe, 'node.color', null)
      const colors = getColor(palette, color)
      setColors(colors, `weekmenu/${index}`)
    }
  }, 300)

  if (!images.length) {
    return null
  }

  return (
    <Link
      to={{
        pathname: `/uke/${slug}`,
        state: { from },
      }}
      {...bem('')}
    >
      <div {...bem('images')} ref={imagesRef}>
        <div {...bem('image-wrapper')}>
          {images.map((image, index) => (
            <AspectRatioImage
              key={image.url}
              aspect="3/4"
              {...bem('image')}
              src={image.url}
              alt=""
              lqip={image.lqip}
              noLazyLoad={index > 0}
            />
          ))}
        </div>
      </div>
      <nav {...bem('indicators')}>
        <ol {...bem('indicator-wrapper')}>
          {images.map((image, index) => (
            <li {...bem('indicator', { active: index === active })} key={image.url} />
          ))}
        </ol>
      </nav>

      <h2 {...bem('name')}>Ukesmeny for uke {week}</h2>
      <p {...bem('meta')}>{recipesCount} oppskrifter</p>
    </Link>
  )
}

WeekMenuEntry.propTypes = {
  slug: T.string.isRequired,
  recipes: T.object.isRequired,
  year: T.string,
  week: T.string,
  promoted: T.bool,
}
