import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import { Link, useHistory } from 'react-router-dom'

import { breakLongWord } from '../../lib/string'
import { minutesToSentence } from '../../lib/utils'
import { Icon, AspectRatioImage } from '../../_shared/components'

const bem = new BEMHelper('recipe-list')

export default function RecipeList({
  recipes,
  title,
  type,
  animate,
  titles,
  removeFavorite,
}) {
  const SubTitle = title ? 'h3' : 'h2'
  const history = useHistory()

  const list = get(recipes, 'edges', recipes) // TODO: Hack since typescript doesn't let me create the right shape

  return (
    <section {...bem('', { [type]: type })}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      <nav {...bem('list')}>
        {list.map(({ node: { id, title, duration, slug, image } }, index) => {
          const superTitle = titles && titles[index]

          return (
            <Link
              to={{
                pathname: `/oppskrifter/${slug}`,
                state: {
                  from: history.location.pathname,
                  data: { title, duration, image },
                },
              }}
              {...bem('item', { animate })}
              key={id}
            >
              {removeFavorite && (
                <button
                  type="button"
                  {...bem('remove')}
                  onClick={(event) => {
                    event.preventDefault()
                    removeFavorite(id)
                  }}
                >
                  <Icon icon="heartFilled" size="small" {...bem('heart')} />
                </button>
              )}
              {superTitle && (
                <strong {...bem('super-title')}>{superTitle}</strong>
              )}
              {image && (
                <AspectRatioImage
                  aspect="3/4"
                  {...bem('image')}
                  src={image.url}
                  alt=""
                  lqip={image && image.lqip}
                />
              )}
              <div {...bem('content')}>
                <SubTitle {...bem('name')}>{breakLongWord(title, 12)}</SubTitle>

                <span {...bem('meta')}>
                  <Icon icon="time" size="small" {...bem('icon')} />
                  {minutesToSentence(duration) || '—'}
                </span>
              </div>
            </Link>
          )
        })}
      </nav>
    </section>
  )
}

RecipeList.propTypes = {
  title: T.string,
  type: T.string,
  animate: T.bool,
  tight: T.bool,
  titles: T.array,
  recipes: T.oneOfType([
    T.shape({
      edges: T.arrayOf(
        T.shape({
          node: T.object.isRequired,
        }),
      ),
    }),
    T.array,
  ]).isRequired,
  removeFavorite: T.func,
}
