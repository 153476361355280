import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('beverage')

export default function Beverage({ items }) {
  return (
    <ul {...bem('')}>
      {items.map(({ node: { id, title, content, url } }) => (
        <li {...bem('item')} key={id}>
          <h3 {...bem('title')}>{title}</h3>
          <p {...bem('content')}>{content}</p>
        </li>
      ))}
    </ul>
  )
}

Beverage.propTypes = {
  items: T.array.isRequired,
}
