import './index.scss'

import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('heart')

export default function Heart({ checked }) {
  const [disableAnimation, setDisableAnimation] = useState(true)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    let _timer
    if (!disableAnimation) {
      setAnimate(true)
    }

    if (disableAnimation) {
      _timer = setTimeout(() => {
        setDisableAnimation(false)
      }, 500)
    }

    return () => clearTimeout(_timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  return (
    <span {...bem('', { animate: animate && checked })}>
      <svg width="30" height="28" viewBox="0 0 30 28">
        <path
          {...bem('shape', { fill: checked })}
          d="M13.37 26.01L6.59999 18.3C5.17999 16.69 5.18999 14.28 6.60999 12.67L6.61999 12.66C8.31999 10.73 11.33 10.74 13.02 12.68L15 14.95L16.99 12.68C18.68 10.74 21.69 10.73 23.39 12.66L23.4 12.67C24.82 14.28 24.82 16.69 23.41 18.3L16.64 26.01C15.77 26.99 14.23 26.99 13.37 26.01Z"
        />
        {checked && animate && (
          <g>
            <path {...bem('shape', 'line')} d="M4.43 9.41L1 5.5" />
            <path {...bem('shape', 'line')} d="M15 6.73V1.5" />
            <path {...bem('shape', 'line')} d="M25.57 9.41L29 5.5" />
          </g>
        )}
      </svg>

    </span>
  )
}

Heart.propTypes = {
  checked: T.bool,
}
