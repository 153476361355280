import './index.scss'

import React, { useContext, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'

import trackCustomEvent from '../../helpers/track-custom-event'
import postMessage from '../../_shared/lib/post-message'
import useIsSignedIn from '../../hooks/signed-in'
import useIsNative from '../../hooks/isNative'
import { OfferingsContext } from '../../services/apollo'
import { Icon, Button, Logo } from '../../_shared/components'

enum INTRO_ELIGIBILITY_STATUS {
  /**
   * RevenueCat doesn't have enough information to determine eligibility.
   */
  INTRO_ELIGIBILITY_STATUS_UNKNOWN = 0,
  /**
   * The user is not eligible for a free trial or intro pricing for this product.
   */
  INTRO_ELIGIBILITY_STATUS_INELIGIBLE = 1,
  /**
   * The user is eligible for a free trial or intro pricing for this product.
   */
  INTRO_ELIGIBILITY_STATUS_ELIGIBLE = 2,
}

const bem = new BEMHelper('paywall')

const formatPrice = (price: string): string => {
  const number = price.split(',00').join('').replace(/^\D+/g, '')

  if (Number(number)) {
    return `${number},-`
  }

  return price
}

export default function Paywall({
  title,
}: T.InferProps<typeof Paywall.propTypes>): JSX.Element {
  const { signedIn } = useIsSignedIn()

  const isNative = useIsNative()
  const offerings = useContext(OfferingsContext) || {}

  useEffect(() => {
    // Ask the native layer to send updated list of in-app purchase offerings
    postMessage({ type: 'updateOfferings' })
  }, [])

  function getShortPeriodName(): string {
    switch (get(offerings, 'monthly.packageType')) {
      case 'MONTHLY':
        return 'i måneden'
      case 'WEEKLY':
        return 'uke'
      case 'YEARLY':
        return 'år'
      default:
        return get(offerings, 'monthly.packageType')
    }
  }

  function getIntroDescription(): string | null {
    const map: {
      DAY: string[]
      WEEK: string[]
      MONTH: string[]
      YEAR: string[]
    } = {
      DAY: ['dagen', 'dagene'],
      WEEK: ['uka', 'ukene'],
      YEAR: ['året', 'årene'],
      MONTH: ['måned', 'månedene'],
    }

    const periods = get(
      offerings,
      'monthly.product.introPrice.periodNumberOfUnits',
    )
    const periodUnit = get(offerings, 'monthly.product.introPrice.periodUnit')

    if (!periods || !periodUnit) {
      return null
    }

    if (periods === 1) {
      return `Første ${get(map, [periodUnit, 0])} gratis.`
    }

    return `Første ${periods} ${get(map, [periodUnit, 1])} gratis.`
  }

  function buyNative(): void {
    postMessage({
      type: 'buy',
      identifier: get(offerings, 'monthly.offeringIdentifier'),
    })
    trackCustomEvent(
      'Buy intent',
      'Click on buy',
      get(offerings, 'monthly.product.title'),
      get(offerings, 'monthly.product.price_string'),
    )
  }

  const showOfferings =
    get(offerings, [
      'introEligiblities',
      get(offerings, 'monthly.product.identifier'),
      'status',
    ]) === INTRO_ELIGIBILITY_STATUS.INTRO_ELIGIBILITY_STATUS_ELIGIBLE &&
    get(offerings, 'monthly.product.introPrice')

  return (
    <div {...bem('')}>
      {title && <h1 {...bem('title')}>{title}</h1>}

      {get(offerings, 'monthly.product', false) && (
        <div {...bem('box')}>
          <Logo {...bem('logo')} />
          <strong {...bem('price')}>
            {formatPrice(get(offerings, 'monthly.product.price_string'))}{' '}
          </strong>
          <div {...bem('monthly')}>{getShortPeriodName()}</div>

          {/** SHOW INTRO OFFER IF ELIGIBLE AND AN OFFER EXIST */}
          {showOfferings && (
            <div {...bem('offer')}>{getIntroDescription()}</div>
          )}

          {get(offerings, 'monthly.product.identifier').match(/^full/) ? (
            <ul {...bem('bullets')}>
              <li {...bem('item')}>
                <Icon icon="check" size="small" {...bem('icon')} />
                Alle oppskrifter og kurs
              </li>
              <li {...bem('item')}>
                <Icon icon="check" size="small" {...bem('icon')} />
                Ukesmeny med handleliste
              </li>
              <li {...bem('item')}>
                <Icon icon="check" size="small" {...bem('icon')} />
                Praktiske videokurs
              </li>
            </ul>
          ) : (
            <p>{get(offerings, 'monthly.product.description')}</p>
          )}

          <Button
            {...bem('buy')}
            {...(isNative ? { onClick: buyNative } : { to: '/buy' })}
            primary
            full
            spaceless
          >
            {showOfferings ? 'Prøv gratis' : 'Kjøp'}
          </Button>
        </div>
      )}

      {!signedIn && (
        <div {...bem('sign-in')}>
          <p>Er du abonnent?</p>
          <Button
            small
            spaceless
            primary
            {...(isNative
              ? {
                  onClick: (): void => postMessage({ type: 'login' }),
                }
              : {
                  to: '/login',
                })}
            {...bem('login')}
          >
            Logg inn
          </Button>
        </div>
      )}
    </div>
  )
}

Paywall.propTypes = {
  title: T.string,
}
