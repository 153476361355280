/* eslint-disable complexity */
import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import {
  Container,
  Input,
  Transition,
  Icon,
  Button,
  Checkbox,
  Select,
} from '../'

const bem = new BEMHelper('form')

export default function Form({
  steps,
  step,
  setStep,
  navigation,
  handleBack = () => {},
}) {
  const currentStep = steps[step || Object.keys(steps)[0]]

  if (!currentStep) {
    console.warn('No current step', steps, step)
    return null
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (currentStep.onSubmit) {
      currentStep.onSubmit(event)
    }
  }

  const hasSubmit = currentStep.submit && currentStep.onSubmit
  const submitProps = hasSubmit
    ? { onSubmit: handleSubmit, noValidate: true }
    : {}
  const submitButtonProps = currentStep.disclaimer
    ? { 'aria-describedby': `disclaimer-${step}` }
    : {}

  return (
    <div {...bem('', { navigation })}>
      {navigation && (
        <Container node="nav" {...bem('navigation')}>
          {currentStep.close ? (
            <button
              type="button"
              {...bem('close')}
              onClick={() => {
                handleBack()
              }}
              aria-label="Lukk"
            >
              <Icon icon="close" {...bem('close-icon')} />
            </button>
          ) : (
            <button
              type="button"
              {...bem('back')}
              onClick={() => {
                if (currentStep.backTo) {
                  setStep && setStep(currentStep.backTo)
                } else {
                  handleBack()
                }
              }}
            >
              <Icon icon="chevron" direction="left" {...bem('back-icon')} />
              Tilbake
            </button>
          )}
        </Container>
      )}

      <div {...bem('wrapper')}>
        <Transition {...bem('step')} updateKey={step || 'no-key'}>
          {currentStep.title && <h1 {...bem('title')}>{currentStep.title}</h1>}
          {currentStep.preamble && (
            <p {...bem('preamble')}>{currentStep.preamble}</p>
          )}

          <Container
            node={hasSubmit ? 'form' : 'div'}
            size="xsmall"
            {...bem('content')}
            {...submitProps}
            key={step}
          >
            {currentStep.items &&
              currentStep.items.map(({ item, id, ...props }, index) => {
                switch (item) {
                  case 'button':
                    return (
                      <Button
                        key={id || index}
                        {...props}
                        {...bem('item', item)}
                      />
                    )

                  case 'input':
                    return (
                      <Input
                        key={id || index}
                        required
                        {...props}
                        id={id}
                        {...bem('item', item)}
                      />
                    )

                  case 'checkbox':
                    return (
                      <Checkbox
                        key={id || index}
                        {...props}
                        id={id}
                        {...bem('item', item)}
                      />
                    )

                  case 'select':
                    return (
                      <Select
                        key={id || index}
                        {...props}
                        id={id}
                        {...bem('item', item)}
                      />
                    )

                  case 'custom':
                    return (
                      <div {...bem('item', item)} key={id || index}>
                        {props.children}
                      </div>
                    )

                  case 'text':
                    return (
                      <p
                        key={id || index}
                        {...bem('item', {
                          [item]: item,
                          small: props.small,
                          tight: props.tight,
                          left: props.left,
                        })}
                      >
                        {props.value}
                      </p>
                    )

                  case 'warning':
                    if (!props.value) {
                      return null
                    }

                    return (
                      <p key={id || index} {...bem('item', { [item]: item })}>
                        {props.value}
                      </p>
                    )

                  default:
                    return null
                }
              })}

            {currentStep.submit && (
              <Button
                type="submit"
                primary
                {...bem('submit')}
                {...currentStep.submitProps}
                {...submitButtonProps}
              >
                {currentStep.submit}
              </Button>
            )}
          </Container>
          {currentStep.disclaimer && (
            <p {...bem('disclaimer')} id={`disclaimer-${step}`}>
              {currentStep.disclaimer}
            </p>
          )}
        </Transition>
      </div>
    </div>
  )
}

Form.propTypes = {
  step: T.string,
  value: T.string,
  children: T.any,
  steps: T.object.isRequired,
  setStep: T.func,
  navigation: T.bool,
  small: T.bool,
  tight: T.bool,
  left: T.bool,
  handleBack: T.func,
}
