/* eslint-disable react-hooks/exhaustive-deps */

import './index.scss'

import React, { useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link, useHistory } from 'react-router-dom'

import useIsSignedIn from '../../hooks/signed-in'
import useIsNative from '../../hooks/isNative'
import usePrevious from '../../hooks/previous'
import useRole from '../../hooks/role'
import postMessage from '../../_shared/lib/post-message'
import { Icon, Portal, Transition } from '../../_shared/components'

const ROOT_ELEMENT = document.querySelector('#root')

const bem = new BEMHelper('menu')

export default function Menu({ show, onClose, isLoggedIn }) {
  const history = useHistory()
  const prevShow = usePrevious(show)
  const isNative = useIsNative()
  const { hasRole: isAdmin } = useRole('admin')
  const { loading, signedIn } = useIsSignedIn()

  // Listen for route change and close if menu is open
  useEffect(
    () =>
      history.listen(() => {
        if (show) {
          onClose()
        }
      }),
    [history, show],
  )

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open')
    } else if (prevShow) {
      document.body.classList.remove('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [show])

  const links = [
    ...(!isLoggedIn ? [{
      name: 'Logg inn',
      ...(isNative ? {
        to: '/login',
        onClick: () => postMessage({ type: 'login' }),
      } : {
        to: '/login',
      }),
    }] : []),
    ...(isAdmin ? [{ name: 'Admin', to: '/admin' }] : []),
    ...(!loading && signedIn ? [{ name: 'Min side', to: '/min-side' }] : []),
    { name: 'Utstyr', to: '/utstyr' },
    { name: 'Råvarer', to: '/ravarer' },
  ]

  return (
    <Portal insertTo={ROOT_ELEMENT}>
      <Transition updateKey={show.toString()} {...bem('')}>
        {show && (
          <nav {...bem('content')}>
            {links.map(({ name, to, onClick }) => (
              <Link to={to} key={to} onClick={onClick} {...bem('link')}>
                {name}
              </Link>
            ))}

            <button type="button" {...bem('close')} onClick={onClose} aria-label="Lukk meny">
              <Icon icon="close" {...bem('icon')} />
            </button>
          </nav>
        )}
      </Transition>
    </Portal>
  )
}

Menu.propTypes = {
  show: T.bool,
  onClose: T.func.isRequired,
}
