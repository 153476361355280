import './index.scss'

import React, { useState } from 'react'
import T from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import BEMHelper from 'react-bem-helper'

import useGestures from '../../hooks/useGestures'
import useIsSignedIn from '../../hooks/signed-in'
import { Menu, Heart } from '..'
import { Icon, Transition, Button } from '../../_shared/components'

const bem = new BEMHelper('header')

export default function Header({ back, search, menu, bookmark, absolute, show = true }) {
  const { loading, signedIn } = useIsSignedIn()
  const [preventSwipe, setPreventSwipe] = useState(false)
  const [menuExpanded, toggleMenu] = useState(false)
  const history = useHistory()

  const handleBack = () => {
    if (back && back.to) {
      history.push(back.to)
    } else {
      history.goBack()
    }
  }

  if (back) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useGestures({ current: document.body }, {
      touchStart: ({ positions }) => {
        if (positions.startX < 50) {
          const page = document.querySelector('.page')
          if (page) {
            page.classList.add('page--swipe')
            page.classList.remove('page--release')
            setPreventSwipe(false)
          }
        }
      },
      touchMove: ({ positions, event }) => {
        if (
          positions.startX < 50 &&
          Math.abs(positions.diffX) > 10 &&
          !preventSwipe
        ) {
          const page = document.querySelector('.page')
          event.preventDefault()

          if (Math.abs(positions.diffX) > Math.abs(positions.diffY)) {
            if (page) {
              page.classList.add('page--swipe')
              page.classList.remove('page--release')
              page.style.transform = `translateX(${Math.max(-(positions.diffX / 4), 0)}px)`
            }
          } else {
            setPreventSwipe(true)
            page.classList.remove('page--swipe')
            page.classList.add('page--release')
            page.style.transform = null
          }
        }
      },
      touchEnd: ({ positions }) => {
        if (preventSwipe) {
          setPreventSwipe(false)
          return
        }

        if (positions.startX < 50) {
          const page = document.querySelector('.page')
          page && page.classList.add('page--release')

          if (positions.endX > window.innerWidth / 5) {
            page.style.transform = `translateX(${window.innerWidth}px)`
            setTimeout(handleBack, 180)
          } else {
            page.style.transform = null
          }

          setTimeout(() => {
            if (page) {
              page.classList.remove('page--swipe')
              page.classList.remove('page--release')
            }
          }, 500)
        }
      },
    }, false)
  }

  const showMenu = () => {
    toggleMenu(true)
  }

  const closeMenu = () => {
    toggleMenu(false)
  }

  const showLogin = !loading && !signedIn
  const isOnSignupPage = history.location.pathname === '/prov'

  return (
    <>
      <Transition enter={750} exit={500} {...bem('', { absolute })} updateKey={show.toString()}>
        {show && (
          <nav {...bem('content')}>
            {menu && !back && (
              <button type="button" {...bem('action')} onClick={showMenu}>
                <Icon icon="dots" {...bem('icon', 'menu')} title="Meny" />
              </button>
            )}
            {back && (
              <button type="button" onClick={handleBack} {...bem('action')}>
                <Icon icon="chevron" {...bem('icon', 'back')} direction="left" title={back.name || 'Tilbake'} />
              </button>
            )}

            {showLogin && !isOnSignupPage && (
              <Button
                {...bem('login')}
                label="Prøv gratis"
                primary
                small
                to={{
                  pathname: '/prov',
                  state: { from: history.location.pathname },
                }}
              />
            )}

            {bookmark && (
              <button
                type="button"
                {...bem('action', { right: true })}
                onClick={bookmark.toggle}
              >
                <Heart
                  checked={bookmark.isBookmarked}
                  title={bookmark.isBookmarked ? 'Fjern som favoritter' : 'Lagre som favoritt'}
                />
              </button>
            )}

            {search && (
              <Link
                to={{
                  pathname: '/sok',
                  state: {
                    from: history.location.pathname,
                  },
                }}
                {...bem('action', 'right')}
              >
                <Icon icon="search" {...bem('icon', 'search')} title="Søk" />
              </Link>
            )}
          </nav>
        )}
      </Transition>
      {menu && <Menu show={menuExpanded} onClose={closeMenu} isLoggedIn={!showLogin} />}
    </>
  )
}

Header.propTypes = {
  back: T.object,
  menu: T.bool,
  bookmark: T.shape({
    toggle: T.func.isRequired,
    isBookmarked: T.bool,
  }),
  search: T.bool,
  absolute: T.bool,
  show: T.bool,
}
