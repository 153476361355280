import React, { useState, useEffect } from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import copy from 'copy-text-to-clipboard'
import { Link } from 'react-router-dom'
import { get, orderBy } from 'lodash'

// import useNative from '../hooks/isNative'
import { Layout, Portions, Halleluja, GroceryList, Actions, Message } from '../components'
import setColors from '../lib/setColors'
import trackCustomEvent from '../helpers/track-custom-event'
import { lowercaseFirst, uppercaseFirst } from '../lib/utils'
import GROCERY from '../queries/pages/Grocery'
import { EMPTY_GROCERY_LIST } from '../queries/mutations/empty-grocery-list'
import { TOGGLE_GROCERY_ITEM } from '../queries/mutations/toggle-grocery-item'
import { COLORS } from '../_shared/lib/color'
import { RouteProps, GroceryList as GroceryListType, GroceryListItem } from '../types'

function stringifyGroceryList(
  departments: GroceryListType['departments'],
  quantity: 'two'|'four'|'six'|'eight',
): string {
  const lines: string[] = []

  departments
    .filter(department => department.ingredients.length)
    .forEach(department => {
      lines.push(department.department.title)
      department.ingredients.forEach(({ ingredient }) => {
        if (ingredient.quantity && ingredient.quantity[quantity]) {
          lines.push(` - ${ingredient.quantity[quantity]} ${lowercaseFirst(ingredient.title)}`)
        } else {
          lines.push(` - ${uppercaseFirst(ingredient.title)}`)
        }
      })
      lines.push('')
    })

  return lines.join('\n')
}

export const routeProps: RouteProps = { path: '/handleliste', exact: true }

const EMPTY_DELAY = 1100
export default function Grocery({ applyColors }: InferProps<typeof Grocery.propTypes>): JSX.Element {
  const [persons, setPersons] = useState<'two'|'four'|'six'|'eight'>('two')
  const [copyMessage, setCopyMessage] = useState(false)
  const [emptying, setEmptying] = useState(false)

  const {
    loading,
    error,
    data,
    data: {
      groceryList: {
        departments = [],
      },
    } = { groceryList: { departments: [] } },
  } = useQuery<{groceryList: GroceryListType}>(GROCERY)

  const [toggleItem] = useMutation<{
    toggleGroceryListItem: {
      __typename: string;
      id: GroceryListItem['id'];
      checked: GroceryListItem['checked'];
    };
  }>(TOGGLE_GROCERY_ITEM)

  const [emptyGroceryList] = useMutation(EMPTY_GROCERY_LIST)

  const trackGroceryEvent = (event: string | null = null): void => {
    trackCustomEvent('Grocery list', event, 'List view')
  }

  function handleToggleItem(item: GroceryListItem): void {
    !item.checked && trackGroceryEvent('Checked grocery item')
    toggleItem({
      variables: { id: item.id },
      optimisticResponse: {
        toggleGroceryListItem: {
          __typename: 'GroceryListItem',
          id: item.id,
          checked: !item.checked,
        },
      },
    })
  }

  useEffect(() => {
    let _timer: any
    if (emptying) {
      clearTimeout(_timer)
      _timer = setTimeout(() => {
        emptyGroceryList()
      }, EMPTY_DELAY)
    }

    return (): void => {
      clearTimeout(_timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptying])

  const handleEmptyGroceryList = (): void => {
    setEmptying(true)
    trackGroceryEvent('Empty grocery list')
  }

  if (error) { throw error }

  // Set colors
  if (applyColors) {
    setColors(COLORS.green, 'grocery')
  }

  const hasContent = departments.length > 0
  const isIos = navigator.userAgent.toLowerCase().match(/iphone|ipad/) !== null

  const weekMenus = get(data, 'weekMenus.edges', [])
  const week = orderBy(
    weekMenus.map(({ node }: any) => ({
      value: node.slug,
    })),
    'value',
    ['asc'],
  )[0]

  const weekMenuLink = week ? `/uke/${week.value}` : '/uke/1'

  return (
    <Layout title="Handleliste">
      {hasContent && (
        <Portions
          value={persons}
          options={[['two', 2], ['four', 4], ['six', 6], ['eight', 8]]}
          onChange={setPersons}
          center={true}
        />
      )}

      {hasContent && (
        <>
          <GroceryList
            departments={departments}
            quantity={persons}
            toggleItem={handleToggleItem}
          />

          <Actions
            actions={[
              {
                key: 'empty',
                icon: 'empty',
                label: 'Tøm handleliste',
                onClick: handleEmptyGroceryList,
              },
              ...(isIos ? [{
                key: 'sms',
                icon: 'sms',
                label: 'Send som tekstmelding',
                onClick: (): void => trackGroceryEvent('Send list by sms'),
                to: `sms:?&body=${encodeURIComponent(stringifyGroceryList(departments, persons))}`,
              }] : []),
              {
                key: 'email',
                icon: 'email',
                label: 'Send på e-post',
                onClick: (): void => trackGroceryEvent('Send list by e-mail'),
                to: `mailto:?body=${encodeURIComponent(stringifyGroceryList(departments, persons))}`,
              },
              {
                key: 'copy',
                icon: 'copy',
                label: 'Kopier',
                onClick: (): void => {
                  setCopyMessage(true)
                  copy(stringifyGroceryList(departments, persons))
                  trackGroceryEvent('Copy crocery list')
                },
              },
            ]}
          />
        </>
      )}
      {!hasContent && !loading && (
        <Halleluja
          type="grocery"
          text={(
            <>
              Fra <Link to={weekMenuLink}>ukesmenyene</Link> kan du lage din egen, smarte handleliste. Når du handler er det bare å stryke ut varene underveis.
            </>
          )}
        />
      )}
      {copyMessage && <Message message="Listen er kopiert" icon="copy" />}
      {emptying && <Message message="Tømmer listen..." icon="empty" />}
    </Layout>
  )
}

Grocery.propTypes = {
  applyColors: T.bool.isRequired,
}
