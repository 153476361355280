import gql from 'graphql-tag'

export default gql`
  mutation UpdateLectureProgress($lectureId: ID! $progress: Int!) {
    updateLectureProgress(id:$lectureId progress:$progress) {
      success
      course {
        node {
          id
          progress
        }
      }
      lecture {
        node {
          id
          progress
          chapters {
            id
            progress
          }
        }
      }
    }
  }
`
