import gql from 'graphql-tag'

import GroceryListFragment from '../fragments/grocery-list'

export const ADD_MENU_TO_GROCERY_LIST = gql`
  mutation AddMenuToGroceryList($weekMenuSlug: String!) {
    addMenuToGroceryList(weekMenuSlug: $weekMenuSlug) {
      success
      groceryList {
        ...GroceryList
      }
    }
  }

  ${GroceryListFragment}
`
