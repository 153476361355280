import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

// import { Button, Icon } from '../../_shared/components'

const bem = new BEMHelper('alphabet-list')

export default function AlphabetList({
  children,
}: InferProps<typeof AlphabetList.propTypes>): JSX.Element {
  return (
    <ol {...bem('')}>
      {children}
    </ol>
  )
}

// eslint-disable-next-line react/display-name
AlphabetList.Group = ({ letter, children }: InferProps<typeof AlphabetList.propTypes>): JSX.Element => {
  return (
    <li {...bem('item')}>
      <h2 {...bem('letter')}>{letter}</h2>
      <div {...bem('content')}>{children}</div>
    </li>
  )
}

AlphabetList.propTypes = {
  letter: T.string,
  children: T.any.isRequired,
}
