import Vtt from 'vtt.js'
import once from 'once'
import { inRange } from 'lodash'

const windowObject = typeof window !== 'undefined' ? window : {
  VTTCue: Vtt.VTTCue,
  VTTRegion: Vtt.VTTRegion,
}

export function partitionCues(cues, partitionSize) {
  const partitions = []

  const lastIndex = Math.ceil(
    cues[cues.length - 1].endTime / partitionSize,
  )

  for (let i = 0; i < lastIndex; i++) {
    const partitionCues = cues.filter(({ startTime, endTime }) => {
      // Do not include if startTime is after end of partition
      if (startTime >= (i + 1) * partitionSize) {
        return false
      }

      // Do not include if end time is before start of partition
      if (endTime < i * partitionSize) {
        return false
      }

      return true
    })

    partitions.push(partitionCues)
  }

  return partitions
}

export function getCue(partitionedCues, partitionSize, time) {
  const lookupIndex = Math.floor(time / partitionSize)
  const partition = partitionedCues[lookupIndex]

  if (!partition) { return null }

  return partition.find(
    ({ startTime, endTime }) => inRange(time, startTime, endTime),
  ) || null
}

export function parse(vttText, partitionSize, done) {
  const callback = once(done)

  if (typeof navigator === 'undefined') {
    global.navigator = { userAgent: '' }
  }

  const cues = []
  const parser = new Vtt.WebVTT.Parser(windowObject, Vtt.WebVTT.StringDecoder())

  parser.oncue = function onCue(cue) {
    cues.push({
      startTime: cue.startTime,
      endTime: cue.endTime,
      text: `${cue.text}`
        .split("\n") // eslint-disable-line quotes
        .map(s => s.trim()),
    })
  }

  parser.onflush = function onFlush() {
    const partitionedCues = partitionCues(cues, partitionSize)

    callback(null, {
      cues,
      getCue: time => getCue(partitionedCues, partitionSize, time),
    })
  }

  parser.onparsingerror = function onParsingError(error) {
    callback(error)
  }

  // Workaround for safari VTTCue implementation bug
  // https://github.com/mozilla/vtt.js/issues/364
  const oldVTTCue = global.VTTCue
  try {
    global.VTTCue = Vtt.VTTCue

    parser.parse(vttText)
    parser.flush()
  } finally {
    global.VTTCue = oldVTTCue
  }
}

export function vtt(vttText, partitionSize = 5) {
  return new Promise((resolve, reject) => {
    parse(vttText, partitionSize, (err, res) => {
      if (err) { return reject(err) }
      resolve(res)
    })
  })
}

export function srtToVtt(srt) {
  const transformed = `WEBVTT FILE

${srt
  .replace(/\{\\([ibu])\}/g, '</$1>')
  .replace(/\{\\([ibu])1\}/g, '<$1>')
  .replace(/\{([ibu])\}/g, '<$1>')
  .replace(/\{\/([ibu])\}/g, '</$1>')
  .replace(/(\d\d:\d\d:\d\d),(\d\d\d)/g, '$1.$2')
  .concat('\r\n\r\n')
}`

  const tmp = document.createElement('DIV')
  tmp.innerHTML = transformed

  return tmp.textContent || tmp.innerText || ''
}

export default async function caption(srtText) {
  return vtt(srtToVtt(srtText))
}
