/* eslint-disable complexity */

import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useParams, useLocation } from 'react-router'
import { useQuery, useMutation, QueryResult } from '@apollo/client'

import { Button } from '../_shared/components'
import getColor from '../_shared/lib/color'
import trackCustomEvent from '../helpers/track-custom-event'
import FAVORITES_QUERY from '../queries/pages/Favourites'
import RECIPE_QUERY from '../queries/pages/Recipe'
import {
  ADD_FAVORITE_RECIPE,
  REMOVE_FAVORITE_RECIPE,
} from '../queries/mutations/favorite'
import extractIngredients from '../lib/extractIngredients'
import setColors from '../lib/setColors'
import { minutesToSentence } from '../lib/utils'
import {
  Layout,
  Info,
  Ingredients,
  Portions,
  Preamble,
  Beverage,
  EquipmentList,
  Paywall,
} from '../components'
import { Recipe, Equipment, BeverageTip } from '../types'
import Error404 from './404'

const showList = ({
  edges = [],
}: {
  edges: Array<{
    node: BeverageTip | Equipment
  }>
}): boolean => {
  return edges && edges.length > 0
}

export const routeProps = { path: '/oppskrifter/:recipe', exact: true }

export default function RecipePage({
  applyColors,
}: InferProps<typeof RecipePage.propTypes>): JSX.Element {
  const location = useLocation()
  const [persons, setPersons] = useState('two')
  const [addFavorite] = useMutation(ADD_FAVORITE_RECIPE)
  const [removeFavorite] = useMutation(REMOVE_FAVORITE_RECIPE)
  const { recipe: slug } = useParams<{ recipe: string }>()
  const { pathname } = useLocation()

  const {
    loading,
    error,
    data: { recipe } = {},
  }: QueryResult<{
    recipe?: {
      node: Recipe
    }
  }> = useQuery(RECIPE_QUERY, {
    variables: { slug },
    fetchPolicy: 'network-only',
  })

  // set colors
  if (!loading && recipe && applyColors) {
    const colors = getColor(
      get(recipe, 'node.image.asset.metadata.palette'),
      get(recipe, 'node.color'),
    )
    setColors(colors, `recipe/${slug}`)
  }

  if (error) {
    throw error
  }

  if (!loading && !recipe) {
    return <Error404 applyColors title="Oppskriften finnes ikke" />
  }

  const back = get(location, 'state.from', '/oppskrifter')
  const tempData = get(location, 'state.data')

  const {
    node: {
      id = null,
      title = null,
      preamble = null,
      image = null,
      tips = null,
      steps = [],
      duration = 0,
      isFavorite = false,
      hasAccess = true,
      beverageTips = { edges: [] },
      equipment = { edges: [] },
      quantitySettings = {
        mode: 'full',
      },
    } = {},
  } = recipe || {
    node: {
      title: get(tempData, 'title'),
      // image: get(tempData, 'image'),
      duration: get(tempData, 'duration'),
    },
  }

  const ingredients = extractIngredients(steps)

  const toggleFavorite = (): void => {
    if (isFavorite) {
      removeFavorite({
        variables: { id },
        optimisticResponse: {
          removeFavoriteRecipe: {
            success: true,
            __typename: 'RemoveFavoriteRecipePayload',
            recipe: {
              __typename: 'RecipesEdge',
              node: { __typename: 'Recipe', id, isFavorite: false },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent('Receipt favourite', 'Remove from favourites', title, -1)
    } else {
      addFavorite({
        variables: { id },
        optimisticResponse: {
          addFavoriteRecipe: {
            success: true,
            __typename: 'AddFavoriteRecipePayload',
            recipe: {
              __typename: 'RecipesEdge',
              node: { __typename: 'Recipe', id, isFavorite: true },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent('Receipt favourite', 'Add to favourites', title, 1)
    }
  }

  const lqip = get(image, 'asset.metadata.lqip')

  const action = {
    to: {
      pathname: `${pathname}/steps/`,
      state: { back, persons },
    },
    label: 'Lag retten',
    description: {
      icon: 'time',
      iconSize: 'small',
      content: minutesToSentence(duration),
    },
  }

  if (!hasAccess) {
    return (
      <Layout
        header={{
          back: { name: 'Oppskrifter', to: back },
          bookmark: {
            toggle: toggleFavorite,
            isBookmarked: isFavorite,
          },
        }}
      >
        <Paywall title={`For å se «${title}» må du bli abonnent:`} />
      </Layout>
    )
  }

  return (
    <Layout
      header={{
        back: { name: 'Oppskrifter', to: back },
        bookmark: {
          toggle: toggleFavorite,
          isBookmarked: isFavorite,
        },
      }}
      cover={{
        loading,
        skeleton: ['title', 'image'],
        title,
        lqip,
        image: image && image.url,
        action,
      }}
    >
      {preamble && <Preamble>{preamble}</Preamble>}
      {ingredients && (
        <Info
          renderTitle={({
            title,
            props,
          }: {
            title: string
            [propName: string]: any
          }): JSX.Element => <div {...props}>{title}</div>}
          title={
            <Portions
              value={persons}
              type={quantitySettings.mode === 'full' ? 'number' : 'text'}
              options={
                quantitySettings.mode === 'full'
                  ? [
                      ['two', 2],
                      ['four', 4],
                      ['six', 6],
                      ['eight', 8],
                    ]
                  : [
                      ['two', quantitySettings.singleHeading || 'enkel'],
                      ['four', quantitySettings.doubleHeading || 'dobbel'],
                    ]
              }
              onChange={setPersons}
            />
          }
        >
          <Ingredients items={ingredients} persons={persons} tight />
        </Info>
      )}

      {showList(beverageTips || {}) && (
        <Info title="Drikke">
          <Beverage items={beverageTips.edges} />
        </Info>
      )}
      {tips && <Info title="Tips">{tips}</Info>}
      {showList(equipment || {}) && (
        <Info title="Utstyr">
          <EquipmentList items={equipment.edges} />
        </Info>
      )}
      <Button
        primary
        full
        spaceless
        icon={isFavorite ? 'heartFilled' : 'heart'}
        onClick={toggleFavorite}
      >
        {isFavorite ? 'Favoritt' : 'Lagre som favoritt'}
      </Button>
    </Layout>
  )
}

RecipePage.propTypes = {
  applyColors: T.bool.isRequired,
}
