import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { get, orderBy } from 'lodash'
import { Link, useHistory } from 'react-router-dom'

const bem = new BEMHelper('categories')

export default function Categories({ categories, title }) {
  const history = useHistory()
  const from = history.location.pathname

  if (!categories || !categories.edges || !categories.edges.length) {
    return null
  }

  return (
    <section {...bem('')}>
      <h2 {...bem('title')}>{title}</h2>

      <nav {...bem('list')}>
        {orderBy(
          categories.edges,
          [({ node }) => get(node, 'recipes.count', 0)],
          ['desc'],
        )
          .filter(({ node }) => get(node, 'recipes.count', 0) || get(node, 'recipes.count', 0))
          .map(({ node: { id, title, slug } }) => (
            <Link
              {...bem('item')}
              to={{
                pathname: `/kategori/${slug}`,
                state: { from },
              }}
              key={id}
            >
              <h3 {...bem('name')}>{title}</h3>
            </Link>
          ))}
      </nav>
    </section>
  )
}

Categories.propTypes = {
  title: T.string.isRequired,
  categories: T.shape({
    edges: T.arrayOf(
      T.shape({
        node: T.object.isRequired,
      }),
    ),
  }).isRequired,
}
