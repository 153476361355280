import { get } from 'lodash'

export default function pm(data) {
  /**
   * This is a workaround for a weird nuance in the Java webview
   * @see https://github.com/react-native-community/react-native-webview/issues/323#issuecomment-511824940
   */
  const postMessageProxy = function(data) {
    if (!get(window, 'ReactNativeWebView.postMessage')) {
      console.log('⚠️ Not in a webview, unable to send postmessage', data)
      return
    }
    window.ReactNativeWebView.postMessage(data)
  }

  // Send postmessage event to native layer
  return postMessageProxy(JSON.stringify(data))
}
