export default {
  MuxTrack: [
    'MuxVideoTrack',
    'MuxAudioTrack'
  ],
  PageSection: [
    'WeekMenuPromotion',
    'CategoryPromotion',
    'RecipePromotion',
    'CategoryList',
    'CoursesList'
  ],
  Payment: [
    'VippsPayment',
    'InvoicePayment'
  ],
}
