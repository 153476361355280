import gql from 'graphql-tag'

import GroceryListFragment from '../fragments/grocery-list'

export default gql`
  query Grocery {
    groceryList {
      ...GroceryList
    }
    weekMenus {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
  ${GroceryListFragment}
`
