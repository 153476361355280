import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Icon } from '../../_shared/components'
import { lowercaseFirst, uppercaseFirst } from '../../lib/utils'

const bem = new BEMHelper('grocery-list')

export default function GroceryList({ departments, quantity, toggleItem }) {
  return (
    <div {...bem('')}>
      {departments.map(({ id, department: { title }, ingredients }) => (
        <section key={id} {...bem('group')}>
          <h2 {...bem('title')}>{title}</h2>

          <ul {...bem('ingredients')}>
            {ingredients.map(({ ingredient, ...item }) => (
              <li key={item.id} {...bem('item')}>
                <label {...bem('ingredient')}>
                  <input
                    {...bem('input')}
                    type="checkbox"
                    onChange={() => toggleItem(item)}
                    checked={item.checked}
                  />
                  <span {...bem('indicator')}>
                    <Icon icon="check" {...bem('icon')} />
                  </span>
                  <span {...bem('label')}>
                    {ingredient.quantity && ingredient.quantity[quantity]}
                    {' '}
                    {ingredient.quantity ? lowercaseFirst(ingredient.title) : uppercaseFirst(ingredient.title)}
                  </span>
                </label>
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  )
}

GroceryList.propTypes = {
  departments: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      department: T.shape({
        id: T.string.isRequired,
        title: T.string.isRequired,
      }).isRequired,
      ingredients: T.arrayOf(T.shape({
        id: T.string.isRequired,
        checked: T.bool,
        ingredient: T.shape({
          id: T.string.isRequired,
          title: T.string.isRequired,
          quantity: T.object,
        }).isRequired,
      }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  quantity: T.string.isRequired,
  toggleItem: T.func.isRequired,
}
