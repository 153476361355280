/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { ReactComponent as Grocery } from '../../assets/illustrations/grocery.svg'
import { ReactComponent as Favorites } from '../../assets/illustrations/favorites.svg'
import { Container } from '../../_shared/components'

const bem = new BEMHelper('halleluja')

export default function Halleluja({
  text,
  type,
}: InferProps<typeof Halleluja.propTypes>): JSX.Element {
  return (
    <Container {...bem('')}>
      {type === 'grocery' && <Grocery {...bem('illustration')} />}
      {type === 'favorites' && <Favorites {...bem('illustration')} />}
      <p {...bem('text')}>{text}</p>
    </Container>
  )
}

Halleluja.propTypes = {
  text: T.any.isRequired,
  type: T.string.isRequired,
}
