import './index.scss'

import React, { useState } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Overview } from '..'
import { Transition } from '../../_shared/components'

const bem = new BEMHelper('progress')

const MIN_PERCENTAGE = 5
const MAX_PERCENTAGE = 98 // Only for number

export default function Progress({ step, steps, ingredients, persons, goTo, loading, hideProgress }) {
  const [expanded, setExpanded] = useState(false)
  const count = steps.length
  const percentageValue = loading ? 0 : (step * (100 - MIN_PERCENTAGE)) / count
  const percentage = MIN_PERCENTAGE + percentageValue

  const toggle = () => {
    setExpanded(!expanded)
  }

  const handleGoTo = step => {
    setExpanded(false)
    goTo(step)
  }

  const countPosition = () => `translateX(${Math.min(MAX_PERCENTAGE, percentage)}vw)`

  const paths = ['0,11 28,11', '0,19 28,19']
  /* expanded
    ? ['5,5 23,23', '5,23 23,5']
    : ['0,11 28,11', '0,19 28,19']
  */

  return (
    <div {...bem('', { expanded, 'hide-progress': hideProgress })}>
      <span {...bem('wrapper')} role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
        <span {...bem('count')} style={{ transform: countPosition() }}>
          <span {...bem('number')}>{step}</span>
        </span>
        <span {...bem('bar')} style={{ transform: `scaleX(${percentage / 100})` }} />
      </span>

      <button
        {...bem('toggle', { expanded })}
        type="button"
        onClick={toggle}
        aria-label={expanded ? 'Skjul steg' : 'Vis steg'}
        aria-expanded={expanded}
      >
        <svg viewBox="0 0 28 28" {...bem('toggle-icon', { expanded })}>
          <polyline {...bem('line')} points={paths[0]} />
          <polyline {...bem('line')} points={paths[1]} />
        </svg>
      </button>

      <Transition {...bem('overview-wrapper')} updateKey={expanded ? 'hide' : 'show'}>
        {expanded && (
          <div {...bem('overview')}>
            <Overview
              steps={steps}
              ingredients={ingredients}
              persons={persons}
              goTo={handleGoTo}
            />
          </div>
        )}
      </Transition>
    </div>
  )
}

Progress.propTypes = {
  step: T.number,
  steps: T.array,
  ingredients: T.array,
  persons: T.oneOf(['two', 'four', 'six', 'eight']),
  loading: T.bool,
  hideProgress: T.bool,
  goTo: T.func.isRequired,
}
