/* eslint-disable react/display-name */
import './index.scss'

import React, { useRef, useState, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import AspectRatioImage from '../AspectRatioImage'

const bem = new BEMHelper('article')

// Make a threshold list based on a number
function buildThresholdList(steps) {
  const thresholds = []

  for (let i = 1.0; i <= steps; i++) {
    const ratio = i / steps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}

export default function Article({
  children,
  title,
  preamble,
  toc,
  image,
  action,
}) {
  const articleRef = useRef()
  const [tocList, makeTocList] = useState(null)
  const [active, setActive] = useState(null)

  useEffect(() => {
    // Make a map to keep all the intersectionRatios
    const intersections = new Map()
    let _timeout, items

    // Intersection function
    function onIntersection(entries) {
      entries.forEach(entry => {
        if (entry.target.id) {
          // Set the ratio to the map
          intersections.set(entry.target.id, entry.intersectionRatio)
        }

        // Get the first item from the map (as a default)
        const first = [...intersections.entries()][0]

        // Go through the map and find the item with the highest ratio
        const mostActive = [...intersections.entries()].reduce((res, item) => {
          if (item[1] > res[1]) {
            return item
          }

          return res
        }, first)

        setActive(mostActive[0])
      })
    }

    // Create the observer
    const observer = new IntersectionObserver(onIntersection, {
      threshold: buildThresholdList(8),
    })

    // Only apply if `toc`
    if (toc) {
      clearTimeout(_timeout)

      // A timeout to make sure everything is rendered
      _timeout = setTimeout(() => {
        items = articleRef.current.querySelectorAll(
          `.${bem('section').className}`,
        )
        items.forEach(node => observer.observe(node))

        makeTocList(
          [...items].map(node => ({
            title: node.querySelector('h2').innerText,
            id: node.getAttribute('id'),
          })),
        )
      }, 200)
    }

    // Cleanup
    return () => {
      clearTimeout(_timeout)

      if (items && items.length > 0) {
        items.forEach(node => observer.unobserve(node))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {toc && (
        <nav {...bem('toc')}>
          {tocList &&
            tocList.map(({ title, id }) => (
              <a
                href={`#${id}`}
                key={id}
                {...bem('toc-item', { active: active === id })}
              >
                {title}
              </a>
            ))}
        </nav>
      )}

      <article {...bem('')} ref={articleRef}>
        <header {...bem('header', { image })}>
          {image && <img {...bem('main-image')} src={image} alt={title} />}
          <div>
            {title && <h1 {...bem('title')}>{title}</h1>}
            {preamble && <p {...bem('preamble')}>{preamble}</p>}
            {action && <div {...bem('action')}>{action}</div>}
          </div>
        </header>
        {children}
      </article>
    </>
  )
}

Article.Text = ({ children, type }) => <p {...bem('text', type)}>{children}</p>

Article.Quote = ({ children, source }) => (
  <blockquote {...bem('quote')}>
    <p {...bem('quote-text')}>«{children}»</p>
    <cite {...bem('quote-source')}>{source}</cite>
  </blockquote>
)

Article.SubTitle = ({ children, type, uppercase }) => {
  const Node = type || 'h2'

  return (
    <Node {...bem('sub-title', { [type]: type, uppercase })}>{children}</Node>
  )
}

Article.List = ({ items, children, type }) => (
  <ul {...bem('list', type)}>
    {(items || []).map((item, index) => (
      <Article.ListItem key={index} {...bem('list-item')}>
        {item}
      </Article.ListItem>
    ))}
    {children}
  </ul>
)

Article.ListItem = ({ children }) => <li {...bem('list-item')}>{children}</li>

Article.Section = ({ id, title, children }) => (
  <section {...bem('section')} id={id}>
    <h2 {...bem('sub-title')}>{title}</h2>
    {children}
  </section>
)

Article.ImageSection = ({ images, children, type }) => {
  const double = images.length === 2
  const aspect = double ? '3/4' : null

  return (
    <section {...bem('image-section', { [type]: type, double })}>
      {images &&
        images.map(({ url, alt, ...props }) => (
          <figure {...bem('image', 'section')} key={url}>
            {aspect ? (
              <AspectRatioImage
                aspect={aspect}
                {...props}
                src={url}
                alt={alt}
              />
            ) : (
              <img src={url} alt={alt} />
            )}
            <figcaption aria-hidden {...bem('caption')}>
              {alt}
            </figcaption>
          </figure>
        ))}

      <div {...bem('content')}>{children}</div>
    </section>
  )
}

Article.Images = ({ images, type }) => {
  const tripple = images.length >= 3
  const double = images.length === 2
  const aspect = double || tripple ? '3/4' : null

  return (
    <div
      {...bem('images', {
        double,
        tripple,
        [type]: type,
      })}
    >
      {images.map(({ url, alt, ...props }) => (
        <figure {...bem('image')} key={url}>
          {aspect ? (
            <AspectRatioImage aspect={aspect} {...props} src={url} alt={alt} />
          ) : (
            <img src={url} alt={alt} />
          )}
          <figcaption aria-hidden {...bem('caption')}>
            {alt}
          </figcaption>
        </figure>
      ))}
    </div>
  )
}

Article.propTypes = {
  children: T.any,
  title: T.string,
  preamble: T.string,
  image: T.string,
  spaceless: T.bool,
  toc: T.bool,
  action: T.any,
}

Article.Text.propTypes = {
  children: T.any.isRequired,
  type: T.string,
}

Article.Quote.propTypes = {
  children: T.any.isRequired,
  source: T.string,
}

Article.SubTitle.propTypes = {
  children: T.any.isRequired,
  type: T.string,
  uppercase: T.bool,
}

Article.List.propTypes = {
  items: T.array,
  children: T.oneOfType([T.node, T.arrayOf(T.node)]),
  type: T.string,
}

Article.ListItem.propTypes = {
  children: T.oneOfType([T.string, T.node, T.arrayOf(T.node)]).isRequired,
}

Article.Section.propTypes = {
  children: T.any.isRequired,
  title: T.string.isRequired,
  id: T.oneOfType([T.string, T.number]).isRequired,
}

Article.ImageSection.propTypes = {
  children: T.any.isRequired,
  images: T.array.isRequired,
  type: T.oneOfType([T.string, T.array]).isRequired,
}

Article.Images.propTypes = {
  images: T.array.isRequired,
  type: T.oneOfType([T.string, T.array]).isRequired,
}
