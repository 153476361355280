import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query {
    weekMenus {
      edges {
        node {
          id
          slug
          year
          week
          title
          recipes {
            count
            edges {
              node {
                title
                color
                image {
                  url(width: 600, height: 732)
                  lqip
                  asset {
                    metadata {
                      palette {
                        ...Palette
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
