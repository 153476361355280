import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('checkbox')

export default function Checkbox({ label = null, className = null, children, ...props }) {
  return (
    <label {...bem('', '', className)}>
      <input {...props} {...bem('input')} type="checkbox" />
      <span {...bem('indicator')}>
        <Icon icon="check" {...bem('icon')} />
      </span>
      <span {...bem('label')}>{label || children}</span>
    </label>
  )
}

Checkbox.propTypes = {
  children: T.any,
  label: T.string,
  className: T.string,
}
