import './index.scss'

import React from 'react'
import T from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('page')

export default function PageTransition({ children, location, enter, exit, node }) {
  const Node = node

  const pageSlug =
    location &&
    location.pathname &&
    location.pathname.split('/').filter(str => str !== '')

  return (
    <TransitionGroup component={null}>
      <Transition
        key={location.key}
        timeout={{ enter, exit }}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        {status => {
          return <Node {...bem('', [status, ...pageSlug])}>{children(status)}</Node>
        }}
      </Transition>
    </TransitionGroup>
  )
}

PageTransition.propTypes = {
  children: T.any.isRequired,
  location: T.object.isRequired,
  enter: T.number,
  exit: T.number,
  node: T.string,
  exitScrollPos: T.string,
}

PageTransition.defaultProps = {
  enter: 1200,
  exit: 600,
  node: 'div',
}
