/* eslint-disable max-len */
import React from 'react'
import T, { InferProps } from 'prop-types'
import { get, orderBy } from 'lodash'
import { useQuery } from '@apollo/client'

import EQUIPMENT_QUERY from '../queries/pages/Equipment'
import { RouteProps } from '../types'
import setColors from '../lib/setColors'
import { COLORS } from '../_shared/lib/color'
import { Loader } from '../_shared/components'
import { Layout, AlphabetList, EquipmentList } from '../components'

export const routeProps: RouteProps = { path: '/utstyr', exact: true }

export default function Equipment({ applyColors }: InferProps<typeof Equipment.propTypes>): JSX.Element {
  const {
    loading,
    data = {},
  } = useQuery(EQUIPMENT_QUERY)

  // Set colors
  if (applyColors) {
    setColors(COLORS.blue, 'equipment')
  }

  const equipment = orderBy(
    get(data, 'equipment.edges', []) || [],
    ['node.title'], ['asc'],
  )

  const grouped = equipment.reduce((list, item) => {
    const firstLetter = (item.node.title || '').charAt(0)

    if (!list[firstLetter]) {
      list[firstLetter] = [item]
    } else {
      list[firstLetter].push(item)
    }

    return list
  }, {})

  return (
    <Layout
      header={{
        back: { name: 'Hjem', to: '/' },
        search: true,
      }}
      cover={{
        loading,
        skeleton: ['title'],
        title: 'Utstyrsguide',
      }}
    >
      {loading && <Loader />}
      {equipment && equipment.length > 0 && grouped && (
        <AlphabetList>
          {Object.keys(grouped).map((key) => (
            <AlphabetList.Group letter={key} key={key}>
              <EquipmentList items={grouped[key]} />
            </AlphabetList.Group>
          ))}
        </AlphabetList>
      )}
    </Layout>
  )
}

Equipment.propTypes = {
  applyColors: T.bool.isRequired,
}
