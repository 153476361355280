import './style.scss'

import React, { Component } from 'react'
import T from 'prop-types'
import { get, throttle } from 'lodash'
import Hls from 'hls.js'
import { get as axiosGet } from 'axios'
import BEMHelper from 'react-bem-helper'

import parseCaption from '../../lib/caption'
import { Button, Icon, AspectRatioImage, Loader } from '../../_shared/components'
import Progress from './Progress'
import Caption from './Caption'

const bem = new BEMHelper('course-video')
const isNative = !!get(window, 'ReactNativeWebView.postMessage')

export default class CourseVideo extends Component {
  static propTypes = {
    video: T.object.isRequired,
    initialProgress: T.number,
    onProgress: T.func.isRequired,
    resolution: T.number,
    caption: T.string,
    done: T.bool,
    next: T.object,
  }

  static defaultProps = {
    initialProgress: 0,
    resolution: 720,
    caption: null,
  }

  state = {
    percentage: 0,
    cues: [],
    playing: false,
    loading: false,
    initialStart: true,
    showControls: true,
    smallCaption: false,
  }

  _mounted = false

  constructor(props) {
    super(props)
    this.state.percentage = (this.props.initialProgress > 90) ? 0 : this.props.initialProgress
  }

  startVideo = () => {
    try {
      isNative && this._video.play()
    } catch (e) {
      // Usually happens if user navigates away before video is ready to play
      console.warn(e.message)
    }
  }

  componentDidMount() {
    this._mounted = true

    if (this._video.canPlayType('application/vnd.apple.mpegurl')) {
      this.startVideo()
    } else if (Hls.isSupported()) {
      var hls = new Hls()

      hls.loadSource(this._video.src)
      hls.attachMedia(this._video)
      hls.on(Hls.Events.MANIFEST_PARSED, this.startVideo)

      this._hls = hls
    } else {
      this.startVideo()
    }

    const startTime = this.props.video.asset.data.duration * ((this.state.percentage || 0) / 100)

    // Set start time for video playback
    this._video.currentTime = startTime
    this._video.oncanplay = () => {
      this._video.currentTime = startTime
      this._video.oncanplay = null
    }

    // Attatch events to track state
    this._video.onpause = () => this.setState({
      playing: false,
      showControls: true,
    })

    this._video.onended = () => this.setState({ playing: false })
    this._video.ontimeupdate = event => {
      this.updateTime(event)
      this.updateCaption()
    }

    this._video.onplay = () => this.setState({
      loading: true,
    }, this.hideControls)
    this._video.onplaying = () => {
      const time = this._video.currentTime

      if (!this.state.initialStart) {
        return this.setState({
          loading: false,
          playing: true,
        }, this.hideControls)
      }

      /**
       * Ugly workaround for weird delay when starting an hls stream
       * for the first time on a video element. It's reported to be
       * playing, when it is in fact waiting for stream to start
       * playing..
       */
      const interval = setInterval(() => {
        if (this._video && this._video.currentTime > time + 0.5) {
          this._mounted && this.setState({ loading: false, playing: true, initialStart: false })
          clearInterval(interval)
        }
      }, 50)
    }

    // Fetch subtitles
    this.getText()
  }

  _timeout = null

  hideControls = () => {
    clearTimeout(this._timeout)

    this._timeout = setTimeout(() => {
      this.setState({ showControls: false })
    }, 300)
  }

  componentWillUnmount() {
    this._mounted = false

    clearTimeout(this._timeout)

    // Deestroy hls object and unset video source to stop data fetching
    if (this._hls) {
      this._hls.destroy()
    }

    if (!this._video) {
      return
    }

    this._video.src = ''
    this._video.load()
  }

  getText = async() => {
    const { caption } = this.props
    if (!caption) {
      return
    }

    let captionText, cues

    try {
      const { data } = await axiosGet(caption)
      captionText = data
    } catch (e) {
      console.warn(`Failed when getting caption: ${e.message}`)
      return
    }

    try {
      // parse
      cues = await parseCaption(captionText)
      this.setState({ cues })
    } catch (e) {
      console.warn(`Failed when parsing captions: ${e.message}`)
    }
  }

  togglePlay = () => {
    if (!this._video) {
      return
    }

    if (this._video.paused) {
      return this._video.play()
    }

    this._video.pause()
  }

  videoClick = () => {
    if (!this._video) {
      return
    }

    this.setState({ showControls: true })

    if (this._video.paused) {
      return this._video.play()
    }
  }

  updateTime = throttle(({ target }) => {
    if (!target) {
      return
    }

    const percentage = 100 * (target.currentTime / target.duration)

    if (!percentage) {
      return
    }

    this._mounted && this.setState({ percentage })
    this.props.onProgress(percentage)
  }, 500)

  skip = amount => () => {
    if (!this._video) {
      return
    }

    this.setState({ showControls: true })

    this._video.currentTime += amount
  }

  updateCaption = () => {
    const { cues } = this.state

    if (!cues || cues.length === 0 || !this._video) {
      return null
    }

    const cue = cues.getCue(this._video.currentTime)
    this.setState({ caption: cue ? cue.text : null })
  }

  setProgress = percentage => {
    const duration = get(this.props, 'video.asset.data.duration')
    const time = duration * (percentage / 100)

    this._video.currentTime = time

    this._mounted && this.setState({ percentage, showControls: true })
    this.props.onProgress(percentage)
  }

  toggleMute = () => {
    this.setState({ muted: !this._video.muted })
    this._video.muted = !this._video.muted
  }

  isMuted = () => this._video && Boolean(this._video.muted)

  render() {
    const { percentage, playing, loading, showControls, caption } = this.state
    const { video, next, done } = this.props
    const duration = get(video, 'asset.data.duration')

    if (!video) {
      return null
    }

    const muted = this.isMuted()
    const nextThumbnail = next ? `${get(next, 'video.asset.thumbnail')}?time=0` : null
    const thumbnail = video ? `${get(video, 'asset.thumbnail')}?time=0` : null

    return (
      <div {...bem('', { playing })}>
        <div {...bem('video-wrapper', { done })}>
          {loading && <Loader type={['absolute', 'transparent']} />}
          <video
            {...bem('video')}
            ref={ref => {
              this._video = ref
            }}
            playsInline
            webkit-playsinline="true"
            src={video.asset.url}
            aria-controls="caption"
            poster={thumbnail}
            onClick={this.videoClick}
          />
          <svg viewBox="0 0 4 5" {...bem('aspect')} />

          {!done ? (
            <div {...bem('overlay')}>
              <Progress
                setProgress={this.setProgress}
                percentage={percentage}
                duration={duration}
                showControls={showControls}
              />
              <button
                type="button"
                {...bem('mute', {
                  hidden: !showControls,
                })}
                onClick={this.toggleMute}
                aria-label={muted ? 'Skru på lyd' : 'Skru av lyd'}
              >
                <Icon
                  icon={muted ? 'muted' : 'sound'}
                  size="small"
                  {...bem('sound-icon', { muted })}
                />
              </button>
            </div>
          ) : (
            <div {...bem('next-overlay')}>
              <AspectRatioImage
                src={nextThumbnail}
                aspect="3/4"
                {...bem('next-thumbnail')}
              />

              {next && (
                <Button
                  primary
                  full
                  label="Neste video"
                  description={{
                    icon: 'lecture',
                    iconSize: 'small',
                    content: next.title,
                  }}
                  to={next.to}
                  {...bem('next')}
                />
              )}
            </div>
          )}
        </div>

        <Caption>
          {caption}
        </Caption>

        <nav {...bem('controls')}>
          {done ? (
            <Button primary spaceless {...bem('replay')} onClick={this.togglePlay}>
              <Icon icon="backwards" size="small" {...bem('replay-icon')} /> Spill på nytt
            </Button>
          ) : (
            <>
              <button type="button" {...bem('control')} onClick={this.skip(-9)}>
                <Icon icon="backwards" size="large" title="Hopp tilbake 10 sekunder" />
              </button>

              <button type="button" {...bem('control', 'fill')} onClick={this.togglePlay}>
                <Icon icon={playing ? 'pause' : 'play'} title={playing ? 'Pause' : 'Spill av'} />
              </button>

              <button type="button" {...bem('control')} onClick={this.skip(10)}>
                <Icon icon="forwards" size="large" title="Hopp frem 10 sekunder" />
              </button>
            </>
          )}
        </nav>
      </div>
    )
  }
}
