/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState, useRef } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('course-video')

export default function Caption({ children }) {
  const dummyRef = useRef()
  const [small, setSmall] = useState(false)

  useLayoutEffect(() => {
    if (dummyRef.current.scrollHeight > dummyRef.current.offsetHeight + 4) {
      setSmall(true)
    } else if (small) {
      setSmall(false)
    }
  }, [children])

  return (
    <p
      id="caption"
      role="region"
      aria-live="polite"
      {...bem('caption', { small })}
    >
      <span {...bem('caption-text')}>
        {children}
      </span>

      <span {...bem('caption-text', 'dummy')} ref={dummyRef}>{children}</span>
    </p>
  )
}

Caption.propTypes = {
  children: T.any,
}
