import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Query($slug: String!) {
    category(slug: $slug) {
      node {
        id
        title
        preamble
        image {
          url(width: 600, height: 732)
          lqip
          asset {
            metadata {
              palette {
                ...Palette
              }
            }
          }
        }
        recipes {
          edges {
            node {
              id
              slug
              title
              duration
              color
              image {
                url(width: 600, height: 732)
                lqip
                asset {
                  metadata {
                    palette {
                      ...Palette
                    }
                  }
                }
              }
            }
          }
        }
        courses {
          edges {
            node {
              id
              slug
              title
              color
              image {
                url(width: 600, height: 732)
                lqip
                asset {
                  metadata {
                    palette {
                      ...Palette
                    }
                  }
                }
              }
              lectures { count }
            }
          }
        }
      }
    }
  }
  ${PaletteFragment}
`
