import React from 'react'
import T, { InferProps } from 'prop-types'
import { get } from 'lodash'
import { useLocation } from 'react-router'

import { COLORS } from '../_shared/lib/color'
import setColors from '../lib/setColors'
import { Layout, Paywall } from '../components'
import pm from '_shared/lib/post-message'
import { Button } from '_shared/components'

export const routeProps = { path: '/push', exact: true }

export default function PushPage({
  applyColors,
}: InferProps<typeof PushPage.propTypes>): JSX.Element {
  const location = useLocation()

  // Set colors
  if (applyColors) {
    setColors(COLORS.yellow, 'push')
  }

  const back = get(location, 'state.from', '/')

  return (
    <Layout
      header={{
        back: { name: 'Hjem', to: back },
      }}
    >
      <Button onClick={() => pm({ type: 'requestPush' })}>Aktivér push</Button>
      <Button onClick={() => pm({ type: 'disablePush'})}>Skru av push</Button>
    </Layout>
  )
}

PushPage.propTypes = {
  applyColors: T.bool.isRequired,
}
