import gql from 'graphql-tag'

import PaletteFragment from '../fragments/palette'

export default gql`
  query Week($slug: String!) {
    weekMenu(slug: $slug) {
      node {
        id
        slug
        year
        week
        title
        preamble
        shoppingList {
          department {
            id
            title
          }
          ingredients {
            id
            title
            quantity {
              two
              four
              six
              eight
            }
          }
        }
        recipes {
          edges {
            node {
              id
              slug
              title
              duration
              color
              image {
                url(width: 600, height: 732)
                lqip
                asset {
                  metadata {
                    palette {
                      ...Palette
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    weekMenus {
      edges {
        node {
          id
          year
          week
          title
          slug
        }
      }
    }
  }
  ${PaletteFragment}
`
