/* eslint-disable react-hooks/exhaustive-deps */

import './index.scss'

import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import usePrevious from '../../hooks/previous'
import { Portal, Transition, Icon } from '../../_shared/components'

const ROOT_ELEMENT = document.querySelector('#root')

const bem = new BEMHelper('message')

let _TIMER
const DURATION = 1600

export default function Message({ message, duration = DURATION, icon }) {
  const [show, setShow] = useState(false)
  const prevMessage = usePrevious(message)

  useEffect(() => {
    if (message && !show && message !== prevMessage) {
      clearTimeout(_TIMER)
      setShow(true)
    }
  }, [message])

  useEffect(() => {
    if (show) {
      _TIMER = setTimeout(() => {
        setShow(false)
      }, duration)
    }

    return () => clearTimeout(_TIMER)
  }, [show])

  return (
    <Portal insertTo={ROOT_ELEMENT}>
      <Transition updateKey={show.toString()} {...bem('')}>
        {show && (
          <p {...bem('content')}>
            {icon && <Icon icon={icon} size="small" {...bem('icon')} />}
            {message}
          </p>
        )}
      </Transition>
    </Portal>
  )
}

Message.propTypes = {
  message: T.string,
  duration: T.number,
  icon: T.string,
}
