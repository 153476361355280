import './index.scss'

import React, { Fragment, useState } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { AspectRatioImage } from '../../_shared/components'
import Equipment from '../Equipment'

const bem = new BEMHelper('equipment-list')

export default function EquipmentList({ items }) {
  const [modal, setModal] = useState(null)

  return (
    <>
      <nav {...bem('')}>
        {items.map(({ node, node: { id, title, url, images } }) => (
          <Fragment key={id}>
            <button type="button" {...bem('item')} onClick={() => setModal(id)}>
              {images.length > 0 && (
                <AspectRatioImage
                  aspect="1/1"
                  {...bem('image')}
                  src={images[0].url}
                  alt={title}
                  lqip={images[0].lqip}
                />
              )}
              <span {...bem('name')}>{title}</span>
            </button>

            <Equipment show={modal === id} onClose={() => setModal(null)} {...node} />
          </Fragment>
        ))}
      </nav>
    </>
  )
}

EquipmentList.propTypes = {
  items: T.array.isRequired,
}
