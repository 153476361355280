/**
 * Takes an array of steps and returns a list of ingredients in
 * the steps of a recipe
 *
 * @param array steps in a recipe
 * @return array of ingredients
 */
export default function extractIngredients(steps) {
  return [].concat(
    [],
    ...(steps || []).map(({ ingredients }) =>
      ingredients.filter(
        ({ showInIngredientList }) => showInIngredientList !== false,
      ),
    ),
  )
}
