import React from 'react'
import T, { InferProps } from 'prop-types'
import { useParams, useLocation } from 'react-router'
import { get } from 'lodash'
import { useQuery, useMutation } from '@apollo/client'

import { ADD_FAVORITE_COURSE, REMOVE_FAVORITE_COURSE } from '../queries/mutations/favorite'
import COURSE_QUERY from '../queries/pages/Course'
import FAVORITES_QUERY from '../queries/pages/Favourites'
import getColor from '../_shared/lib/color'
import setColors from '../lib/setColors'
import trackCustomEvent from '../helpers/track-custom-event'
import { Button } from '../_shared/components'
import { Layout, LectureList, Preamble, Paywall } from '../components'
import { RouteProps, Lecture } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = { path: '/kurs/:slug', exact: true }

function getNextLecture(lectures: { edges: { node: Lecture }[] }): null | Lecture & { index: number } {
  if (!lectures.edges.length) {
    return null
  }

  const array = [...lectures.edges].map(({ node }, index) => ({
    ...node,
    index,
  }))

  const lastWithProgress = [...array].reverse().find(({ progress = 0 }) => progress > 5)

  if (lastWithProgress && lastWithProgress.progress > 95) {
    return lastWithProgress.index + 1 < array.length ? array[lastWithProgress.index + 1] : array[0]
  }

  if (!lastWithProgress) {
    return array[0]
  }

  return lastWithProgress
}

// eslint-disable-next-line complexity
export default function CoursePage({ applyColors }: InferProps<typeof CoursePage.propTypes>): JSX.Element {
  const location = useLocation()
  const { slug } = useParams()

  const {
    loading,
    error,
    data: {
      course: {
        node: course = {},
      } = {},
    } = {},
  } = useQuery(COURSE_QUERY, {
    variables: { slug },
    fetchPolicy: 'network-only',
  })

  const [addFavorite] = useMutation(ADD_FAVORITE_COURSE)
  const [removeFavorite] = useMutation(REMOVE_FAVORITE_COURSE)

  const id = get(course, 'id', '')
  const title = get(course, 'title', '')
  // const preamble = get(course, 'preamble', null)
  const image = get(course, 'image', null)
  const preamble = get(course, 'preamble', null)
  const lectures = get(course, 'lectures', { edges: [] })
  const isFavorite = get(course, 'isFavorite', false)
  const hasAccess = get(course, 'hasAccess', true)

  // set colors
  if (!loading && course && applyColors) {
    const colors = getColor(
      get(course, 'image.asset.metadata.palette', null),
      get(course, 'color', null),
    )
    setColors(colors, `course/${slug}`)
  }

  if (error) { throw error }

  if (!loading && !course) {
    return <Error404 applyColors title="Kurset finnes ikke" />
  }

  const toggleFavorite = (): void => {
    if (isFavorite) {
      removeFavorite({
        variables: { id },
        optimisticResponse: {
          removeFavoriteCourse: {
            success: true,
            __typename: 'RemoveFavoriteCoursePayload',
            course: {
              __typename: 'CoursesEdge',
              node: { __typename: 'Course', id, isFavorite: false },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent('Course favourite', 'Remove from favourites', title, -1)
    } else {
      addFavorite({
        variables: { id },
        optimisticResponse: {
          addFavoriteCourse: {
            success: true,
            __typename: 'AddFavoriteCoursePayload',
            course: {
              __typename: 'CoursesEdge',
              node: { __typename: 'Course', id, isFavorite: true },
            },
          },
        },
        refetchQueries: [{ query: FAVORITES_QUERY }],
      })
      trackCustomEvent('Course favourite', 'Add to favourites', title, 1)
    }
  }

  const back = get(location, 'state.from', '/kurs/')

  const resume = getNextLecture(lectures)

  const action = resume
    ? {
      label: resume.index !== 0 ? 'Fortsett' : 'Start kurs',
      progress: resume.progress,
      description: {
        content: `${(resume.index || 0) + 1}. ${resume.title}`,
      },
      to: `/kurs/${slug}/leksjon/${resume.id}`,
    }
    : null

  if (!hasAccess) {
    return (
      <Layout
        header={{
          back: { name: 'Kurs', to: back },
          bookmark: {
            toggle: toggleFavorite,
            isBookmarked: isFavorite,
          },
        }}
      >
        <Paywall title={`For å se «${title}» må du bli abonnent:`} />
      </Layout>
    )
  }

  return (
    <Layout
      header={{
        back: { name: 'Kurs', to: back },
        bookmark: {
          toggle: toggleFavorite,
          isBookmarked: isFavorite,
        },
      }}
      cover={{
        loading,
        skeleton: ['title', 'image'],
        title,
        image: image && image.url,
        action,
      }}
    >
      {!!preamble && <Preamble>{preamble}</Preamble>}
      {lectures.edges.length > 0 && (
        <>
          <LectureList lectures={lectures} />
          <br />

          <Button
            primary
            full
            spaceless
            icon={isFavorite ? 'heartFilled' : 'heart'}
            onClick={toggleFavorite}
          >
            {isFavorite ? 'Favoritt' : 'Lagre som favoritt'}
          </Button>
        </>
      )}
    </Layout>
  )
}

CoursePage.propTypes = {
  applyColors: T.bool.isRequired,
}
