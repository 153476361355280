import './index.scss'

import React, { useState, useRef, useEffect } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
// import { keyframes } from 'emotion'

import { Icon, Portal, Transition } from '../../_shared/components'

const bem = new BEMHelper('step-overlay')

// const DURATION = 300
// const EASING = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'

export default function StepOverlay({ children, insertContentTo, onChange }) {
  const toggleRef = useRef()
  const contentRef = useRef()
  const [expanded, setExpanded] = useState(false)
  const [position, setPosition] = useState(0)

  const toggle = () => {
    onChange(!expanded)
    setExpanded(expanded => !expanded)
  }

  useEffect(() => {
    if (expanded) {
      const diff = Math.round(
        toggleRef.current.getBoundingClientRect().top - contentRef.current.getBoundingClientRect().top,
      )
      setPosition(diff)
    }
  }, [expanded])

  const title = `${expanded ? 'Alle ingredienser' : 'Alle ingredienser'}`

  return (
    <div {...bem('', { expanded })}>
      <button {...bem('toggle')} type="button" onClick={toggle} ref={toggleRef} aria-expanded={expanded}>
        {title}
        <Icon icon="chevron" size="small" direction="down" {...bem('indicator')} />
      </button>

      <Portal insertTo={insertContentTo}>
        <Transition updateKey={expanded.toString()} {...bem('wrapper')} enter={900}>
          {expanded && (
            <div {...bem('body')} ref={contentRef} style={{ '--top': `${position}px` }}>
              <button {...bem('toggle')} type="button" onClick={toggle} aria-expanded={expanded}>
                {title}
                <Icon icon="chevron" size="small" direction="up" {...bem('indicator')} />
              </button>
              <div {...bem('content')}>{children}</div>
            </div>
          )}
        </Transition>
      </Portal>
    </div>
  )
}

StepOverlay.propTypes = {
  children: T.any.isRequired,
  insertContentTo: T.any.isRequired,
  onChange: T.func.isRequired,
}
