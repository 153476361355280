/* eslint-disable react-hooks/exhaustive-deps */

import './index.scss'

import React, { useEffect, ReactElement } from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

import usePrevious from '../../hooks/previous'
import { Portal, Transition, Button } from '../../_shared/components'

const ROOT_ELEMENT = document.querySelector('#root')

const bem = new BEMHelper('confirm')

export default function Confirm({
  title,
  onClose,
  onSubmit,
  actions,
  children,
  show = false,
}: InferProps<typeof Confirm.propTypes>): ReactElement {
  const prevShow = usePrevious(show)

  useEffect(() => {
    if (show) {
      document.body.classList.add('block-scrolling')
    } else if (prevShow) {
      document.body.classList.remove('block-scrolling')
    }

    return (): void => document.body.classList.remove('block-scrolling')
  }, [show])

  return (
    <Portal insertTo={ROOT_ELEMENT}>
      <Transition updateKey={`${show}`} {...bem('')} enter={700} exit={1500}>
        {show && (
          <div {...bem('wrapper')}>
            <form onSubmit={onSubmit} {...bem('content')}>
              <h2 {...bem('title')}>{title}</h2>

              {children && <div {...bem('children')}>{children}</div>}

              {actions.map(({ label, ...props }, index) => (
                <Button
                  key={index}
                  {...props}
                  {...bem('action')}
                  spaceless
                  full
                  small
                >
                  {label}
                </Button>
              ))}
            </form>

            <button {...bem('backdrop')} type="button" onFocus={onClose} onClick={onClose} aria-label="Lukk" />
          </div>
        )}
      </Transition>
    </Portal>
  )
}

Confirm.propTypes = {
  title: T.string.isRequired,
  onClose: T.func.isRequired,
  actions: T.array.isRequired,
  onSubmit: T.func.isRequired,
  children: T.any,
  show: T.bool,
}
