/* global FB */

import React, { useState, useEffect, ChangeEvent } from 'react'
import T, { InferProps } from 'prop-types'
import { useMutation } from '@apollo/client'
import { get } from 'lodash'

import { Button, Input } from '../_shared/components'
import { RouteProps } from '../types'
import {
  UNCONNECTED_USER_WITH_SAME_EMAIL,
  ERROR_USER_NOT_FOUND,
} from '../constants'
import { COLORS } from '../_shared/lib/color'
import setColors from '../lib/setColors'
import { Layout } from '../components'
import LOGIN_MUTATION from '../queries/mutations/login'
import LOGIN_FACEBOOK_MUTATION from '../queries/mutations/login-facebook'
import LINK_FACEBOOK_MUTATION from '../queries/mutations/link-facebook'

export const routeProps: RouteProps = { path: '/login', exact: true }

export default function Login({
  applyColors,
}: InferProps<typeof Login.propTypes>): JSX.Element {
  const [login, { data: loginData }] = useMutation(LOGIN_MUTATION)

  const [
    loginFacebook,
    { data: loginFacebookData, loading: loginFacebookLoading },
  ] = useMutation(LOGIN_FACEBOOK_MUTATION)

  const [linkAccountWithFacebook, { data: linkAccountData }] = useMutation(
    LINK_FACEBOOK_MUTATION,
  )

  const [error, setError] = useState<null | {
    code: string | number
    message: string
  }>(null)
  const [fbAuth, setFbAuth] = useState<boolean>(false)
  const [accessToken, setAccessToken] = useState<null | string>(null)

  const [credentials, updateValues] = useState({
    email: '',
    password: '',
    name: '',
  })

  const { email, password } = credentials
  const connectedUserNotFound =
    get(loginFacebookData, 'loginFacebook.error.code') ===
    ERROR_USER_NOT_FOUND.code

  // set colors
  applyColors && setColors(COLORS.inverted, 'login')

  function handleActionResponse(actionResponse: {
    token?: string
    error?: {
      code: string | number
      message: string
    }
  }): void {
    if (!actionResponse) {
      return
    }

    if (actionResponse.error) {
      return setError(actionResponse.error)
    }

    if (!actionResponse.token) {
      return setError({ code: 'LOGIN2', message: 'An unknown error occured' })
    }

    try {
      localStorage.setItem('token', actionResponse.token)
      window.location.pathname = '/'
      return
    } catch (e) {
      return setError({
        code: 'LOGIN3',
        message:
          'Successful login, but failed when writing token to localstorage.',
      })
    }
  }

  // Handle logging users in and setting JWTs and so on..
  useEffect(() => handleActionResponse(get(loginData, 'login')), [loginData])
  useEffect(
    () => handleActionResponse(get(loginFacebookData, 'loginFacebook')),
    [loginFacebookData],
  )
  useEffect(() => handleActionResponse(get(linkAccountData, 'linkFacebook')), [
    linkAccountData,
  ])

  useEffect(() => {
    if (connectedUserNotFound) {
      setFbAuth(false)
    }
  }, [connectedUserNotFound])

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (!email || !password) {
      return setError({
        code: 'LOGIN1',
        message: 'Du må fylle ut både epost og passord. Prøv igjen',
      })
    }

    login({ variables: { email, password } })
  }

  const handleChange = (key: string) => (value: string): void => {
    updateValues({ ...credentials, [key]: value })
  }

  const loginWithFacebook = (): void => {
    setFbAuth(true)

    FB.login(
      (response) => {
        if (response.authResponse) {
          const { accessToken } = response.authResponse
          setAccessToken(accessToken)

          // Pass access to GraphQL server in order to trade for a foodsteps token
          loginFacebook({ variables: { accessToken } })
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      },
      { scope: 'email' },
    )
  }

  const handleLinkAccount = (event: ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault()
    linkAccountWithFacebook({ variables: { accessToken, password } })
  }

  const unconnectedUserExist =
    get(loginFacebookData, 'loginFacebook.error.code') ===
    UNCONNECTED_USER_WITH_SAME_EMAIL.code

  return (
    <div style={{ color: 'white' }}>
      <Layout title="Logg inn">
        {connectedUserNotFound && (
          <div>
            Du har ikke en Foodsteps-bruker.. Du kan registrere deg snart
          </div>
        )}

        {unconnectedUserExist && (
          <div>
            Det finnes en Foodsteps-bruker med den samme epost-adressen som din
            Facebook-konto. Du kan koble sammen disse brukerne ved å skrive inn
            passordet til Foodsteps-kontoen. Om du ikke vil gjøre det nå kan du
            gjøre det senere inne på "min side".
            <form onSubmit={handleLinkAccount}>
              <Input
                label="Passord"
                id="password"
                type="password"
                value={password}
                onChange={handleChange('password')}
              />

              <Button type="submit" full>
                Koble sammen kontoene og logg inn
              </Button>
            </form>
          </div>
        )}

        {!fbAuth ? (
          <form onSubmit={handleSubmit}>
            <Input
              label="E-post"
              id="email"
              type="email"
              value={email}
              onChange={handleChange('email')}
            />
            <Input
              label="Passord"
              id="password"
              type="password"
              value={password}
              onChange={handleChange('password')}
            />
            {error && <div>{error.message}</div>}
            <Button type="submit" primary full>
              Logg inn
            </Button>
            {!fbAuth && (
              <Button full small primary onClick={loginWithFacebook}>
                Logg inn med Facebook
              </Button>
            )}
          </form>
        ) : (
          <div>
            {loginFacebookLoading ? (
              'Logger inn...'
            ) : (
              <div>
                {connectedUserNotFound && <div>Fant ikke brukeren</div>}
              </div>
            )}
          </div>
        )}
      </Layout>
    </div>
  )
}

Login.propTypes = {
  applyColors: T.bool.isRequired,
}
