import gql from 'graphql-tag'

export const TOGGLE_GROCERY_ITEM = gql`
  mutation ToggleGroceryItem($id: ID!) {
    toggleGroceryListItem(id: $id) {
      id
      checked
    }
  }
`
