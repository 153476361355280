/* eslint-disable react/jsx-key, max-len */

import './index.scss'

import React from 'react'
import T, { InferProps } from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('icon')

export const ICONS: { [iconName: string]: { [size: string]: any } } = {
  chevron: {
    base: [
      [9, 18],
      <path
        {...bem('line')}
        d="M0.709991 1.51001L6.56999 7.37001C7.60999 8.41001 7.60999 10.09 6.56999 11.13L0.709991 16.99"
      />,
    ],
    small: [
      [5, 10],
      <path
        {...bem('line')}
        d="M0.59375 0.926279L3.98375 4.31628C4.25375 4.58628 4.25375 5.02628 3.98375 5.29628L0.59375 8.68628"
      />,
    ],
  },
  dots: {
    base: [
      [21, 4],
      <>
        <path
          {...bem('fill')}
          d="M1.75 4C2.7165 4 3.5 3.2165 3.5 2.25C3.5 1.2835 2.7165 0.5 1.75 0.5C0.783502 0.5 0 1.2835 0 2.25C0 3.2165 0.783502 4 1.75 4Z"
        />
        <path
          {...bem('fill')}
          d="M10.32 4C11.2865 4 12.07 3.2165 12.07 2.25C12.07 1.2835 11.2865 0.5 10.32 0.5C9.35351 0.5 8.57001 1.2835 8.57001 2.25C8.57001 3.2165 9.35351 4 10.32 4Z"
        />
        <path
          {...bem('fill')}
          d="M18.9 4C19.8665 4 20.65 3.2165 20.65 2.25C20.65 1.2835 19.8665 0.5 18.9 0.5C17.9335 0.5 17.15 1.2835 17.15 2.25C17.15 3.2165 17.9335 4 18.9 4Z"
        />
      </>,
    ],
  },
  home: {
    base: [
      [20, 18],
      <path
        {...bem('line')}
        d="M4.05002 8.95V16.74H15.78V8.95L17.87 8.23C18.27 8.09 18.36 7.56 18.02 7.3L9.92002 1.25L1.81002 7.3C1.47002 7.55 1.55002 8.09 1.96002 8.23L4.05002 8.95Z"
      />,
    ],
    small: [
      [15, 13],
      <path
        {...bem('line')}
        d="M3.07996 6.44002V12.05H11.53V6.44002L13.03 5.92002C13.32 5.82002 13.38 5.43002 13.13 5.25002L7.30996 0.900024L1.46996 5.25002C1.21996 5.43002 1.27996 5.82002 1.56996 5.92002L3.07996 6.44002Z"
      />,
    ],
  },
  recipe: {
    base: [
      [18, 18],
      <path
        {...bem('line')}
        d="M4.76 0.23999V17.74M16.71 17.74V0.99999C14.56 0.99999 12.68 2.73999 12.68 4.87999V11.47H16.71M1 0.23999V6.56999C1 7.34999 1.6 7.98999 2.35 7.98999H7.16C7.91 7.98999 8.51 7.35999 8.51 6.56999V0.23999"
      />,
    ],
    small: [
      [13, 14],
      <path
        {...bem('line')}
        d="M3.41997 0.420044V13.02M12.03 13.02V0.970044C10.49 0.970044 9.12997 2.22004 9.12997 3.77004V8.52004H12.03M0.719971 0.420044V4.98004C0.719971 5.54004 1.15997 6.00004 1.68997 6.00004H5.14997C5.68997 6.00004 6.11997 5.54004 6.11997 4.98004V0.420044"
      />,
    ],
  },
  course: {
    base: [
      [18, 19],
      <>
        <path
          {...bem('fill')}
          d="M11.23 6.46003L7.35001 4.22003C7.09001 4.07003 6.76001 4.26003 6.76001 4.56003V9.05003C6.76001 9.35003 7.09001 9.54003 7.35001 9.39003L11.24 7.15003C11.49 6.99003 11.49 6.61003 11.23 6.46003Z"
        />
        <path
          {...bem('line')}
          d="M8.81 12.74V15.18M14.88 17.53L9.83001 15.58C9.17001 15.32 8.44001 15.32 7.78001 15.58L2.73001 17.53M3.06 12.1H14.56C15.7 12.1 16.62 11.18 16.62 10.04V3.56C16.63 2.42 15.7 1.5 14.56 1.5H3.06C1.92 1.5 1 2.42 1 3.56V10.03C1 11.18 1.92 12.1 3.06 12.1Z"
        />
      </>,
    ],
    small: [
      [13, 13],
      <>
        <path
          {...bem('fill')}
          d="M8.09 4.28995L5.29 2.66995C5.1 2.56995 4.87 2.69995 4.87 2.91995V6.14995C4.87 6.36995 5.1 6.49995 5.29 6.38995L8.09 4.76995C8.28 4.66995 8.28 4.39995 8.09 4.28995Z"
        />
        <path
          {...bem('line')}
          d="M6.34998 8.81006V10.5701M10.72 12.26L7.08999 10.85C6.61999 10.67 6.08999 10.67 5.61999 10.85L1.98999 12.26M2.20997 8.34997H10.49C11.31 8.34997 11.98 7.67997 11.98 6.85997V2.20997C11.98 1.38997 11.31 0.719971 10.49 0.719971H2.20997C1.38997 0.719971 0.719971 1.38997 0.719971 2.20997V6.86997C0.719971 7.68997 1.38997 8.34997 2.20997 8.34997Z"
        />
      </>,
    ],
  },
  lecture: {
    base: [
      [19, 18],
      <>
        <path
          {...bem('line')}
          d="M12.15 8.7L7.05 5.76C6.79 5.6 6.46 5.79 6.46 6.09V11.98C6.46 12.28 6.79 12.47 7.05 12.32L12.15 9.38C12.41 9.22 12.41 8.85 12.15 8.7Z"
          stroke="#010202"
        />
        <path
          {...bem('line')}
          d="M3.83 17H14.25C15.82 17 17.08 15.73 17.08 14.17V4.33C17.08 2.76 15.81 1.5 14.25 1.5H3.83C2.27 1.5 1 2.77 1 4.33V14.16C1 15.73 2.27 17 3.83 17Z"
        />
      </>,
    ],
    small: [
      [13, 13],
      <>
        <path
          {...bem('line')}
          d="M9.32997 12.28H3.26997C1.85997 12.28 0.719971 11.14 0.719971 9.72999V3.67C0.719971 2.26 1.85997 1.12 3.26997 1.12H9.32997C10.74 1.12 11.88 2.26 11.88 3.67V9.72999C11.88 11.14 10.74 12.28 9.32997 12.28Z"
        />
        <path
          {...bem('line')}
          d="M8.43997 6.39999L4.96997 4.4C4.73997 4.27 4.44997 4.43 4.44997 4.7V8.7C4.44997 8.96 4.73997 9.13 4.96997 9L8.43997 7C8.66997 6.86 8.66997 6.52999 8.43997 6.39999Z"
        />
      </>,
    ],
  },
  time: {
    base: [
      [18, 18],
      <path
        {...bem('line')}
        d="M8.4 4.83V9.25L11.81 12.67M12.96 17H4.54C2.59 17 1 15.41 1 13.46V5.04C1 3.09 2.59 1.5 4.54 1.5H12.95C14.91 1.5 16.49 3.09 16.49 5.04V13.45C16.5 15.41 14.91 17 12.96 17Z"
      />,
    ],
    small: [
      [13, 13],
      <path
        {...bem('line')}
        d="M6.03997 3.50999V6.7L8.50997 9.16M9.32997 12.28H3.26997C1.85997 12.28 0.719971 11.14 0.719971 9.72999V3.67C0.719971 2.26 1.85997 1.12 3.26997 1.12H9.32997C10.74 1.12 11.88 2.26 11.88 3.67V9.72999C11.88 11.14 10.74 12.28 9.32997 12.28Z"
      />,
    ],
  },
  search: {
    base: [
      [19, 19],
      <path
        {...bem('line')}
        d="M13.01 13.07H4.54C2.59 13.07 1 11.48 1 9.52V4.6C1 2.65 2.59 1.06 4.54 1.06H9.46C11.42 1.06 13 2.65 13 4.6V13.07H13.01ZM13.01 13.07L17.65 17.71"
      />,
    ],
    small: [
      [14, 14],
      <path
        {...bem('line')}
        d="M9.03999 9.13998L13.16 13.26M9.23 9.32998H3.09C1.94 9.32998 1 8.39997 1 7.23997V3.18998C1 2.03998 1.93 1.09998 3.09 1.09998H7.14C8.29 1.09998 9.23 2.02998 9.23 3.18998V9.32998Z"
      />,
    ],
  },
  close: {
    base: [[17, 19], <path {...bem('line')} d="M0.709991 1.51001L16.18 16.98M16.18 1.51001L0.709991 16.98" />],
    small: [[13, 13], <path {...bem('line')} d="M0.51001 1.14001L11.65 12.28M11.65 1.14001L0.51001 12.28" />],
  },
  heart: {
    base: [
      [21, 19],
      <path
        {...bem('line')}
        d="M8.83001 16.2901L2.06001 8.58005C0.640013 6.97005 0.650013 4.56005 2.07001 2.95005L2.08001 2.94005C3.78001 1.01005 6.79001 1.02005 8.48001 2.96005L10.47 5.23005L12.46 2.96005C14.15 1.02005 17.16 1.01005 18.86 2.94005L18.87 2.95005C20.29 4.56005 20.29 6.97005 18.88 8.58005L12.11 16.2901C11.23 17.2701 9.69001 17.2701 8.83001 16.2901Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />,
    ],
    small: [
      [16, 13],
      <path {...bem('line')}
        d="M7.58 11.765L2.46 5.94497C1.47 4.81497 1.47 3.11497 2.47 1.98497L2.48 1.97497C3.68 0.624975 5.79 0.624975 6.98 1.98497L8.38 3.58497L9.78 1.98497C10.97 0.624975 13.08 0.614975 14.28 1.97497L14.29 1.98497C15.29 3.11497 15.29 4.81497 14.3 5.94497L9.19 11.765C8.75 12.245 8 12.245 7.58 11.765Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />,
    ],
  },
  heartFilled: {
    base: [
      [21, 19],
      <path
        {...bem('line', 'fill')}
        d="M8.83001 16.2901L2.06001 8.58005C0.640013 6.97005 0.650013 4.56005 2.07001 2.95005L2.08001 2.94005C3.78001 1.01005 6.79001 1.02005 8.48001 2.96005L10.47 5.23005L12.46 2.96005C14.15 1.02005 17.16 1.01005 18.86 2.94005L18.87 2.95005C20.29 4.56005 20.29 6.97005 18.88 8.58005L12.11 16.2901C11.23 17.2701 9.69001 17.2701 8.83001 16.2901Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />,
    ],
    small: [
      [16, 13],
      <path {...bem('line', 'fill')}
        d="M7.58 11.765L2.46 5.94497C1.47 4.81497 1.47 3.11497 2.47 1.98497L2.48 1.97497C3.68 0.624975 5.79 0.624975 6.98 1.98497L8.38 3.58497L9.78 1.98497C10.97 0.624975 13.08 0.614975 14.28 1.97497L14.29 1.98497C15.29 3.11497 15.29 4.81497 14.3 5.94497L9.19 11.765C8.75 12.245 8 12.245 7.58 11.765Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />,
    ],
  },
  play: {
    base: [
      [16, 18],
      <path
        {...bem('line')}
        d="M13.91 7.40001L4.19 1.79001C2.77 0.970009 1 1.99001 1 3.63001V14.86C1 16.5 2.77 17.52 4.19 16.7L13.91 11.09C15.33 10.27 15.33 8.22001 13.91 7.40001Z"
      />,
    ],
    small: [
      [12, 13],
      <path
        {...bem('line')}
        d="M10.02 5.37004L3.01997 1.33004C1.99997 0.740036 0.719971 1.47004 0.719971 2.65004V10.73C0.719971 11.91 1.99997 12.64 3.01997 12.06L10.02 8.02004C11.04 7.43004 11.04 5.96004 10.02 5.37004Z"
      />,
    ],
  },
  people: {
    base: [
      [25, 19],
      <path
        {...bem('line')}
        d="M16.79 18V14.66C16.79 13.48 15.83 12.53 14.66 12.53H3.13C1.96 12.53 1 13.49 1 14.67V18.01M23.03 18.03V14.66C23.03 13.48 22.07 12.53 20.9 12.53H19.38M15.14 1.5C17.21 1.5 18.88 3.17 18.88 5.24C18.88 7.31 17.21 8.98 15.14 8.98M12.63 5.24C12.63 7.30554 10.9555 8.98 8.89 8.98C6.82446 8.98 5.15 7.30554 5.15 5.24C5.15 3.17445 6.82446 1.5 8.89 1.5C10.9555 1.5 12.63 3.17445 12.63 5.24Z"
      />,
    ],
  },
  lines: {
    large: [[28, 11], <path {...bem('line')} d="M27.26 1.14502H0M27.26 9.26502H0" />],
    base: [[18, 9], <path {...bem('line')} d="M17.47 1.5H0M17.47 7.5H0" />],
    small: [[13, 7], <path {...bem('line')} d="M12.87 1H0M12.87 5.42H0" />],
  },
  forwards: {
    large: [
      [28, 32],
      <>
        <path
          {...bem('line')}
          d="M22.46 8.44996L25.83 5.07996C26.11 4.79996 26.11 4.34996 25.83 4.07996L22.46 0.709961M25.77 4.57996H8.34C4.42 4.57996 1.25 7.74996 1.25 11.67V22.73C1.25 26.64 4.42 29.82 8.34 29.82H19.4C23.31 29.82 26.49 26.65 26.49 22.73V17.2" />
        <path
          {...bem('line', 'thin')}
          d="M8.89999 11.9399V22.4399M18.83 17.1899C18.83 19.7525 17.3839 21.8299 15.6 21.8299C13.8161 21.8299 12.37 19.7525 12.37 17.1899C12.37 14.6273 13.8161 12.5499 15.6 12.5499C17.3839 12.5499 18.83 14.6273 18.83 17.1899Z"
        />
      </>,
    ],
    base: [
      [15, 20],
      <path
        {...bem('line')}
        d="M13.72 5.40003H4.54C2.59 5.40003 1 6.99003 1 8.94003V14.47C1 16.43 2.59 18.01 4.54 18.01H10.07C12.03 18.01 13.61 16.42 13.61 14.47V11.71M10.28 9.27003L13.65 5.90003C13.93 5.62003 13.93 5.17003 13.65 4.90003L10.28 1.53003"
      />,
    ],
  },
  backwards: {
    large: [
      [28, 32],
      <>
        <path
          {...bem('line')}
          d="M5.02 8.44996L1.65 5.07996C1.37 4.79996 1.37 4.34996 1.65 4.07996L5.02 0.709961M1.71 4.58H19.14C23.05 4.58 26.23 7.75 26.23 11.67V22.73C26.23 26.64 23.06 29.82 19.14 29.82H8.09C4.17 29.81 1 26.64 1 22.72V17.19" />
        <path
          {...bem('line', 'thin')}
          d="M8.64999 11.9399V22.4399M18.58 17.1899C18.58 19.7525 17.1339 21.8299 15.35 21.8299C13.5661 21.8299 12.12 19.7525 12.12 17.1899C12.12 14.6273 13.5661 12.5499 15.35 12.5499C17.1339 12.5499 18.58 14.6273 18.58 17.1899Z"
        />
      </>,
    ],
    base: [
      [15, 20],
      <path
        {...bem('line')}
        d="M1.14 5.40003H10.32C12.28 5.40003 13.86 6.99003 13.86 8.94003V14.47C13.86 16.43 12.27 18.01 10.32 18.01H4.78C2.82 18.01 1.24 16.42 1.24 14.47V11.71M4.58 9.27003L1.21 5.90003C0.93 5.62003 0.93 5.17003 1.21 4.90003L4.58 1.53003"
      />,
    ],
  },
  replay: {
    base: [
      [28, 32],
      <path
        {...bem('line')}
        d="M5.02 8.44996L1.65 5.07996C1.37 4.79996 1.37 4.34996 1.65 4.07996L5.02 0.709961M1.71 4.58H19.14C23.05 4.58 26.23 7.75 26.23 11.67V22.73C26.23 26.64 23.06 29.82 19.14 29.82H8.09C4.17 29.81 1 26.64 1 22.72V17.19" />,
    ],
  },
  pause: {
    base: [[11, 18], <path {...bem('line')} d="M1 0.5V17.97M10 0.5V17.97" />],
    small: [[6, 13], <path {...bem('line')} d="M0.719971 0V12.58M5.03997 0V12.58" />],
  },
  check: {
    base: [
      [20, 14],
      <path
        {...bem('line')}
        d="M0.709991 7.96994L4.27999 11.5399C5.31999 12.5799 6.99999 12.5799 8.03999 11.5399L18.76 0.809937"
      />,
    ],
    small: [
      [15, 11],
      <path
        {...bem('line')}
        d="M0.51001 6.36002L3.08001 8.93002C3.83001 9.68002 5.04001 9.68002 5.79001 8.93002L13.51 1.21002"
      />,
    ],
  },
  sound: {
    base: [
      [22, 19],
      <path
        {...bem('line')}
        d="M17.88 3.76996C19.28 5.16996 20.15 7.10996 20.15 9.24996C20.15 11.39 19.28 13.33 17.88 14.73M15.05 6.59996C15.73 7.27996 16.15 8.21996 16.15 9.24996C16.15 10.28 15.73 11.22 15.05 11.9M8.62 2.00996L4.69 5.93996H2.42C1.63 5.93996 1 6.57996 1 7.35996V11.14C1 11.92 1.63 12.56 2.42 12.56H4.69L8.62 16.49C9.71 17.58 11.58 16.81 11.58 15.26V3.23996C11.58 1.68996 9.71 0.919961 8.62 2.00996Z"
      />,
    ],
    small: [
      [17, 14],
      <path
        {...bem('line')}
        d="M14.2168 2.7749C15.313 3.8693 15.9942 5.38583 15.9942 7.0587C15.9942 8.73157 15.313 10.2481 14.2168 11.3425M12.001 4.98718C12.5334 5.51875 12.8623 6.25356 12.8623 7.05873C12.8623 7.86389 12.5334 8.5987 12.001 9.13027M6.9663 1.39903L3.88919 4.47116H2.11183C1.49328 4.47116 1 4.97146 1 5.5812V8.53608C1 9.14581 1.49328 9.64611 2.11183 9.64611H3.88919L6.9663 12.7182C7.81974 13.5703 9.28391 12.9684 9.28391 11.7567V2.36053C9.28391 1.14888 7.81974 0.546957 6.9663 1.39903Z"
      />,
    ],
  },
  muted: {
    base: [
      [22, 19],
      <path
        {...bem('line')}
        d="M15.06 6.23996L21.08 12.26M21.08 6.23996L15.06 12.26M8.62 2.00996L4.69 5.93996H2.42C1.63 5.93996 1 6.57996 1 7.35996V11.14C1 11.92 1.63 12.56 2.42 12.56H4.69L8.62 16.49C9.71 17.58 11.58 16.81 11.58 15.26V3.23996C11.58 1.68996 9.71 0.919961 8.62 2.00996Z"
      />,
    ],
    small: [
      [18, 14],
      <path
        {...bem('line')}
        d="M11.9231 4.66977L16.6 9.33015M16.6 4.66977L11.9231 9.33015M6.91991 1.39516L3.86673 4.43757H2.10319C1.48944 4.43757 1 4.93302 1 5.53686V8.46314C1 9.06698 1.48944 9.56243 2.10319 9.56243H3.86673L6.91991 12.6048C7.76672 13.4487 9.21951 12.8526 9.21951 11.6526V2.34737C9.21951 1.14744 7.76672 0.551341 6.91991 1.39516Z"
      />,
    ],
  },
  tag: {
    base: [
      [18, 19],
      <>
        <path
          {...bem('line')}
          d="M1.62 8.39008L7.49 2.52008C8.32 1.69008 9.67 1.69008 10.5 2.52008L15.49 7.51008C16.32 8.34008 16.32 9.69008 15.49 10.5201L9.62 16.3901C9.22 16.7901 8.68 17.0101 8.12 17.0101H3.13C1.95 17.0001 1 16.0501 1 14.8801V9.89008C1 9.33008 1.22 8.79008 1.62 8.39008Z"
        />
        <path
          {...bem('fill')}
          d="M6.32 13.26C7.18709 13.26 7.89 12.5571 7.89 11.69C7.89 10.8229 7.18709 10.12 6.32 10.12C5.45291 10.12 4.75 10.8229 4.75 11.69C4.75 12.5571 5.45291 13.26 6.32 13.26Z"
        />
      </>,
    ],
    small: [
      [13, 14],
      <>
        <path
          {...bem('line')}
          d="M1.16997 6.08992L5.38997 1.86992C5.98997 1.26992 6.95997 1.26992 7.54997 1.86992L11.14 5.45992C11.74 6.05992 11.74 7.02992 11.14 7.61992L6.91997 11.8399C6.62997 12.1299 6.23997 12.2899 5.83997 12.2899H2.24997C1.39997 12.2899 0.719971 11.5999 0.719971 10.7599V7.16992C0.719971 6.76992 0.879971 6.37992 1.16997 6.08992Z"
        />
        <path
          {...bem('fill')}
          d="M4.54998 9.59997C5.17406 9.59997 5.67998 9.09405 5.67998 8.46997C5.67998 7.84588 5.17406 7.33997 4.54998 7.33997C3.9259 7.33997 3.41998 7.84588 3.41998 8.46997C3.41998 9.09405 3.9259 9.59997 4.54998 9.59997Z"
        />
      </>,
    ],
  },
  star: {
    large: [
      [30, 33],
      <path
        {...bem('line')}
        d="M25.6576 17.2883L24.0566 16.3617L25.6576 15.4352C27.8291 14.1751 28.5836 11.3768 27.3138 9.19014C26.0625 7.00345 23.2837 6.24366 21.1122 7.52232L19.5111 8.44889V6.57723C19.5111 4.05697 17.4869 2 14.9657 2C12.4446 2 10.4203 4.05697 10.4203 6.57723V8.44889L8.81924 7.50379C6.64774 6.24366 3.86895 6.98491 2.61757 9.17161C1.36619 11.3583 2.1023 14.1565 4.2738 15.4166L5.87483 16.3432L4.2738 17.2698C2.1023 18.5299 1.34779 21.3281 2.61757 23.5148C3.86895 25.7015 6.64774 26.4613 8.81924 25.1826L10.4203 24.2561V26.1277C10.4203 28.648 12.463 30.705 14.9657 30.705C17.4685 30.705 19.5111 28.648 19.5111 26.1277V24.2561L21.1122 25.1826C23.2837 26.4428 26.0625 25.7015 27.3138 23.5148C28.5836 21.3467 27.8291 18.5484 25.6576 17.2883Z"
      />,
    ],
    base: [
      [18, 18],
      <path
        {...bem('line')}
        d="M14.85 9.75L13.98 9.25L14.85 8.75C16.03 8.07 16.44 6.56 15.75 5.38C15.07 4.2 13.56 3.79 12.38 4.48L11.51 4.98V3.97C11.51 2.61 10.41 1.5 9.04 1.5C7.67 1.5 6.57 2.61 6.57 3.97V4.98L5.7 4.47C4.52 3.79 3.01 4.19 2.33 5.37C1.65 6.55 2.05 8.06 3.23 8.74L4.1 9.24L3.23 9.74C2.05 10.42 1.64 11.93 2.33 13.11C3.01 14.29 4.52 14.7 5.7 14.01L6.57 13.51V14.52C6.57 15.88 7.68 16.99 9.04 16.99C10.4 16.99 11.51 15.88 11.51 14.52V13.51L12.38 14.01C13.56 14.69 15.07 14.29 15.75 13.11C16.44 11.94 16.03 10.43 14.85 9.75Z"
      />,
    ],
  },
  grocery: {
    base: [
      [20, 18],
      <path
        {...bem('line')}
        d="M2.01 13.91L3.09 2.14C3.12 1.78 3.43 1.5 3.79 1.5H6.44C6.83 1.5 7.15 1.82 7.15 2.21V5.66C7.15 6.05 7.47 6.37 7.86 6.37H12.21C12.6 6.37 12.92 6.05 12.92 5.66V2.21C12.92 1.82 13.24 1.5 13.63 1.5H16.28C16.65 1.5 16.95 1.78 16.99 2.14L18.07 13.9C18.22 15.56 16.91 16.99 15.25 16.99H4.83C3.17 17 1.86 15.57 2.01 13.91Z"
      />,
    ],
    small: [
      [14, 13],
      <path
        {...bem('line')}
        d="M0.730012 10.05L1.50001 1.58C1.52001 1.32 1.74001 1.12 2.01001 1.12H3.92001C4.20001 1.12 4.43001 1.35 4.43001 1.63V4.11C4.43001 4.39 4.66001 4.61999 4.94001 4.61999H8.07001C8.35001 4.61999 8.58001 4.39 8.58001 4.11V1.63C8.58001 1.35 8.81001 1.12 9.09001 1.12H11C11.26 1.12 11.48 1.32 11.51 1.58L12.28 10.05C12.39 11.25 11.45 12.28 10.25 12.28H2.76001C1.56001 12.28 0.620012 11.25 0.730012 10.05Z"
      />,
    ],
  },
  empty: {
    base: [
      [20, 19],
      <>
        <path {...bem('line')} d="M9.37001 5.90004L15.94 12.69C17.68 14.49 16.41 17.5 13.9 17.5H4.84001C2.34001 17.5 1.06001 14.49 2.80001 12.69L9.37001 5.90004Z" />
        <path {...bem('line')} d="M5.58001 2.69004C7.14001 0.820041 10.14 3.03004 9.40001 5.90004C6.70001 7.13004 4.01001 4.56004 5.58001 2.69004Z" />
        <path {...bem('line')} d="M14.33 5.04004C14.75 7.44004 11.14 8.32004 9.41001 5.91004C10.2 3.04004 13.9 2.63004 14.33 5.04004Z" />
      </>,
    ],
    small: [
      [14, 13],
      <>
        <path {...bem('line')} d="M7.05002 3.93005C7.58002 1.86005 5.42002 0.270051 4.29002 1.62005C3.16002 2.96005 5.10002 4.81005 7.05002 3.93005ZM7.05002 3.93005C8.30002 5.67005 10.9 5.04005 10.59 3.31005C10.29 1.58005 7.63002 1.87005 7.05002 3.93005ZM7.03002 3.93005L11.76 8.82005C13.01 10.1201 12.1 12.2801 10.29 12.2801H3.76002C1.96002 12.2801 1.04002 10.1101 2.29002 8.82005L7.03002 3.93005Z" />
      </>,
    ],
  },
  email: {
    base: [
      [21, 17],
      <path {...bem('line')} d="M18.57 3.08L12.25 9.4C11.13 10.52 9.3 10.52 8.18 9.4L1.85 3.08M3.83 15.99H16.59C18.16 15.99 19.42 14.72 19.42 13.16V4.33C19.42 2.76 18.15 1.5 16.59 1.5H3.83C2.27 1.5 1 2.77 1 4.33V13.15C1 14.72 2.27 15.99 3.83 15.99Z" />,
    ],
    small: [
      [15, 13],
      <path {...bem('line')} d="M13.37 2.05992L8.81997 6.60992C8.00997 7.41992 6.69997 7.41992 5.88997 6.60992L1.32997 2.05992M2.75997 11.3499H11.94C13.07 11.3499 13.98 10.4399 13.98 9.30992V2.95992C13.98 1.82992 13.07 0.919922 11.94 0.919922H2.75997C1.62997 0.919922 0.719971 1.82992 0.719971 2.95992V9.30992C0.719971 10.4399 1.62997 11.3499 2.75997 11.3499Z" />,
    ],
  },
  copy: {
    base: [
      [17, 19],
      <path {...bem('line')} d="M15.85 11.31V5.04C15.85 3.08 14.26 1.5 12.31 1.5H5.56M3.83 18H9.01C10.58 18 11.84 16.73 11.84 15.17V8.33C11.85 6.77 10.58 5.5 9.01 5.5H3.83C2.27 5.5 1 6.77 1 8.33V15.16C1 16.73 2.27 18 3.83 18Z" />,
    ],
    small: [
      [13, 15],
      <path {...bem('line')} d="M11.41 8.47992V3.96992C11.41 2.55992 10.27 1.41992 8.85997 1.41992H3.99997M2.75997 13.2999H6.48997C7.61997 13.2999 8.52997 12.3899 8.52997 11.2599V6.33992C8.52997 5.20992 7.61997 4.29992 6.48997 4.29992H2.75997C1.62997 4.29992 0.719971 5.20992 0.719971 6.33992V11.2599C0.719971 12.3899 1.62997 13.2999 2.75997 13.2999Z" />,
    ],
  },
  hamburger: {
    base: [
      [18, 18],
      <path {...bem('line')} d="M17.47 9.24H0M17.47 16.99H0M17.47 1.5H0" />,
    ],
  },
  download: {
    base: [
      [18, 18],
      <path
        {...bem('line')}
        d="M0 16.99H17.48M13.38 7.89L8.77 12.7C8.4 13.08 7.79 13.1 7.41 12.73L2.6 8.12M7.99 0.5V12.99"
      />,
    ],
  },
  passwordHidden: {
    base: [
      [24, 18],
      <path
        {...bem('line')}
        d="M19.79 1.45996L4.32 16.93M9.52 11.61V7.34996C9.52 6.95996 9.84 6.63996 10.23 6.63996H14.49M2.2 8.67996C1.93 8.95996 1.93 9.39996 2.2 9.66996C4.93 12.44 7.65 15.43 12.05 15.43C16.45 15.43 19.17 12.45 21.9 9.66996C22.17 9.38996 22.17 8.94996 21.9 8.67996C19.17 5.90996 16.45 2.91996 12.05 2.91996C7.65 2.91996 4.93 5.90996 2.2 8.67996Z"
      />,
    ],
  },
  passwordVisible: {
    base: [
      [24, 16],
      <>
        <path
          {...bem('line')}
          d="M13.83 10.74H10.18C9.79 10.74 9.47 10.42 9.47 10.03V6.37995C9.47 5.98995 9.79 5.66995 10.18 5.66995H13.83C14.22 5.66995 14.54 5.98995 14.54 6.37995V10.03C14.54 10.42 14.22 10.74 13.83 10.74Z"
        />
        <path
          {...bem('line')}
          d="M2.2 7.70995C1.93 7.98995 1.93 8.42995 2.2 8.69995C4.93 11.47 7.65 14.46 12.05 14.46C16.45 14.46 19.17 11.48 21.9 8.69995C22.17 8.41995 22.17 7.97995 21.9 7.70995C19.18 4.92995 16.46 1.94995 12.06 1.94995C7.66 1.94995 4.93 4.92995 2.2 7.70995Z"
        />
      </>,
    ],
  },
  sms: {
    base: [
      [20, 18],
      <>
        <path {...bem('fill')} d="M5.6 7.93004C6.32901 7.93004 6.92 7.33906 6.92 6.61004C6.92 5.88102 6.32901 5.29004 5.6 5.29004C4.87098 5.29004 4.28 5.88102 4.28 6.61004C4.28 7.33906 4.87098 7.93004 5.6 7.93004Z" />
        <path {...bem('fill')} d="M9.67 7.93004C10.399 7.93004 10.99 7.33906 10.99 6.61004C10.99 5.88102 10.399 5.29004 9.67 5.29004C8.94098 5.29004 8.35 5.88102 8.35 6.61004C8.35 7.33906 8.94098 7.93004 9.67 7.93004Z" />
        <path {...bem('fill')} d="M13.75 7.93004C14.479 7.93004 15.07 7.33906 15.07 6.61004C15.07 5.88102 14.479 5.29004 13.75 5.29004C13.021 5.29004 12.43 5.88102 12.43 6.61004C12.43 7.33906 13.021 7.93004 13.75 7.93004Z" />
        <path {...bem('line')} d="M11.09 12.23L5.53 16.44V12.23H3.12C1.95 12.23 1 11.27 1 10.1V3.13C1 1.95 1.95 1 3.13 1H16.23C17.4 1 18.36 1.95 18.36 3.13V10.1C18.36 11.27 17.41 12.23 16.23 12.23H11.09Z" strokeLinejoin="round"/>
      </>,
    ],
  },
}

export default function Icon({
  icon,
  size,
  direction,
  className,
  title,
  ...props
}: InferProps<typeof Icon.propTypes>): JSX.Element | null {
  if (!icon || !ICONS[icon] || !ICONS[icon][size]) {
    return null
  }

  const [[width, height], content] = ICONS[icon][size]

  return (
    <svg
      {...props}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      {...bem('', [direction, size], className || '')}
    >
      {title && <title>{title}</title>}
      {content}
    </svg>
  )
}

Icon.propTypes = {
  icon: T.oneOf(Object.keys(ICONS)).isRequired,
  direction: T.string.isRequired,
  size: T.string.isRequired,
  className: T.string,
  title: T.string,
}

Icon.defaultProps = {
  icon: 'home',
  size: 'base',
  direction: '',
}
