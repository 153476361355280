/* eslint-disable complexity */

import React, { useState } from 'react'
import T, { InferProps } from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { get } from 'lodash'

import FAVORITES_QUERY from '../queries/pages/Favourites'
import { REMOVE_FAVORITE_RECIPE } from '../queries/mutations/favorite'
import { Layout, RecipeList, CourseList, Halleluja } from '../components'
import { Loader, Tabs } from '../_shared/components'
import setColors from '../lib/setColors'
import getColor, { COLORS } from '../_shared/lib/color'
import { RouteProps } from '../types'

export const routeProps: RouteProps = { path: '/favoritter/', exact: true }

export default function Favourites({
  applyColors,
}: InferProps<typeof Favourites.propTypes>): JSX.Element {
  const [tab, setTab] = useState('recipes')

  const {
    loading,
    error,
    data: { courses = [], recipes = [] } = {},
  } = useQuery(FAVORITES_QUERY)
  const [removeFavorite] = useMutation(REMOVE_FAVORITE_RECIPE)

  const recipesEdges = {
    edges: recipes.map(({ recipe: { node } }) => ({ node })),
  }
  const coursesEdges = {
    edges: courses.map(({ course: { node } }) => ({ node })),
  }

  if (error) { throw error }

  if (loading) {
    return (
      <Layout title="Favoritter">
        <Loader />
      </Layout>
    )
  }

  const recipesCount = recipesEdges.edges.length
  const coursesCount = coursesEdges.edges.length

  // Set colors
  if (applyColors) {
    let colors = COLORS.red

    if (recipesCount || coursesCount) {
      const palette =
        tab === 'courses' || !recipesCount
          ? get(courses, '[0].course.node.image.asset.metadata.palette', null)
          : get(recipes, '[0].recipe.node.image.asset.metadata.palette', null)
      const color =
        tab === 'courses' || !recipesCount
          ? get(courses, '[0].course.color', null)
          : get(recipes, '[0].recipe.color', null)
      colors = getColor(palette, color)
    }
    setColors(colors, `favorites-${tab}`)
  }

  const removeRecipe = (id: string): void => {
    removeFavorite({
      variables: { id },
      optimisticResponse: {
        removeFavoriteRecipe: {
          success: true,
          __typename: 'RemoveFavoriteRecipePayload',
          recipe: {
            __typename: 'RecipesEdge',
            node: { __typename: 'Recipe', id, isFavorite: false },
          },
        },
      },
      refetchQueries: [{ query: FAVORITES_QUERY }],
    })
  }

  const removeCourse = (id: string): void => {
    removeFavorite({
      variables: { id },
      optimisticResponse: {
        removeFavoriteCourse: {
          success: true,
          __typename: 'RemoveFavoriteCoursePayload',
          course: {
            __typename: 'CoursesEdge',
            node: { __typename: 'Course', id, isFavorite: false },
          },
        },
      },
      refetchQueries: [{ query: FAVORITES_QUERY }],
    })
  }

  return (
    <Layout title="Favoritter">
      {Boolean(recipesCount && coursesCount) && (
        <Tabs
          active={tab}
          onChange={setTab}
          items={[
            { label: 'Oppskrifter', id: 'recipes' },
            { label: 'Kurs', id: 'courses' },
          ]}
        />
      )}

      {!recipesCount && !coursesCount && (
        <Halleluja
          type="favorites"
          text="Snarveien til dine favoritter! Trykk på hjertet når du finner kurs og oppskrifter du liker, så finner du dem igjen her."
        />
      )}

      {recipesCount > 0 && (tab === 'recipes' || !coursesCount) ? (
        <RecipeList
          recipes={recipesEdges}
          animate
          removeFavorite={removeRecipe}
        />
      ) : null}
      {coursesCount > 0 && (tab === 'courses' || !recipesCount) ? (
        <CourseList
          courses={coursesEdges}
          animate
          removeFavorite={removeCourse}
        />
      ) : null}
    </Layout>
  )
}

Favourites.propTypes = {
  applyColors: T.bool.isRequired,
}
