import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('tabs')

export default function Tabs({ active, items, onChange, tight, small }) {
  const handleChange = id => () => onChange(id)

  return (
    <nav {...bem('', { tight, small })} role="tablist">
      {items.map(({ label, icon, id }) => (
        <button
          type="button"
          key={id}
          {...bem('item', {
            active: active === id,
          })}
          onClick={handleChange(id)}
          role="tab"
          aria-selected={active === id}
        >
          {icon && <Icon icon={icon} {...bem('icon')} />}
          {label}
        </button>
      ))}
    </nav>
  )
}

Tabs.propTypes = {
  active: T.oneOfType([T.string, T.number]).isRequired,
  items: T.arrayOf(
    T.shape({
      icon: T.string,
      label: T.string.isRequired,
      id: T.oneOfType([T.string, T.number]).isRequired,
    }),
  ).isRequired,
  onChange: T.func.isRequired,
  tight: T.bool,
  small: T.bool,
}
