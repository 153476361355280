import React from 'react'
import T, { InferProps } from 'prop-types'
import { get, orderBy } from 'lodash'
import { useQuery } from '@apollo/client'

import getColor from '../_shared/lib/color'
import WEEKS_QUERY from '../queries/pages/Weeks'
import setColors from '../lib/setColors'
import { Layout, WeekMenuEntry } from '../components'
import { RouteProps } from '../types'
import Error404 from './404'

export const routeProps: RouteProps = { path: '/uke/', exact: true }

export default function WeekPage({ applyColors }: InferProps<typeof WeekPage.propTypes>): JSX.Element {
  const { loading, error, data } = useQuery(WEEKS_QUERY)

  const weekMenus = get(data, 'weekMenus.edges', [])

  // set colors
  if (!loading && weekMenus && applyColors) {
    const colors = getColor(get(weekMenus, '[0].recipes.edges[0].node.image.asset.metadata.palette'))
    setColors(colors, 'weeks')
  }

  if (error) { throw error }

  if (!loading && !weekMenus) {
    return <Error404 applyColors title="Fant ingen ukesmenyer" />
  }

  const weeks = orderBy(
    weekMenus,
    [
      menu => menu?.node?.year ? Number(menu?.node?.year) : null,
      menu => menu?.node?.week ? Number(menu?.node?.week) : null,
    ],
    ['desc', 'desc']
  )

  return (
    <Layout
      header={{
        back: { name: 'Hjem', to: '/' },
        search: true,
      }}
      cover={{
        loading,
        skeleton: ['title'],
        title: 'Ukesmenyer',
      }}
    >
      {weeks.map((item, index) => (
        <WeekMenuEntry
          year={get(item, 'node.year')}
          week={get(item, 'node.week')}
          key={get(item, 'node.slug') || index}
          recipes={get(item, 'node.recipes', {})}
          slug={get(item, 'node.slug', null)}
          promoted
        />
      ))}
    </Layout>
  )
}

WeekPage.propTypes = {
  applyColors: T.bool.isRequired,
}
