import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Icon } from '../../_shared/components'

const bem = new BEMHelper('rating')

const VALUES = [1, 2, 3, 4, 5]

/**
 * Allows setting, but not updating, a rating. We currenly don't support changing the rating..
 */
export default function Rating({ value, onChange }) {
  return (
    <nav {...bem('')}>
      {VALUES.map(item => (
        <button
          type="button"
          {...bem('item', { active: item <= value })}
          key={item}
          {...(value ? { disabled: true } : { onClick: () => onChange(item) })}
        >
          <Icon icon="star" size="large" {...bem('icon')} />
        </button>
      ))}
    </nav>
  )
}

Rating.propTypes = {
  value: T.number.isRequired,
  onChange: T.func.isRequired,
}
