import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('info')

function Title({ title, ...props }) {
  return <h2 {...props}>{title}</h2>
}

Title.propTypes = {
  title: T.string.isRequired,
}

export default function Info({ children, title, type, renderTitle }) {
  return (
    <section {...bem('', type)}>
      {title && renderTitle({ title, ...bem('title') })}
      <div {...bem('content')}>{children}</div>
    </section>
  )
}

Info.propTypes = {
  title: T.any,
  children: T.any.isRequired,
  renderTitle: T.any,
  type: T.oneOfType([T.string, T.array]),
}

Info.defaultProps = {
  renderTitle: Title,
}
