// import React from 'react'
import { createPortal } from 'react-dom'

export default function Portal({ children, insertTo }) {
  if (!insertTo || !children) {
    return null
  }

  return createPortal(children, insertTo)
}
