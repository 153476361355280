import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'

const bem = new BEMHelper('amount')

export default function Amount({
  min,
  max,
  label,
  value: currentValue,
  options,
  unit,
  onChange,
  id = 'amount',
}) {
  const count = max + 1 - min
  const minMax = min && max ? new Array(count).fill(null).map((val, index) => ({
    value: index + min,
    title: index + min,
  })) : null

  const primary = (options || minMax).slice(0, 3)
  const secondary = (options || minMax).slice(3)

  const handleSelect = ({ target: { value } }) => {
    if (Number.isNaN(Number(value))) {
      onChange(value)
      return
    }

    onChange(Number(value))
  }

  const selectHasValue = secondary.some(({ value }) => value === currentValue)

  return (
    <nav {...bem('')} role="tablist">
      <span {...bem('label')} id={`${id}-label`}>
        {label}
      </span>
      <div {...bem('options')}>
        {primary.map(({ value, title = value }) => (
          <button
            type="button"
            key={value}
            {...bem('item', {
              selected: currentValue === value,
            })}
            onClick={() => onChange(value)}
            role="tab"
            aria-labelledby={`${id}-label`}
            aria-selected={currentValue === value}
          >
            {title}
          </button>
        ))}

        {secondary && secondary.length > 0 && (
          <>
            <Icon
              icon="chevron"
              size="small"
              direction="down"
              {...bem('indicator')}
            />
            <select
              {...bem('item', { dropdown: true, selected: selectHasValue })}
              value={selectHasValue ? currentValue : ''}
              onChange={handleSelect}
              aria-labelledby={`${id}-label`}
            >
              <option disabled value="">
                Annet
              </option>
              {secondary.map(({ value, title = value }) => (
                <option key={value} value={value}>
                  {title}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
    </nav>
  )
}

Amount.propTypes = {
  id: T.string,
  label: T.string.isRequired,
  value: T.oneOfType([T.string, T.number]).isRequired,
  options: T.arrayOf(T.number).isRequired,
  onChange: T.func.isRequired,
  unit: T.string,
  min: T.number.isRequired,
  max: T.number.isRequired,
}
